import { TUserSchema } from '@marlin/account-data-access-user';
import { renderEllipsisCell } from '@marlin/data-grid';
import { ActionDelete, ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import { phoneNumberMask } from '@marlin/shared/ui-form';
import { InvitationStatusChip, RoleChip } from '@marlin/shared/ui/chips';
import { TRole } from '@marlin/shared/utils-role';
import { GridColDef, gridStringOrNumberComparator } from '@mui/x-data-grid';

import { ActionResend } from './action-resend.component';
import { content } from './content';
import { IDeleteUser, INVITATION_STATUS, IResendInvite } from './types';

const isInvitationActive = (invitationStatus: INVITATION_STATUS | null) =>
  invitationStatus !== INVITATION_STATUS.EXPIRED;

const isInvitationActiveOrPending = (invitationStatus: INVITATION_STATUS | null) =>
  invitationStatus === INVITATION_STATUS.EXPIRED || invitationStatus === INVITATION_STATUS.PENDING;

interface IUserListColumnsProps {
  onResend: (user: IResendInvite) => void;
  onEdit: (userId: string) => void;
  onDelete: (user: IDeleteUser) => void;
  onDeleteInvitation: (invitationId: string) => void;
}

interface IBaseColumnsProps {
  columnProps: Partial<GridColDef>;
  rows: TUserSchema[];
}

export const createColumns =
  ({ onResend, onEdit, onDelete, onDeleteInvitation }: IUserListColumnsProps) =>
  ({ columnProps, rows }: IBaseColumnsProps): GridColDef[] =>
    [
      {
        field: 'FirstName',
        headerName: content.USER_TABLE_HEADER_NAME,
        flex: 1,
        ...columnProps,
        renderCell: renderEllipsisCell,
      },
      {
        field: 'title',
        headerName: content.USER_TABLE_HEADER_TITLE,
        flex: 1,
        ...columnProps,
        renderCell: renderEllipsisCell,
      },
      {
        field: 'Email',
        headerName: content.USER_TABLE_HEADER_EMAIL,
        flex: 2,
        ...columnProps,
        renderCell: renderEllipsisCell,
      },
      {
        field: 'Phone',
        headerName: content.USER_TABLE_HEADER_PHONE,
        flex: 1,
        ...columnProps,
        renderCell: (cellValues) => {
          return cellValues.row.phone ? phoneNumberMask(cellValues.row.phone) : '';
        },
      },
      {
        field: 'role',
        headerName: content.USER_TABLE_HEADER_ROLE,
        flex: 0.5,
        ...columnProps,
        renderCell: (cellValues) => {
          const { invitationStatus, role } = cellValues.row as TUserSchema;

          if (invitationStatus) {
            return <InvitationStatusChip invitationStatus={invitationStatus} />;
          }

          if (role) {
            return <RoleChip role={role} />;
          }

          return cellValues.value;
        },
        sortComparator: (roleA: TRole, roleB: TRole, cellParams1, cellParams2) => {
          const firstRowInvitationStatus = rows.find((row) => row.id === cellParams1.id)?.invitationStatus;
          const secondRowInvitationStatus = rows.find((row) => row.id === cellParams2.id)?.invitationStatus;
          if (firstRowInvitationStatus || secondRowInvitationStatus) {
            const cellValueA = (firstRowInvitationStatus || roleA).toUpperCase();
            const cellValueB = (secondRowInvitationStatus || roleB).toUpperCase();

            if (cellValueA < cellValueB) {
              return -1;
            } else if (cellValueA > cellValueB) {
              return 1;
            }
            return 0;
          }

          return gridStringOrNumberComparator(roleA, roleB, cellParams1, cellParams2);
        },
      },
      {
        field: 'action',
        headerName: '',
        sortable: false,
        hideable: false,
        filterable: false,
        flex: 0.5,
        align: 'center',
        ...columnProps,
        renderCell: (cellValues) => {
          const deleteAction = isInvitationActiveOrPending(cellValues.row.invitationStatus)
            ? () => onDeleteInvitation(cellValues.row.id)
            : () => onDelete(cellValues.row);

          return (
            <ContextMenu>
              {!isInvitationActive(cellValues.row.invitationStatus) && (
                <ActionResend onClick={() => onResend(cellValues.row)} />
              )}
              <ActionEdit
                disabled={isInvitationActiveOrPending(cellValues.row.invitationStatus)}
                onClick={() => onEdit(cellValues.row.id)}
              />
              {<ActionDelete onClick={deleteAction} />}
            </ContextMenu>
          );
        },
      },
    ];
