import { TExtractFnReturnType, useInfiniteQuery, useQuery } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';
import { useMemo } from 'react';
import { z } from 'zod';

import { TGateway, TGatewayFilters, TGatewayList, gatewayFiltersSchema } from '../gateway.model.schema';
import { getGateways } from '../infrastructure/get-gateways';
import { queryKey } from '../query-key.enum';

type TUseGatewaysProps = {
  params: TGatewayFilters;
  enabled?: boolean;
};

export const useGateways = ({ params, enabled }: TUseGatewaysProps) => {
  const queryParams = safeParseData(params, gatewayFiltersSchema);

  return useQuery<TExtractFnReturnType<typeof getGateways>>({
    queryKey: queryKey.GATEWAY_FILTER(queryParams),
    queryFn: () => getGateways(queryParams),
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);
    },
    enabled,
  });
};

type TQueryFnType = typeof getGateways;

type TUseInfiniteGatewaysProps = {
  params: Omit<TGatewayFilters, 'page'>;
  enabled?: boolean;
};

export const useGatewaysWithLoadMore = ({ params, enabled = true }: TUseInfiniteGatewaysProps) => {
  const result = useInfiniteQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.GATEWAY_FILTER(params),
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getGateways({ ...params, page: pageParam as number }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.page * lastPage.pagination.pageSize < lastPage.pagination.totalItems
        ? lastPage.pagination.page + 1
        : undefined;
    },
    enabled,
  });

  const rows: TGateway[] = useMemo(() => {
    const unfilteredRows: (TGateway | null)[] = result.data?.pages?.flatMap((i: TGatewayList) => i.data) || [];
    return unfilteredRows.filter((i: TGateway | null): i is TGateway => i !== null);
  }, [result.data?.pages]);

  return {
    rows,
    totalItems: result.data?.pages[0].pagination.totalItems || 0,
    hasNextPage: result.hasNextPage,
    fetchNextPage: result.fetchNextPage,
    refetch: result.refetch,
    isLoading: result.isLoading,
    isFetching: result.isFetching,
    isError: result.isError,
    error: result.error,
  };
};
