import { TUserSchema } from '@marlin/account-data-access-user';
import { IDeleteUser, IResendInvite, createColumns } from '@marlin/account-ui-user-list';
import { MarlinTable } from '@marlin/marlin-table';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useCallback, useState } from 'react';

import { content } from './content';
import { DeleteUserModal } from './delete-user-modal.component';
import { ResendModal } from './resend-modal.component';
import { useDeleteCurrentInvitation } from './use-delete-invitation.hook';
import { useUsersList } from './use-users-list.hook';
import { UserListMenu } from './user-list-menu.component';

export const UserList = () => {
  const { deleteCurrentInvitation } = useDeleteCurrentInvitation();

  const [resendModalOpen, setResendModalOpen] = useState<IResendInvite | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<IDeleteUser | null>(null);

  const openResendModal = useCallback((user: IResendInvite): void => {
    setResendModalOpen(user);
  }, []);
  const openDeleteModal = useCallback((user: IDeleteUser): void => {
    setDeleteModalOpen(user);
  }, []);
  const closeResendModal = useCallback(() => setResendModalOpen(null), []);
  const closeDeleteModal = useCallback(() => setDeleteModalOpen(null), []);

  const { goTo } = useRouter();

  const navigateToDetails = (userId: string) => {
    goTo(routes.settings.editUser.details.url(userId));
  };

  return (
    <>
      <MarlinTable<TUserSchema>
        useDataHook={useUsersList}
        createColumns={createColumns({
          onResend: openResendModal,
          onEdit: navigateToDetails,
          onDelete: openDeleteModal,
          onDeleteInvitation: (invitationId: string) => deleteCurrentInvitation(invitationId),
        })}
        buttons={<UserListMenu />}
        texts={{
          searchTitle: content.USER_TABLE_SEARCH_LABEL,
          tableTitle: content.USER_TABLE_TITLE,
          tableTitleTooltip: content.USER_TABLE_TOOLTIP,
        }}
      />
      <ResendModal user={resendModalOpen} onClose={closeResendModal} />
      <DeleteUserModal user={deleteModalOpen} onClose={closeDeleteModal} />
    </>
  );
};
