import { IconEquipment } from '@marlin/asset/shared/ui/assets';
import { MarlinTheme } from '@marlin/shared/theme';
import { dateAdapter, equipmentTimeFormat } from '@marlin/shared/utils-common-date';
import { MODEL } from '@marlin/shared/utils/zod';
import { alpha } from '@mui/system';
import React, { ReactElement } from 'react';

import { TDirection, TStatus } from '../types';

export const getBackgroundByEquipmentStatus = (status: TStatus | undefined, theme: MarlinTheme) => {
  switch (status) {
    case 'success':
      return theme.palette.text.alternative;
    case 'error':
      return alpha(theme.palette.error.main, 0.12);
    case 'warning':
      return alpha(theme.palette.warning.main, 0.12);
    default:
      return theme.palette.text.alternative;
  }
};

export const getBorderColorByEquipmentStatus = (status: TStatus | undefined, theme: MarlinTheme) => {
  switch (status) {
    case 'success':
      return theme.palette.systemMap.main;
    case 'error':
      return theme.palette.error.main;
    case 'warning':
      return theme.palette.warning.main;
    default:
      return theme.palette.systemMap.main;
  }
};

export const getIconColorByEquipmentStatus = (status: TStatus | undefined, theme: MarlinTheme) => {
  switch (status) {
    case 'success':
      return theme.palette.text.primary;
    case 'error':
      return theme.palette.error.dark;
    case 'warning':
      return theme.palette.warning.dark;
    default:
      return theme.palette.text.alternative;
  }
};

export const getStatusIconColor = (status: TStatus | undefined, theme: MarlinTheme) => {
  switch (status) {
    case 'success':
      return theme.palette.success.light;
    case 'error':
      return theme.palette.error.dark;
    case 'warning':
      return theme.palette.warning.dark;
    case 'notAvailable':
      return theme.palette.action.disabled;
    default:
      return theme.palette.text.alternative;
  }
};

export const getStatusColor = (status: TStatus | undefined, theme: MarlinTheme) => {
  switch (status) {
    case 'success':
      return theme.palette.primary.main;
    case 'error':
      return theme.palette.warning.contrastText;
    case 'warning':
      return theme.palette.warning.contrastText;
    case 'notAvailable':
      return theme.palette.text.secondary;
    default:
      return theme.palette.primary.main;
  }
};

export const getColumnPosition = (currentIndex: number, totalColumns: number): TDirection => {
  const half = Math.ceil(totalColumns / 2);
  if (currentIndex < half) {
    return 'left';
  } else if (currentIndex === half) {
    return 'center';
  }
  return 'right';
};

export const formatRemainingTime = (remainingTimestamp?: string) => {
  const remainingTime = remainingTimestamp ? dateAdapter.date(parseInt(remainingTimestamp, 10) * 1000) : undefined;
  return remainingTime ? remainingTime.utc().format(equipmentTimeFormat) : undefined;
};

const boilerModels: MODEL[] = [MODEL.AERCO_BOILER_LN, MODEL.AERCO_BOILER_DUAL];
const hotWaterHeaterModels: MODEL[] = [MODEL.AERCO_WATER_HEATER_INNOVATION, MODEL.AERCO_WATER_HEATER_N];
const leakDefenseModels: MODEL[] = [MODEL.SENTINEL];

export const getEquipmentIcon = (model: MODEL | undefined | null): ReactElement => {
  if (boilerModels.includes(model as MODEL)) {
    return <IconEquipment.Boiler />;
  }

  if (hotWaterHeaterModels.includes(model as MODEL)) {
    return <IconEquipment.HotWaterHeater />;
  }

  if (leakDefenseModels.includes(model as MODEL)) {
    return <IconEquipment.LeakPrevention />;
  }

  return <IconEquipment.Valve />;
};
