import { useGetGateway } from '@marlin/asset/data-access/gateway';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { SystemConstraintsPage } from '@marlin/shared/ui-page';
import { routes } from '@marlin/shared/utils-routes';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { Navigate } from 'react-router-dom';

import { GatewayDetailsConfigContextProvider } from '../shared/context/gateway-details-config.context';
import { getGatewayConfig } from '../shared/utils/get-config.utils';
import { GatewayDashboardBuilder } from './builder/gateway-dashboard-builer.component';

export const GatewayDetails = () => {
  const gatewayId = useIdFromPathname();
  const { data: gateway, isLoading } = useGetGateway({ gatewayId });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!gateway) {
    return <SystemConstraintsPage />;
  }

  const config = getGatewayConfig(gateway.model);

  if (!config) {
    return <Navigate to={routes.gateway.list.url()} />;
  }

  return (
    <GatewayDetailsConfigContextProvider config={config}>
      <GatewayDashboardBuilder gateway={gateway} />
    </GatewayDetailsConfigContextProvider>
  );
};
