import { TExtractFnReturnType } from '@marlin/shared/utils/react-query';
import { useInfiniteQuery, useQuery } from '@marlin/shared/utils/react-query';

import { TSublocationParamsSchema } from '../infrastructure/schema/sublocations.schema';
import { getAssetsInSublocations } from '../infrastructure/sublocations';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getAssetsInSublocations;

type TUseSublocationsOptions = {
  locationId: string;
  params: TSublocationParamsSchema;
};

export const useAssetsInSublocationWithPaging = ({ locationId, params }: TUseSublocationsOptions) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.SUBLOCATIONS(locationId, params),
    queryFn: () => getAssetsInSublocations(locationId, params),
    placeholderData: (previousData, previousQuery) => previousData,
  });
};

type TUseInfiniteSublocationsProps = {
  locationId: string;
  params: Omit<TSublocationParamsSchema, 'page'>;
  enabled?: boolean;
  refetchOnMount?: boolean;
};

export const useAssetsInSublocationWithLoadMore = ({
  locationId,
  params,
  enabled,
  refetchOnMount,
}: TUseInfiniteSublocationsProps) => {
  return useInfiniteQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.LOCATIONS(params, locationId),
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getAssetsInSublocations(locationId, { ...params, page: (pageParam as number) || 1 }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.page * lastPage.pagination.pageSize < lastPage.pagination.totalItems
        ? lastPage.pagination.page + 1
        : undefined;
    },
    enabled,
    refetchOnMount,
  });
};
