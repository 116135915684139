import { MarlinTheme } from '@marlin/shared/theme';
import { useTheme } from '@mui/material/styles';

import { TPowerSource } from '../../types';
import { getPowerSourceType } from '../../utils';
import { Battery } from './battery.component';
import { Line } from './line.component';

export interface IPowerSourceProps {
  batteryLevel: number;
  powerSource?: TPowerSource;
  gatewayModel?: string;
}

export const PowerSource = ({ gatewayModel, batteryLevel, powerSource: powerSourceProps }: IPowerSourceProps) => {
  const powerSource: TPowerSource = powerSourceProps ?? getPowerSourceType(batteryLevel);
  const theme = useTheme<MarlinTheme>();

  if (gatewayModel === 'SmartBaseStationDT550') {
    switch (powerSource.toUpperCase()) {
      case 'LINE': {
        return <Line />;
      }

      case 'BATTERY': {
        return <Line disconnected color={theme.palette.error.main} />;
      }

      default: {
        return null;
      }
    }
  }

  switch (powerSource) {
    case 'LINE': {
      return <Line />;
    }

    case 'BATTERY': {
      return <Battery batteryLevel={batteryLevel} />;
    }

    default: {
      return null;
    }
  }
};
