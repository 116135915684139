import { useGetWebhook, useIsPartition, useUpdateWebhook } from '@marlin/account-data-access-webhook';
import { TWebhookForm } from '@marlin/account-ui-webhook-upsert-form';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { routes } from '@marlin/shared/utils-routes';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { createWebhookApiParamsAdapter, updateWebhookApiParamsAdapter } from '../adapters';
import { content } from '../content';
import { useWebhookEquipmentPartition } from './use-webhook-equipment-partition.hook';
import { useWebhookFlowMeters } from './use-webhook-flow-meters.hook';

interface IUseWebhookUpdate {
  defaultValues?: TWebhookForm;
  handleSubmit: (values: TWebhookForm) => void;
  handleCancel: () => void;
  currentWebhookName: string;
  isLoading: boolean;
  isError: boolean;
  isFormSubmitting: boolean;
  webhookId?: string;
  selectedDefaultFlowMeters: TWebhookForm['devices'];
  selectedAllFlowMeters: TWebhookForm['devices'];
  equipmentsPartitionData: TWebhookForm['devices'];
  isLoadingEquipmentPartition: boolean;
  isErrorEquipmentPartition: boolean;
}

export const useWebhookUpdate = (): IUseWebhookUpdate => {
  const navigate = useNavigate();
  const { webhookId } = useParams<{ webhookId?: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedDefaultFlowMeters, selectedAllFlowMeters, isFlowMetersLoading, isFlowMetersError } =
    useWebhookFlowMeters();

  const {
    isError: isErrorEquipmentPartition,
    isLoading: isLoadingEquipmentPartition,
    data: equipmentsPartitionData,
  } = useWebhookEquipmentPartition();

  const getWebhookParams = useMemo(() => {
    return {
      params: {
        webhookId: webhookId || '',
      },

      enabled: !!webhookId,
    };
  }, [webhookId]);

  const { data: webhookData, isLoading, isFetching, isError } = useGetWebhook(getWebhookParams);
  const { mutateAsync: updateWebhook, isPending: isFormSubmitting } = useUpdateWebhook();

  const isPartition = useIsPartition({ webhookDevices: webhookData?.devices ?? [] });

  const defaultValues: TWebhookForm | undefined = useMemo(() => {
    if (!webhookData) {
      return undefined;
    }

    return updateWebhookApiParamsAdapter(webhookData, isPartition);
  }, [isPartition, webhookData]);

  const handleSubmit = useCallback(
    (values: TWebhookForm) => {
      return updateWebhook({ ...createWebhookApiParamsAdapter(values), webhookId: webhookData?.id || '' })
        .then(() => {
          navigate(routes.settings.integration.url());
          enqueueSnackbar(content.WEBHOOK_UPDATE_SUCCESS, {
            variant: 'success',
            preventDuplicate: true,
          });
        })
        .catch(() => {
          enqueueSnackbar(content.WEBHOOK_UPDATE_ERROR, {
            variant: 'error',
            preventDuplicate: true,
          });
        });
    },
    [enqueueSnackbar, navigate, updateWebhook, webhookData?.id]
  );

  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return useMemo(
    () => ({
      defaultValues,
      handleCancel,
      currentWebhookName: webhookData?.name || '',
      isLoading: isFlowMetersLoading || isLoading || isFetching,
      handleSubmit,
      isFormSubmitting,
      webhookId,
      isError: isFlowMetersError || isError,
      selectedDefaultFlowMeters,
      selectedAllFlowMeters,
      isLoadingEquipmentPartition,
      isErrorEquipmentPartition,
      equipmentsPartitionData,
    }),
    [
      defaultValues,
      handleCancel,
      webhookData?.name,
      isFlowMetersLoading,
      isLoading,
      handleSubmit,
      isFormSubmitting,
      webhookId,
      isFlowMetersError,
      isError,
      selectedDefaultFlowMeters,
      selectedAllFlowMeters,
      isFetching,
      isLoadingEquipmentPartition,
      isErrorEquipmentPartition,
      equipmentsPartitionData,
    ]
  );
};
