// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  DefinedUseQueryResult,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useQuery as useBaseQuery,
} from '@tanstack/react-query';
import { useEffect } from 'react';

interface IExtendedQueryOptions<TQueryFnData, TError, TData, TQueryKey extends QueryKey>
  extends UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> {
  onSuccess?: (data: TData) => void;
  onError?: (error: TError) => void;
}

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: Omit<IExtendedQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'initialData'> & {
    initialData?: () => undefined;
  }
): UseQueryResult<TData, TError>;

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: Omit<IExtendedQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'initialData'> & {
    initialData: TQueryFnData | (() => TQueryFnData);
  }
): DefinedUseQueryResult<TData, TError>;

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(options: IExtendedQueryOptions<TQueryFnData, TError, TData, TQueryKey>): UseQueryResult<TData, TError>;

export function useQuery<TQueryFnData, TError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>({
  onSuccess,
  onError,
  queryKey,
  queryFn,
  ...rest
}: IExtendedQueryOptions<TQueryFnData, TError, TData, TQueryKey>): UseQueryResult<TData, TError> {
  const queryResult = useBaseQuery<TQueryFnData, TError, TData, TQueryKey>({
    queryKey,
    queryFn,
    ...rest,
  });

  useEffect(() => {
    if (queryResult.isSuccess && onSuccess) {
      onSuccess(queryResult.data);
    }
  }, [onSuccess, queryResult.data, queryResult.isSuccess]);

  useEffect(() => {
    if (queryResult.isError && onError) {
      onError(queryResult.error);
    }
  }, [onError, queryResult.error, queryResult.isError]);

  return queryResult;
}
