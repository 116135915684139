import { gatewayImage, nexaLogo } from '@marlin/asset/shared/ui/assets';
import { GATEWAY_TAB_VALUES } from '@marlin/shared/utils-routes';

import { GatewaySettings } from '../../../desktop/components/settings/gateway-settings.component';
import { content } from '../../content';
import { IGatewayDetailsConfig } from '../model';
import { NexaGatewayDetailsConfig } from './details-config';

export const NexaGatewayConfig: IGatewayDetailsConfig = {
  details: NexaGatewayDetailsConfig,
  tabs: {
    [GATEWAY_TAB_VALUES.SETTINGS]: <GatewaySettings />,
  },
  dashboard: {
    equipmentAndSensorsList: { show: true },
    productDetails: {
      logoSrc: nexaLogo,
      imageSrc: gatewayImage,
      modelName: content.NEXA_GATEWAY,
      datapoints: {
        connectionStatus: {
          display: true,
          signalStrengthDatapointName: 'Rssi',
        },
        connectionType: {
          display: true,
          connectionTypeDatapointName: 'ConnectionType',
        },
      },
    },
    topAlerts: { show: true },
    displayGatewayMode: true,
  },
};
