import { FormField, IToggleButtonsOption, ToggleButtons } from '@marlin/shared/ui-form-common';
import { TUnitOfMeasure, getFlowRateUom, getUomFullname } from '@marlin/shared/utils-format-reading';
import { createDataTestId } from '@marlin/shared/utils/testing-utils';
import { Grid, SvgIconProps, Typography } from '@mui/material';
import { FC } from 'react';
import { ControllerProps, FieldValues, useFormContext } from 'react-hook-form';

import { content } from '../content';
import { useStyles } from './uom-control.styles';

export interface IUomControlProps<TUoM extends TUnitOfMeasure, TFieldValues extends FieldValues> {
  options: IToggleButtonsOption<TUoM>[];
  fieldName: ControllerProps<TFieldValues>['name'];
  label: string;
  LabelIcon: FC<SvgIconProps>;
  flowFieldName?: ControllerProps<TFieldValues>['name'];
}

export const UomControl = <TUoM extends TUnitOfMeasure, TFieldValues extends FieldValues>({
  options,
  fieldName,
  label,
  LabelIcon,
  flowFieldName,
}: IUomControlProps<TUoM, TFieldValues>) => {
  const { classes } = useStyles();
  const { setValue, watch } = useFormContext<TFieldValues>();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={6}>
        <div className={classes.labelRow}>
          <div className={classes.labelIcon}>
            <LabelIcon color="primary" />
          </div>
          <Typography variant="body2" data-testid={createDataTestId('uom-control', label, 'label')}>
            {label}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormField<TFieldValues> fieldName={fieldName}>
          {(props) => (
            <>
              <div data-testid={createDataTestId('uom-control', label, 'buttons')}>
                <ToggleButtons<TUoM>
                  options={options}
                  value={props.value as TUoM}
                  onChange={(_, value) => {
                    if (value !== null) {
                      if (flowFieldName && getFlowRateUom(value)) {
                        const flowRateUom = getFlowRateUom(value) ?? '';
                        setValue(flowFieldName, flowRateUom as TFieldValues[typeof flowFieldName], {
                          shouldValidate: true,
                        });
                      }
                      props.onChange && props.onChange(_, value);
                    }
                  }}
                />
              </div>
              {flowFieldName ? (
                <div>
                  <div>
                    <Typography
                      variant="caption"
                      data-testid={createDataTestId('uom-control', label, 'volume-fullname')}
                    >
                      {content.VOLUME_UOM(getUomFullname(props.value) ?? '')}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="caption" data-testid={createDataTestId('uom-control', label, 'flow-fullname')}>
                      {content.FLOW_UOM(getUomFullname(watch(flowFieldName)) ?? '')}
                    </Typography>
                  </div>
                </div>
              ) : (
                <Typography variant="caption" data-testid={createDataTestId('uom-control', label, 'fullname')}>
                  {getUomFullname(props.value)}
                </Typography>
              )}
            </>
          )}
        </FormField>
      </Grid>
    </Grid>
  );
};
