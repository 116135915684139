import { useFilteredEquipmentsWithPaging } from '@marlin/asset/data-access/equipment';
import { IEquipmentTypeOption } from '@marlin/shared/ui-device-type';
import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';
import { useMemo, useState } from 'react';

import { statusDatapoints } from '../../utils/status-datapoints';

interface IEquipmentParams {
  search?: string | undefined;
  locations?: string[];
  gatewayIds?: string[];
  equipmentType?: EQUIPMENT_TYPE[];
}

export interface IEquipmentFilter {
  search: string | undefined;
  locations: { id: string; name: string }[];
  equipmentType: IEquipmentTypeOption[];
}

export interface IPaginationModel {
  pageSize: number;
  page: number;
}

type TSortingDirection = 'Ascending' | 'Descending';

export const useEquipmentHub = ({
  paginationModel,
  filterParams,
}: {
  paginationModel: IPaginationModel;
  filterParams?: IEquipmentParams;
}) => {
  const [sortingDirection, setSortingDirection] = useState<TSortingDirection>('Ascending');
  const [sortingColumn, setSortingColumn] = useState<string>('name');

  const handleSortingDirection = (direction?: string | null) => {
    setSortingDirection(direction === 'asc' ? 'Ascending' : 'Descending');
  };

  const handleSortingColumn = (column: string) => {
    setSortingColumn(column);
  };

  const equipmentQueryParams = useMemo(
    () => ({
      params: {
        pageSize: paginationModel.pageSize,
        page: paginationModel.page + 1,
        datapoints: statusDatapoints,
        sorting: {
          direction: sortingDirection,
          sortBy: sortingColumn,
        },
        ...filterParams,
      },
    }),
    [paginationModel, sortingDirection, sortingColumn, filterParams]
  );

  const equipmentsQuery = useFilteredEquipmentsWithPaging(equipmentQueryParams);

  const isLoading = useMemo(
    () => equipmentsQuery.isLoading || equipmentsQuery.isInitialLoading,
    [equipmentsQuery.isInitialLoading, equipmentsQuery.isLoading]
  );

  const isError = useMemo(() => equipmentsQuery.isError, [equipmentsQuery.isError]);

  const rowCount = useMemo(() => equipmentsQuery?.data?.pagination.totalItems ?? 0, [equipmentsQuery.data?.pagination]);

  return {
    rows: equipmentsQuery.data?.data ?? [],
    isLoading,
    isError,
    paginationModel,
    rowCount,
    handleSortingDirection,
    handleSortingColumn,
  };
};
