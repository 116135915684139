import { NexaGatewayConfig } from '../config/nexa-gateway/nexa-gateway.config';
import { SmartBaseStationConfig } from '../config/smart-base-station/smart-base-station.config';

export const getGatewayConfig = (model: string) => {
  switch (model) {
    case 'Gateway':
      return NexaGatewayConfig;
    case 'SmartBaseStationDT550':
      return SmartBaseStationConfig;
    default:
      return null;
  }
};
