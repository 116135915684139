import { TGatewayDetailsRow } from '@marlin/asset/shared/utils/details-mapper';
import { TDateString, formatDate } from '@marlin/shared/utils-common-date';

import { content } from '../../content';
import { firmwareVersionCallback } from '../utils';

export const NexaGatewayDetailsConfig: TGatewayDetailsRow[] = [
  { name: 'manufacturerId', label: content.GATEWAY_ID, valueSource: 'gateway', column: 1 },
  {
    name: 'ConfigData',
    label: content.FIRMWARE_VERSION,
    valueSource: 'datapoints',
    column: 1,
    valueCallback: firmwareVersionCallback,
  },
  {
    name: 'commissionDate',
    label: content.COMMISSION_DATE,
    valueSource: 'gateway',
    column: 1,
    valueCallback: (value) => formatDate(value as unknown as TDateString, 'MM/DD/YY'),
  },
  { name: 'configurationId', label: content.CONFIGURATION_ID, valueSource: 'gateway', column: 1 },
  { name: 'description', label: content.DESCRIPTION, valueSource: 'gateway', column: 2 },
];
