import { IconEquipment } from '@marlin/asset/shared/ui/assets';
import { CardHeader, DetailsItem } from '@marlin/asset/shared/ui/hub-card';
import { MarlinTheme } from '@marlin/shared/theme';
import { ReadingCard } from '@marlin/shared/ui-asset-card-mobile';
import { Paper } from '@mui/material';
import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useEquipmentDetailsConfigContext } from '../../../../shared/context/equipment-details-config-context';
import { useBoilersAndPumps } from '../../../../shared/hooks/tekmar-ssc/use-boilers-and-pumps.hook';
import { content } from '../../../content';
import { useStyles } from '../../../hooks/boilers-and-pumps/use-styles.hook';

type TBoilerOrPumpData = ReturnType<typeof useBoilersAndPumps>['boilersAndPumps'][number];

interface IMobileBoilersAndPumpsProps {
  boilersAndPumps: TBoilerOrPumpData[];
}

const useMobileStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.secondary,
    padding: theme.typography.pxToRem(16),
    gap: theme.typography.pxToRem(8),
  },
  paper: {
    backgroundColor: theme.palette.background.secondary,
    padding: theme.typography.pxToRem(16),
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  readings: {
    display: 'flex',
    flex: '1 1 0',
    gap: theme.typography.pxToRem(8),
    '& > *': {
      maxWidth: '50%',
    },
  },
  icon: {
    marginRight: theme.typography.pxToRem(8),
    height: theme.typography.pxToRem(20),
    width: theme.typography.pxToRem(20),
    '& > svg': {
      fontSize: theme.typography.pxToRem(20),
    },
    '& > span': {
      fontSize: theme.typography.pxToRem(20),
      color: theme.palette.action.active,
    },
  },
}));

export const MobileBoilersAndPumps = ({ boilersAndPumps }: IMobileBoilersAndPumpsProps) => {
  const { classes } = useMobileStyles();
  return (
    <div className={classes.container}>
      {boilersAndPumps.map((boilerOrPump) => (
        <BoilerOrPumpCard boilerOrPump={boilerOrPump} />
      ))}
    </div>
  );
};

const BoilerOrPumpCard = ({ boilerOrPump }: { boilerOrPump: TBoilerOrPumpData }) => {
  const { classes: mobileClasses } = useMobileStyles();
  const { classes, cx } = useStyles();

  const {
    config: { boilersAndPumps },
  } = useEquipmentDetailsConfigContext();

  const columns = useMemo(() => boilersAndPumps?.columns, [boilersAndPumps]);
  return (
    <Paper variant="outlined" className={mobileClasses.paper}>
      <div className={mobileClasses.cardHeader}>
        <CardHeader
          asset={{ name: boilerOrPump.name, id: boilerOrPump.id }}
          icon={<div className={mobileClasses.icon}>{getIcon(boilerOrPump.type)}</div>}
        />
      </div>
      <div className={mobileClasses.readings}>
        {columns?.includes('boilerStatus') && boilerOrPump.type !== 'pump' && (
          <ReadingCard title={content.BOILERS_AND_PUMPS.COLUMNS.BOILER_STATUS} testId="boiler-status">
            <span
              className={`${cx({
                [classes.highlighted]: boilerOrPump.boilerStatusControlType === 'highlight',
                [classes.error]: boilerOrPump.boilerStatusControlType === 'error',
                [classes.custom]: !boilerOrPump.boilerStatusControlType,
              })}`}
            >
              {boilerOrPump.boilerStatus}
            </span>
          </ReadingCard>
        )}
        {columns?.includes('pumpStatus') && (
          <ReadingCard title={content.BOILERS_AND_PUMPS.COLUMNS.PUMP_STATUS} testId="pump-status">
            <span
              className={`${cx({
                [classes.highlighted]: boilerOrPump.pumpStatusControlType === 'highlight',
                [classes.error]: boilerOrPump.pumpStatusControlType === 'error',
                [classes.custom]: !boilerOrPump.pumpStatusControlType,
              })}`}
            >
              {boilerOrPump.pumpStatus}
            </span>
          </ReadingCard>
        )}
      </div>
      <DetailsItem
        testId="boiler-or-pump-card-hours"
        label={content.BOILERS_AND_PUMPS.COLUMNS.HOURS}
        value={boilerOrPump.hours}
      />
      <DetailsItem
        testId="boiler-or-pump-card-cycles"
        label={content.BOILERS_AND_PUMPS.COLUMNS.CYCLES}
        value={boilerOrPump.cycles}
      />
    </Paper>
  );
};

const getIcon = (type: TBoilerOrPumpData['type']) => {
  switch (type) {
    case 'boiler':
      return <IconEquipment.Boiler />;
    case 'pump':
      return <IconEquipment.Pump />;
  }
};
