import { DesktopSideNavComponent } from '@marlin/account-feature-navbar';
import { MarlinTheme } from '@marlin/shared/theme';
import { useDesktopSideNavbarCollapse } from '@marlin/shared/utils-common-desktop-sidenav';
import { FC, PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useSideNavOptions } from './navbar-options';

interface IStyles {
  isCollapsed: boolean;
  desktopNavbarLocked: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { isCollapsed, desktopNavbarLocked }) => ({
  appContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      minHeight: `calc( 100dvh - ${theme.typography.pxToRem(theme.static.mobileHeaderHeight)} )`,
      height: `calc( 100dvh - ${theme.typography.pxToRem(theme.static.mobileHeaderHeight)} )`,
    },
  },
  appContent: {
    marginLeft: desktopNavbarLocked ? 'unset' : !isCollapsed ? theme.typography.pxToRem(80) : 'unset',
    minHeight: '100vh',
    height: '100vh',
    overflowY: 'scroll',
    flexGrow: 1,
    backgroundColor: theme.palette.background.secondary,
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto',
      height: 'auto',
    },
  },
  main: {
    overflowY: 'hidden',
    height: '100%',
  },
}));

export const DesktopSideNavLayout: FC<PropsWithChildren> = ({ children }) => {
  const { desktopNavbarCollapsed, desktopNavbarLocked } = useDesktopSideNavbarCollapse();
  const { classes } = useStyles({ isCollapsed: desktopNavbarCollapsed, desktopNavbarLocked });

  const { topNavLinks, bottomNavLinks } = useSideNavOptions();

  return (
    <div className={classes.main}>
      <div className={classes.appContainer}>
        <DesktopSideNavComponent
          topNavLinks={topNavLinks}
          bottomNavLinks={bottomNavLinks}
          orgSwitcherVariant="marlin"
        />
        <div className={classes.appContent} id="appContent">
          {children}
        </div>
      </div>
    </div>
  );
};
