import { MarlinTheme } from '@marlin/shared/theme';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  deleteIcon: {
    color: theme.palette.error.main,
  },

  deleteText: {
    color: theme.palette.error.main,
  },
}));

interface IActionDeleteProps {
  onClick: () => void;
  removeText?: boolean;
  deregisterText?: boolean;
  customText?: string;
  testId?: string;
}

export const ActionDelete = ({
  onClick,
  removeText = false,
  deregisterText = false,
  customText,
  testId,
}: IActionDeleteProps) => {
  const { classes } = useStyles();

  return (
    <MenuItem
      onClick={(event) => {
        onClick();
        event.stopPropagation();
      }}
      data-testid={testId}
    >
      <ListItemIcon>
        <DeleteRoundedIcon fontSize="small" className={classes.deleteIcon} />
      </ListItemIcon>
      <ListItemText className={classes.deleteText}>
        {customText ?? getDeleteText(removeText, deregisterText)}
      </ListItemText>
    </MenuItem>
  );
};

const getDeleteText = (removeText: boolean, deregisterText: boolean) => {
  if (removeText) {
    return content.ACTION_REMOVE;
  }
  if (deregisterText) {
    return content.ACTION_DEREGISTER;
  }
  return content.ACTION_DELETE;
};
