import { TExtractFnReturnType, TQueryConfig, useQuery } from '@marlin/shared/utils/react-query';

import { getLocationHierarchy } from '../infrastructure/hierarchy';

type TQueryFnType = typeof getLocationHierarchy;

type TUseLocationOptions = {
  locationId: string;
  config?: Omit<TQueryConfig<TQueryFnType>, 'queryKey' | 'queryFn'>;
};

export const useLocationHierarchy = ({ locationId, config }: TUseLocationOptions) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>, Error>({
    queryKey: ['location', locationId, 'hierarchy'],
    queryFn: () => getLocationHierarchy({ locationId }),
    ...config,
  });
};
