import { useCreateGateway, useUpdateGateway } from '@marlin/asset/data-access/gateway';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { AxiosError } from 'axios';
import React, { useCallback } from 'react';
import { z } from 'zod';

import { getConfigurationPayload } from '../components/form/get-configuration-payload';
import {
  TUpsertConnectionFormSchemaType,
  TUpsertConnectionFormSchemaTypeUpdate,
} from '../components/form/upsert-connection-form.schema';
import { content } from '../content';
import { TAddressErrors } from '../context/test-flow.context';
import { isUpdating } from '../utils/is-updating.utils';

interface ISaveConfiguration {
  deviceId: string;
  onClose: () => void;
  refetch: () => void;
  data: TUpsertConnectionFormSchemaType | TUpsertConnectionFormSchemaTypeUpdate;
  setAddressErrors: React.Dispatch<React.SetStateAction<TAddressErrors | null>>;
}

export const useSaveConfiguration = ({ deviceId, onClose, data, refetch, setAddressErrors }: ISaveConfiguration) => {
  const { enqueueSnackbar } = useSnackbar();

  const onError = useCallback(
    (error: AxiosError<{ errorMessage: string; status: string }>) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);

      if (error?.response?.data?.status) {
        try {
          setAddressErrors(JSON.parse(error?.response?.data.errorMessage));
        } catch (e) {
          enqueueSnackbar(error?.response?.data.errorMessage, { variant: 'error' });
          setAddressErrors(null);
        }
      } else {
        enqueueSnackbar(content.ERROR_MESSAGE, { variant: 'error' });
      }

      onClose();
    },
    [enqueueSnackbar, onClose, setAddressErrors]
  );

  const onSaveSuccess = useCallback(() => {
    enqueueSnackbar(content.SAVE_MESSAGE, { variant: 'success' });
    refetch();
    onClose();
  }, [enqueueSnackbar, onClose, refetch]);

  const createGatewayMutation = useCreateGateway({
    onError,
    onSuccess: onSaveSuccess,
  });
  const updateGatewayMutation = useUpdateGateway({
    onError,
    onSuccess: onSaveSuccess,
  });

  const handleSave = () => {
    const payload = getConfigurationPayload(data, deviceId);
    if (data && isUpdating(payload)) {
      updateGatewayMutation.mutate({ data: { ...payload } });
    } else {
      createGatewayMutation.mutate({ data: payload });
    }
  };

  const isLoading = updateGatewayMutation.isPending || createGatewayMutation.isPending;

  return { handleSave, isLoading };
};
