import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  row: {
    display: 'flex',
    gap: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(32),
    '&:first-child': {
      marginTop: theme.typography.pxToRem(16),
    },
  },

  fullWidth: {
    width: '100%',
  },
  subsectionTitle: {
    marginTop: theme.typography.pxToRem(8),
    fontWeight: theme.typography.fontWeightBold,
  },
  groupHeader: {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    padding: `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(16)}`,
  },
  groupList: {
    padding: 0,
  },
  helperText: {
    paddingLeft: theme.typography.pxToRem(12),
  },
}));
