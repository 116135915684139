import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import {
  INVITATION_STATUS,
  TInvitationsResponseData,
  invitationStatusSchema,
  invitationsResponseDataSchema,
} from './invitations.schema';

export const deleteInvitationsByStatus = async (status: INVITATION_STATUS): Promise<TInvitationsResponseData> => {
  const invitationStatus = safeParseData(status, invitationStatusSchema);

  const response = await getHttpClient().delete(paths.INVITATION_STATUS, {
    params: { invitationStatus },
  });
  return safeParseData(response, invitationsResponseDataSchema);
};
