import { MarlinTheme } from '@marlin/shared/theme';
import { CommissionDateField } from '@marlin/shared/ui-form';
import { FormField, INPUT_TYPE, Input, Select } from '@marlin/shared/ui-form-common';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import {
  GATEWAY_REGISTRATION_ERROR,
  TUpsertGatewayForm,
  TUpsertGatewayUpdateForm,
} from '@marlin/shared/utils/gateway-utils';
import { TGatewayInfoUpdatePayload } from '@marlin/shared/utils/zod';
import { Box, Button, Icon, IconButton, Paper, SelectChangeEvent } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../shared/content';
import { useGatewayUpsertForm } from '../shared/hooks/use-gateway-upsert-form.hook';
import { useLocationList } from '../shared/hooks/use-location-list.hook';
import { UpdateGatewayLeaveGuard } from './update-gateway-leave-guard.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  buttonWrapper: {
    width: '100%',
    marginTop: theme.typography.pxToRem(8),
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    width: theme.typography.pxToRem(324),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: theme.typography.pxToRem(712),
    padding: theme.typography.pxToRem(24),
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: theme.typography.pxToRem(16),
      borderRadius: 0,
      fontSize: theme.typography.body2.fontSize,
    },
  },
  input: {
    marginBottom: theme.typography.pxToRem(32),
    flexBasis: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.typography.pxToRem(16),
    },
  },
  select: {
    width: '100%',
  },
  formSection: {
    margin: `${theme.typography.pxToRem(16)} 0`,
    fontSize: theme.typography.pxToRem(16),
    width: '100%',
  },
  crossIcon: {
    marginRight: theme.typography.pxToRem(5),
  },
  link: {
    color: theme.palette.info.light,
  },
  registerLink: {
    color: theme.palette.info.light,
    marginLeft: 'auto',
    textAlign: 'right',
    width: '100%',
    marginTop: theme.typography.pxToRem(32),
    cursor: 'pointer',
    fontSize: theme.typography.pxToRem(12),
  },
  registerCodeFields: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

interface IGatewayUpsertFormProps {
  defaultValues?: TGatewayInfoUpdatePayload;
  gatewayId?: string;
  onSubmit: ((value: TUpsertGatewayUpdateForm) => void) | ((value: TUpsertGatewayForm) => void);
  registrationErrorCode: GATEWAY_REGISTRATION_ERROR | null;
  title: string;
  subtitle: string;
}

export const GatewayUpsertForm = ({
  defaultValues,
  onSubmit,
  gatewayId,
  registrationErrorCode,
  title,
  subtitle,
}: IGatewayUpsertFormProps) => {
  const { classes } = useStyles();
  const { locationsList } = useLocationList();

  const {
    form,
    dateValidationErrorMessage,
    registerCode,
    errors,
    disableSubmitButton,
    setDateValidationErrorMessage,
    handleClearRegisterCode,
  } = useGatewayUpsertForm({ defaultValues, registrationErrorCode });

  const { goTo } = useRouter();

  const handleOnCancel = () => {
    goTo(routes.gateway.list.path);
  };

  return (
    <PageContainer prefix="create-gateway">
      <PageHeader icon={null} title={title} subtitle={subtitle} prefix="gateway-header" />
      <Paper className={classes.formContainer}>
        <FormProvider {...form}>
          <div className={classes.formSection}>{content.GATEWAY_LABEL}</div>
          <FormField<TUpsertGatewayForm> fieldName="name">
            {(props) => (
              <Input
                {...props}
                className={classes.input}
                label={content.GATEWAY_NAME}
                required
                testId="gateway-name-field"
                error={
                  errors.name && {
                    type: 'custom',
                    message: errors.name.message,
                  }
                }
              />
            )}
          </FormField>
          <FormField<TUpsertGatewayForm> fieldName="locationId">
            {(props) => (
              <Select
                {...props}
                required
                className={classes.select}
                prefix="locationId"
                label={content.LOCATION_NAME}
                data={locationsList || []}
                onChange={(e: SelectChangeEvent) => {
                  form.clearErrors('locationId');
                  props.onChange && props.onChange(e);
                }}
                error={errors.locationId}
                requiredErrorMessageOnBlur={content.REQUIRED}
              />
            )}
          </FormField>

          {!gatewayId && (
            <>
              <div className={classes.registerCodeFields}>
                <div className={classes.formSection}>{content.REGISTER_LABEL}</div>
              </div>
              <FormField<TUpsertGatewayForm> fieldName="registrationCode">
                {(props) => (
                  <Box width="100%" data-testid="register-code-field-wrapper">
                    <Input
                      {...props}
                      required
                      type={INPUT_TYPE.NUMBER}
                      maxLength={8}
                      className={classes.input}
                      label={content.REGISTRATION_CODE}
                      testId="register-code-field"
                      error={
                        errors.registrationCode && {
                          type: 'custom',
                          message: errors.registrationCode.message,
                        }
                      }
                      externalEndAdornment={{
                        endAdornment: (
                          <>
                            {registerCode && (
                              <IconButton
                                className={classes.crossIcon}
                                onClick={handleClearRegisterCode}
                                data-testid="register-code-clear-button"
                              >
                                <Icon baseClassName="material-symbols-outlined">{content.CANCEL}</Icon>
                              </IconButton>
                            )}
                          </>
                        ),
                      }}
                    />
                  </Box>
                )}
              </FormField>
            </>
          )}

          <div className={classes.formSection}>{content.ADDITIONAL_INFORMATION_LABEL}</div>
          <CommissionDateField
            setDateValidationErrorMessage={setDateValidationErrorMessage}
            dateValidationErrorMessage={dateValidationErrorMessage}
          />
          <FormField<TUpsertGatewayForm> fieldName="description">
            {(props) => (
              <Input
                {...props}
                className={classes.input}
                label={content.DESCRIPTION}
                testId="description-field"
                maxLength={500}
                multiline
              />
            )}
          </FormField>
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.button}
              variant="outlined"
              data-testid="gateway-cancel-button"
              onClick={handleOnCancel}
            >
              {content.CANCEL}
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              data-testid="gateway-submit-button"
              onClick={form.handleSubmit(onSubmit)}
              disabled={disableSubmitButton}
            >
              {gatewayId ? content.UPDATE : content.CREATE}
            </Button>
          </div>
        </FormProvider>
      </Paper>
      <UpdateGatewayLeaveGuard
        shouldDisplayGuard={
          form.formState.isDirty && (!form.formState.isSubmitSuccessful || !form.formState.isSubmitting)
        }
      />
    </PageContainer>
  );
};
