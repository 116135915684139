import { ALERT_STATUS_FILTER, ICriticality } from '@marlin/alert/data-access/alert-action';
import { CRITICALITY, TCriticality } from '@marlin/shared/ui-criticality';
import { useDebounceValue } from '@marlin/shared/utils/react-query';
import { useCallback, useEffect, useState } from 'react';

import { IFilterParams, defaultFilterParams } from '../../details/components/event-log/event-log.component';

export interface IUseFiltering {
  criticalityChange: (value: TCriticality) => void;
  statusChange: (value: ALERT_STATUS_FILTER) => void;
  status: IStatus;
  handleClearAll: () => void;
  onChangeSearchValue: (value: string) => void;
  textSearchValue: string;
}

export interface IStatus {
  new: boolean;
  resolved: boolean;
}

const getCriticality = (criticality: TCriticality, currentParams: ICriticality) => {
  switch (criticality) {
    case CRITICALITY.LOW:
      return {
        ...currentParams,
        low: !currentParams.low,
      };
    case CRITICALITY.HIGH:
      return {
        ...currentParams,
        high: !currentParams.high,
      };

    default:
      return currentParams;
  }
};

const getStatus = (status: ALERT_STATUS_FILTER, currentParams: IStatus) => {
  switch (status) {
    case ALERT_STATUS_FILTER.CURRENT:
      return {
        ...currentParams,
        new: !currentParams.new,
      };
    case ALERT_STATUS_FILTER.RESOLVED:
      return {
        ...currentParams,
        resolved: !currentParams.resolved,
      };

    default:
      return currentParams;
  }
};

const mapStatusToStatusParam = (status: IStatus) => {
  if (status.new && status.resolved) {
    return ALERT_STATUS_FILTER.ALL;
  } else if (status.new) {
    return ALERT_STATUS_FILTER.CURRENT;
  } else if (status.resolved) {
    return ALERT_STATUS_FILTER.RESOLVED;
  }

  return ALERT_STATUS_FILTER.ALL;
};

export const useFiltering = (onChangeParams: React.Dispatch<React.SetStateAction<IFilterParams>>): IUseFiltering => {
  const [status, setStatus] = useState<IStatus>({
    new: false,
    resolved: false,
  });
  const [textSearchValue, setTextSearchValue] = useState('');
  const debouncedTextSearchValue = useDebounceValue(textSearchValue, 500);

  const onChangeSearchValue = (value: string) => {
    setTextSearchValue(value);
  };

  const handleChangeParams = useCallback(
    (name: keyof IFilterParams, value?: string | string[]) => {
      onChangeParams((prevParams) => ({
        ...prevParams,
        [name]: value,
      }));
    },
    [onChangeParams]
  );

  useEffect(() => {
    handleChangeParams('search', debouncedTextSearchValue);
  }, [debouncedTextSearchValue, handleChangeParams]);

  const criticalityChange = useCallback(
    (value: TCriticality) => {
      onChangeParams((prevParams) => ({
        ...prevParams,
        criticality: getCriticality(value, prevParams.criticality),
      }));
    },
    [onChangeParams]
  );

  const statusChange = useCallback(
    (value: ALERT_STATUS_FILTER) => {
      const currentStatus = getStatus(value, status);
      setStatus(currentStatus);

      const statusParam = mapStatusToStatusParam(currentStatus);

      onChangeParams((prevParams) => ({
        ...prevParams,
        status: statusParam,
      }));
    },
    [onChangeParams, status]
  );

  const handleClearAll = useCallback(() => {
    onChangeParams(defaultFilterParams);
    setTextSearchValue('');
    setStatus({
      new: false,
      resolved: false,
    });
  }, [onChangeParams]);

  return {
    criticalityChange,
    statusChange,
    status,
    handleClearAll,
    onChangeSearchValue,
    textSearchValue,
  };
};
