import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

interface IActionCustomProps {
  optionText: string;
  onClick?: () => void;
  disabled?: boolean;
  icon?: JSX.Element;
  dataTestId?: string;
}

export const ActionCustom = ({ optionText, onClick, disabled = false, icon, dataTestId }: IActionCustomProps) => {
  return (
    <MenuItem data-testid={dataTestId} disabled={disabled} onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{optionText}</ListItemText>
    </MenuItem>
  );
};
