import { IconEquipmentMode } from '@marlin/asset/shared/ui/assets';

import { content } from './content';
import type { TStatusMap } from './types';

export const statusIdToKeyAdapter = (id: string): string | undefined =>
  ({
    '0': 'startup',
    '1': 'auto',
    '2': 'idle',
    '3': 'manual',
    '4': 'sanitize',
    '5': 'cooldown',
  }[id]);

export const intellistationEquipmentModeLabelMap: TStatusMap = new Map([
  ['auto', { mode: content.EQUIPMENT_MODE_LABELS.AUTO, Icon: IconEquipmentMode.Auto }],
  ['manual', { mode: content.EQUIPMENT_MODE_LABELS.MANUAL, Icon: IconEquipmentMode.Manual }],
  ['idle', { mode: content.EQUIPMENT_MODE_LABELS.IDLE, Icon: IconEquipmentMode.Standby }],
  [
    'sanitize',
    {
      mode: content.EQUIPMENT_MODE_LABELS.SANITIZE,
      Icon: IconEquipmentMode.IntellistationSanitize,
    },
  ],
  [
    'startup',
    {
      mode: content.EQUIPMENT_MODE_LABELS.START_UP,
      Icon: IconEquipmentMode.IntellistationStartup,
    },
  ],
  ['cooldown', { mode: content.EQUIPMENT_MODE_LABELS.COOL_DOWN, Icon: IconEquipmentMode.Cool }],
]);
