import { IconEquipmentMode } from '@marlin/asset/shared/ui/assets';

import { content } from './content';
import type { TStatusMap } from './types';

export const statusIdToKeyAdapterSbs = (id: string): string | undefined =>
  ({
    '0': 'off',
    '1': 'on',
  }[id]);

export const statusIdToKeyAdapterSsc = (id: string): string | undefined =>
  ({
    '0': 'establishingSteamTemp',
    '1': 'establishingSteamTime',
    '2': 'heatOn',
    '3': 'heatOff',
    '4': 'coolDown',
    '5': 'wwsd',
    '6': 'noHeatNormal',
    '7': 'noHeatAbortCutoff',
    '8': 'overrideMaxHeat',
    '9': 'overrideHand',
    '10': 'overrideOff',
  }[id]);

export const tekmarSbsEquipmentModeLabelMap: TStatusMap = new Map([
  ['on', { mode: content.EQUIPMENT_MODE_SBS_LABELS.OVERRIDE, Icon: IconEquipmentMode.Sync }],
  ['off', { mode: content.EQUIPMENT_MODE_SBS_LABELS.AUTOMATIC, Icon: IconEquipmentMode.Auto }],
]);

export const tekmarSscEquipmentModeLabelMap: TStatusMap = new Map([
  ['wwsd', { mode: content.EQUIPMENT_MODE_SSC_LABELS.WWSD, Icon: IconEquipmentMode.TekmarWeatherShutdown }],
  [
    'overrideOff',
    {
      mode: content.EQUIPMENT_MODE_SSC_LABELS.OVERRIDE_OFF,
      Icon: IconEquipmentMode.TekmarHeatOff,
    },
  ],
  [
    'overrideMaxHeat',
    {
      mode: content.EQUIPMENT_MODE_SSC_LABELS.OVERRIDE_MAX_HEAT,
      Icon: IconEquipmentMode.TekmarHeatOn,
    },
  ],
  ['overrideHand', { mode: content.EQUIPMENT_MODE_SSC_LABELS.OVERRIDE_HAND, Icon: IconEquipmentMode.Manual }],
  [
    'establishingSteamTemp',
    {
      mode: content.EQUIPMENT_MODE_SSC_LABELS.ESTABLISH_STEAM_MODE,
      Icon: IconEquipmentMode.TekmarHeat,
    },
  ],
  ['heatOn', { mode: content.EQUIPMENT_MODE_SSC_LABELS.HEAT_ON, Icon: IconEquipmentMode.TekmarHeatOn }],
  [
    'heatOff',
    {
      mode: content.EQUIPMENT_MODE_SSC_LABELS.HEAT_OFF,
      Icon: IconEquipmentMode.TekmarHeatOff,
    },
  ],
  [
    'establishingSteamTime',
    {
      mode: content.EQUIPMENT_MODE_SSC_LABELS.ESTABLISH_STEAM_MODE,
      Icon: IconEquipmentMode.TekmarHeat,
    },
  ],
  ['coolDown', { mode: content.EQUIPMENT_MODE_SSC_LABELS.COOL_DOWN, Icon: IconEquipmentMode.Cool }],
  ['noHeatNormal', { mode: content.EQUIPMENT_MODE_SSC_LABELS.NO_HEAT_NORMAL, Icon: IconEquipmentMode.Sync }],
  ['noHeatAbortCutoff', { mode: content.EQUIPMENT_MODE_SSC_LABELS.NO_HEAT_ABORT_CUTOFF, Icon: IconEquipmentMode.Sync }],
]);
