import { useMetadataByModels } from '@marlin/asset/data-access/equipment';
import { flowVolumeDatapointNames, useMultiChartsStore, useSetMultiChartsStore } from '@marlin/asset/ui/charts';
import { TDeviceMetadataResponseRaw, TModelsMetadataResponse } from '@marlin/shared/utils/datapoint-mappers';
import partition from 'lodash/partition';
import uniq from 'lodash/uniq';
import { useCallback, useMemo } from 'react';

import { useSensorFilteringStore } from '../context/sensor-filtering.context';
import { ILocationData, TGroupNames, TSensorMetadata } from '../types';
import { parseOptions } from '../utils/parse-options';
import { useEquipmentWithDevicesQuery, useLocationsWithDevicesQuery } from './use-filtered-queries.hook';

export const useMultiChartMetadata = () => {
  const [selectedLocationId] = useSensorFilteringStore((store) => store.selectedLocationId);
  const setMultiChartsStore = useSetMultiChartsStore();
  const [metadata] = useMultiChartsStore((store) => store.metadata);

  const locationQuery = useLocationsWithDevicesQuery();
  const equipmentQuery = useEquipmentWithDevicesQuery({ selectedLocationId });

  const allLocationsData = useMemo<ILocationData[]>(() => {
    if (!locationQuery.data?.data) return [];

    return locationQuery.data.data.map((location) => {
      const [sensorsList, equipmentList] = partition(location.devices, (device) => device.type !== 'Equipment');

      return {
        id: location.id,
        name: location.name,
        equipmentList,
        sensorsList,
      };
    });
  }, [locationQuery?.data?.data]);

  const allModels = useMemo(
    () =>
      uniq(
        allLocationsData.reduce<string[]>((acc, location) => {
          return [
            ...acc,
            ...location.equipmentList.map((equipment) => equipment.model),
            ...location.sensorsList.map((sensor) => sensor.model),
          ];
        }, [])
      ),
    [allLocationsData]
  );

  const onMetadataSuccess = useCallback(
    (data: TModelsMetadataResponse) => {
      const metadata = data.reduce<TSensorMetadata>((acc, dataForModel) => {
        const groupDatapoints = dataForModel.metadata.chartDatapoints
          .map((group) => {
            return getGroupDatapoints(dataForModel.metadata.chartDatapoints, group.groupName)
              .filter((datapoint) => !flowVolumeDatapointNames.includes(datapoint.name))
              .map((datapoint) => ({
                ...datapoint,
                group: group.groupName.toLowerCase() as TGroupNames,
              }));
          })
          .flat();

        return { ...acc, [dataForModel.model]: groupDatapoints };
      }, {});

      setMultiChartsStore({ metadata });
    },
    [setMultiChartsStore]
  );

  const metadataQuery = useMetadataByModels(allModels, onMetadataSuccess);

  const allOptions = useMemo(() => parseOptions(allLocationsData, metadata), [allLocationsData, metadata]);

  const hasNoData = useMemo(() => {
    if (!allModels.length) {
      return true;
    }

    return metadataQuery?.data?.length === 0;
  }, [allModels.length, metadataQuery.data]);

  const refresh = useCallback(() => {
    locationQuery.refetch();
    equipmentQuery.refetch();
    metadataQuery.refetch();
  }, [locationQuery, equipmentQuery, metadataQuery]);

  const isInitialLoading =
    locationQuery.isInitialLoading || metadataQuery.isInitialLoading || equipmentQuery.isInitialLoading;

  return {
    allOptions,
    isLoading: (locationQuery.isLoading || metadataQuery.isLoading || equipmentQuery.isLoading) && isInitialLoading,
    isError: locationQuery.isError || metadataQuery.isError || equipmentQuery.isError,
    hasNoData,
    refresh,
    metadata,
  };
};

const getGroupDatapoints = (chartDatapoints: TDeviceMetadataResponseRaw['chartDatapoints'], groupName: string) => {
  if (
    groupName.toLowerCase() === 'temperature' &&
    chartDatapoints.some((group) => group.groupName.toLowerCase() === 'boilerfiringrate')
  ) {
    const boilerFiringRateDatapoints =
      chartDatapoints.find((group) => group.groupName.toLowerCase() === 'boilerfiringrate')?.datapoints ?? [];
    const temperatureDatapoints =
      chartDatapoints.find((group) => group.groupName.toLowerCase() === 'temperature')?.datapoints ?? [];
    return [...temperatureDatapoints, ...boilerFiringRateDatapoints].filter(
      (datapoint) => datapoint.displayType === 'datapoint'
    );
  }

  const datapoints =
    chartDatapoints.find((group) => group.groupName.toLowerCase() === groupName.toLowerCase())?.datapoints ?? [];
  return datapoints.filter((datapoint) => datapoint.displayType === 'datapoint');
};
