export function createArrayIndexMap<T>(array: T[]): Map<T, number> {
  const map = new Map<T, number>();
  array.forEach((value, index) => {
    map.set(value, index);
  });
  return map;
}

export const areAllValuesZero = (values: number[]): boolean => values.every((value) => value === 0);

export const hasOnlyOneElement = (array: unknown[]): boolean => array.length === 1;
