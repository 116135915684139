const automation = '/workflow/api/automated-actions';
const automationDetails = `${automation}/{automationId}`;
const automationSnooze = `${automationDetails}/snooze`;
const automationHistory = `${automation}/{deviceId}/history`;

export const paths = {
  AUTOMATION_LIST: `${automation}/filter`,
  AUTOMATION_CREATE: automation,
  AUTOMATION: automationDetails,
  AUTOMATION_HISTORY: automationHistory,
  AUTOMATION_DELETE: automationDetails,
  AUTOMATION_SNOOZE: automationSnooze,
  AUTOMATION_SNOOZE_CANCEL: `${automationSnooze}/cancel`,
  ASSETS_DEVICE_LIST: 'api/assets/devices',
  ASSETS_LOCATION_LIST: 'api/assets/locations',
  ASSETS_CURRENT_LOCATION_LIST: 'api/assets/{locationId}/locations',
  ASSETS_EQUIPMENT_LIST: 'api/assets/equipment',
  AUTOMATIONS_COMPLEX: `${automation}/complex`,
  AUTOMATION_COMPLEX: `${automation}/complex/{automationId}`,
};
