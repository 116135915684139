import { getUomSymbol } from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

export const uomTypeSchema = z.union([
  z.literal('ft3'),
  z.literal('l'),
  z.literal('gal'),
  z.literal('m3'),
  z.literal('sm3'),
  z.literal('scf'),
]);

export type TUomType = z.infer<typeof uomTypeSchema>;

interface IUomToggleOption {
  value: NonNullable<TUomType>;
  label: string;
}

export const uomGasToggleOptions: IUomToggleOption[] = [
  {
    value: 'scf',
    label: getUomSymbol('scf') ?? '',
  },
  {
    value: 'sm3',
    label: getUomSymbol('sm3') ?? '',
  },
];

export const uomWaterToggleOptions: IUomToggleOption[] = [
  {
    value: 'ft3',
    label: getUomSymbol('ft3') ?? '',
  },
  {
    value: 'l',
    label: getUomSymbol('l') ?? '',
  },
  {
    value: 'gal',
    label: getUomSymbol('gal') ?? '',
  },
  {
    value: 'm3',
    label: getUomSymbol('m3') ?? '',
  },
];
