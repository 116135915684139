import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getRecipients } from '../infrastructure/get-recipients';
import { IFilterParams, queryKey } from '../query-key.enum';

type TQueryFnType = typeof getRecipients;

interface IUseRecipientsProps {
  filters?: IFilterParams;
  enabled?: boolean;
  addDeleted?: boolean;
}

export const useRecipients = ({ filters, enabled, addDeleted }: IUseRecipientsProps = {}) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>, Error>({
    queryKey: queryKey.RECIPIENTS({ ...filters, addDeleted }),
    queryFn: () => getRecipients({ addDeleted }),
    enabled,
  });
};
