import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { deleteInvitation } from '../infrastructure/delete-invitation';
import { QUERY_KEY } from './query-key.enum';

export const useDeleteInvitation = () => {
  return useMutation({
    mutationFn: deleteInvitation,
    onSettled: () => {
      return queryClient.invalidateQueries({ queryKey: [QUERY_KEY.USER] });
    },
  });
};
