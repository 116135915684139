import { dateAdapter } from '@marlin/shared/utils-common-date';
import moment from 'moment/moment';

const liveDataExpirationSecondsMap = new Map([
  ['SmartBaseStationDT550', 600], // 10 minutes
  ['NexaGateway', 28800], // 8 hours
]);

export const getIsOnline = (
  timestamp: string | undefined,
  lastReadingTime?: string | undefined,
  model: string = ''
) => {
  const liveDataExpirationSeconds = liveDataExpirationSecondsMap.get(model) ?? 28800;

  if (timestamp) {
    const diffSeconds = moment.duration(dateAdapter.date()?.diff(dateAdapter.date(timestamp))).asSeconds();

    return diffSeconds < liveDataExpirationSeconds;
  }

  if (lastReadingTime) {
    const diffSeconds = moment.duration(dateAdapter.date()?.diff(dateAdapter.date(lastReadingTime))).asSeconds();

    return diffSeconds < liveDataExpirationSeconds;
  }

  return false;
};
