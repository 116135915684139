import { useAvailableBoilers, useMetadata } from '@marlin/asset/data-access/equipment';
import { IGlobalEquipmentConfig } from '@marlin/asset/shared/equipment-config';
import { TDeviceMetadataResponse } from '@marlin/shared/utils/datapoint-mappers';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { useMemo } from 'react';

import { extractBoilerNumber } from '../../../shared/utils/extract-boiler-names';

export const useChartDatapoints = (config: IGlobalEquipmentConfig | null) => {
  const equipmentId = useIdFromPathname();

  const {
    data: metadata,
    isLoading: isMetadataLoading,
    refetch: refetchMetadata,
    isError: isMetadataError,
  } = useMetadata({ equipmentId, enabled: false });

  const checkAvailableBoilers = useMemo(
    () => config?.chart.some((chart) => chart.checkAvailableBoilers) ?? false,
    [config]
  );

  const boilerGroupNames = useMemo(() => {
    return (
      metadata?.datapointGroups
        ?.filter((group) => group.groupName.includes('Boiler'))
        .map((group) => group.groupName) ?? []
    );
  }, [metadata?.datapointGroups]);

  const {
    data: availableBoilers,
    isError: isAvailableBoilersError,
    isLoading: boilersLoading,
  } = useAvailableBoilers({
    equipmentId,
    checkAvailableBoilers,
  });

  const availableBoilersNames = useMemo(() => {
    if (!availableBoilers || !boilerGroupNames.length) return [];
    return availableBoilers.datapoints
      .map((boiler) => boilerGroupNames[extractBoilerNumber(boiler.name) - 1])
      .sort((a, b) => extractBoilerNumber(a) - extractBoilerNumber(b));
  }, [availableBoilers, boilerGroupNames]);

  const chartDatapoints = useMemo(() => {
    if (!metadata) return [];

    if (checkAvailableBoilers && !isAvailableBoilersError) {
      const boilersGroupName = config?.chart.find((chart) => chart.checkAvailableBoilers)?.name;

      const boilersGroup = metadata.chartDatapoints.find(
        (group) => group.groupName.toLowerCase() === boilersGroupName?.toLowerCase()
      );

      const boilersGroupWithDefaults = boilersGroup?.datapoints.map((datapoint) => {
        const boilerNumber = extractBoilerNumber(datapoint.name);
        const isDefault = Boolean(availableBoilersNames.some((boiler) => extractBoilerNumber(boiler) === boilerNumber));

        return {
          ...datapoint,
          isDefault,
        };
      });

      if (boilersGroupWithDefaults?.length && boilersGroupName) {
        const boilersChartDatapointsGroup: TDeviceMetadataResponse['chartDatapoints'][number] = {
          groupName: boilersGroupName,
          datapoints: boilersGroupWithDefaults,
        };

        return [
          ...metadata.chartDatapoints.filter(
            (group) => group.groupName.toLowerCase() !== boilersGroupName.toLowerCase()
          ),
          boilersChartDatapointsGroup,
        ];
      }
    }

    return metadata.chartDatapoints;
  }, [availableBoilersNames, checkAvailableBoilers, config?.chart, isAvailableBoilersError, metadata]);

  return {
    isLoading: isMetadataLoading || (checkAvailableBoilers && boilersLoading),
    refetchMetadata,
    isMetadataError,
    chartDatapoints,
  };
};
