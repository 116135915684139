import { useGetProfile } from '@marlin/account-data-access-organization';
import { DataLoader } from '@marlin/shared/ui-loader';
import { PageContainer, PageHeader, SystemConstraintsPage } from '@marlin/shared/ui-page';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import HelpRounded from '@mui/icons-material/HelpRounded';
import { Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { HelpForm } from '../components/form/help-form.component';
import { SendSuccess } from '../components/send-success/send-success.component';
import { content } from '../content';

export const DesktopHelpHub = () => {
  const { goTo } = useRouter();
  const { pathname } = useLocation();

  const handleTabChange = (event: SyntheticEvent, pathname: string) => {
    goTo(pathname);
  };

  const [sendSuccess, setSendSuccess] = useState<boolean>(false);

  const { data, isError, isLoading } = useGetProfile();

  if (isLoading) {
    return <DataLoader />;
  }

  if (isError || !data) {
    return <SystemConstraintsPage />;
  }

  return (
    <PageContainer data-testid="help-page">
      <PageHeader
        icon={<HelpRounded />}
        title={content.HELP_HUB_TITLE}
        subtitle={content.HELP_HUB_SUBTITLE}
        prefix="settings-hub-header"
        data-testid="help-page-header"
      />
      <Tabs value={pathname} onChange={handleTabChange}>
        <Tab value={routes.help.root} label={content.CUSTOMER_FEEDBACK} data-testid="customer-feedback-tab" />
      </Tabs>
      {sendSuccess ? (
        <SendSuccess email={data.email} onSuccess={setSendSuccess} />
      ) : (
        <HelpForm firstName={data.firstName} lastName={data.lastName} onSuccess={setSendSuccess} />
      )}
    </PageContainer>
  );
};
