import TemperatureIcon from '@mui/icons-material/DeviceThermostat';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import FlowIcon from '@mui/icons-material/PanoramaPhotosphere';
import PressureIcon from '@mui/icons-material/Speed';

import { Icon } from './components/base-icon.component';
import { IIconEquipment, IIconEquipmentMode, IIconSensor, IIconUoM, TIconRecord, TWebIconProps } from './types';

const DeviceHubIcon = (props: TWebIconProps) => <Icon name="device_hub_rounded" {...props} />;
const ValveIcon = (props: TWebIconProps) => <Icon name="valve" {...props} />;

export const IconEquipment: TIconRecord<IIconEquipment, TWebIconProps> = {
  Boiler: (props) => <Icon name="water_heater" {...props} />,
  SteamBoiler: (props) => <Icon name="water_heater" {...props} />,
  Valve: ValveIcon,
  ShutOffValve: ValveIcon,
  DigitalMixingValve: ValveIcon,
  PressureReducingValve: DeviceHubIcon,
  Pump: DeviceHubIcon,
  HotWaterHeater: (props) => <Icon name="water_voc" {...props} />,
  WaterTank: DeviceHubIcon,
  LeakPrevention: (props) => <Icon name="water_lock_rounded" {...props} />,
  PartitionController: (props) => <Icon name="sensor_door" {...props} />,
  Other: DeviceHubIcon,
  None: DeviceHubIcon,
  HeatPump: (props) => <HeatPumpIcon {...props} />,
};

export const IconSensor: TIconRecord<IIconSensor, TWebIconProps> = {
  FlowMeter: (props) => <FlowIcon {...props} />,
  Pressure: (props) => <PressureIcon {...props} />,
  Temperature: (props) => <TemperatureIcon {...props} />,
  LeakDetector: (props) => <Icon name="water" {...props} />,
  Pump: (props) => <Icon name="water_pump" {...props} />,
};

export const IconUoM: TIconRecord<IIconUoM, TWebIconProps> = {
  Temperature: (props) => <TemperatureIcon {...props} />,
  Pressure: (props) => <PressureIcon {...props} />,
  Water: (props) => <FlowIcon {...props} />,
  Energy: (props) => <Icon name="bolt" {...props} />,
  Gas: (props) => <Icon name="gas_meter" {...props} />,
};

export const IconEquipmentMode: TIconRecord<IIconEquipmentMode, TWebIconProps> = {
  Auto: (props) => <Icon name="hdr_auto" {...props} />,
  Manual: (props) => <Icon name="pan_tool" {...props} />,
  Remote: (props) => <Icon name="directions_walk" {...props} />,
  Error: (props) => <Icon name="warning_rounded" {...props} />,
  Standby: (props) => <Icon name="pending" {...props} />,
  Sync: (props) => <Icon name="autorenew" {...props} />,
  Cool: (props) => <Icon name="ac_unit" {...props} />,
  Offline: (props) => <Icon name="signal_wifi_off" {...props} />,
  Off: (props) => <Icon name="alpha-a-circle" {...props} />,
  IntellistationSanitize: (props) => <Icon name="settings_suggest" {...props} />,
  IntellistationStartup: (props) => <Icon name="switch_access_shortcut" {...props} />,
  TekmarHeatOn: (props) => <Icon name="mode_heat" {...props} />,
  TekmarHeatOff: (props) => <Icon name="mode_heat_off" {...props} />,
  TekmarHeat: (props) => <Icon name="heat" {...props} />,
  TekmarWeatherShutdown: (props) => <Icon name="sunny" {...props} />,
  TekmarMaxHeat: (props) => <Icon name="mode_heat" {...props} />, // TODO
  TekmarSun: (props) => <Icon name="sunny" {...props} />, // TODO
  SentinelInHome: (props) => <Icon name="in_home_mode" {...props} />,
};
