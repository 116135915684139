import { AUTOMATION_RULE_TYPE } from '@marlin/alert/data-access/automated-action';
import { timeDictionary } from '@marlin/shared/utils-common-date';
import {
  TUnitOfMeasure,
  getUnitType,
  getUnitTypeSeparator,
  getUomSymbol,
  roundValue,
} from '@marlin/shared/utils-format-reading';
import { isSensorType } from '@marlin/shared/utils/format-alert';
import isNil from 'lodash/isNil';

import { content } from './content';
import { DEVICE_TYPE, ICondition, IExtendedAutomation } from './types';

interface IDeviceFormat {
  type: string;
  unit: string;
}

export const formatDeviceType = (deviceType: DEVICE_TYPE): IDeviceFormat => {
  switch (deviceType) {
    case DEVICE_TYPE.TEMPERATURE: {
      return {
        type: content.SENSOR_TYPES.TEMPERATURE,
        unit: getUnitType(deviceType),
      };
    }
    case DEVICE_TYPE.PRESSURE: {
      return {
        type: content.SENSOR_TYPES.PRESSURE,
        unit: getUnitType(deviceType),
      };
    }
    case DEVICE_TYPE.PULSE_METER: {
      return {
        type: content.SENSOR_TYPES.FLOW_METER,
        unit: getUnitType(deviceType),
      };
    }
    default: {
      return {
        type: '',
        unit: '',
      };
    }
  }
};

export const formatSystemTriggers = (item: IExtendedAutomation): string => {
  if (item.ruleType === AUTOMATION_RULE_TYPE.BATTERY_LEVEL && item.conditions.thresholdMin)
    return content.SYSTEM_TRIGGERS.SENSOR_BATTERY(item.conditions.thresholdMin.value);
  if (item.ruleType === AUTOMATION_RULE_TYPE.DEVICE_LOST_COMMUNICATION && item.duration.value && item.duration.unit)
    return content.SYSTEM_TRIGGERS.SENSOR_COMMUNICATION(timeDictionary[item.duration.unit], item.duration.value);
  if (item.ruleType === AUTOMATION_RULE_TYPE.GATEWAY_LOST_COMMUNICATION && item.duration.value && item.duration.unit)
    return content.SYSTEM_TRIGGERS.GATEWAY(timeDictionary[item.duration.unit], item.duration.value);
  if (item.ruleType === AUTOMATION_RULE_TYPE.DEVICE_ERROR) return content.ALL_EQUIPMENT_ERRORS;
  if (item.ruleType === AUTOMATION_RULE_TYPE.EQUIPMENT_INFORMATION) return content.EQUIPMENT_INFORMATION;
  return '';
};

export const formatTriggers = (
  conditions: ICondition,
  deviceType?: DEVICE_TYPE,
  uoM?: TUnitOfMeasure | null
): string => {
  if (!deviceType || deviceType === DEVICE_TYPE.GATEWAY) {
    return '';
  }

  if (deviceType === DEVICE_TYPE.LEAK) {
    return content.LEAK_TRIGGER;
  }

  const { type, unit } = formatDeviceType(deviceType);

  const unitOfMeasure = getUomSymbol(uoM) ?? unit;

  const separator = isSensorType(deviceType) ? getUnitTypeSeparator(deviceType) : ' ';

  if (!isNil(conditions.thresholdMax) && !isNil(conditions.thresholdMin)) {
    return content.TRIGGERS.ALL(
      type,
      unitOfMeasure,
      roundValue(conditions.thresholdMax?.value, 'decimal'),
      roundValue(conditions.thresholdMin?.value, 'decimal'),
      separator
    );
  }
  if (!isNil(conditions.thresholdMax)) {
    return content.TRIGGERS.MAX(type, unitOfMeasure, roundValue(conditions.thresholdMax?.value, 'decimal'), separator);
  }
  if (!isNil(conditions.thresholdMin)) {
    return content.TRIGGERS.MIN(type, unitOfMeasure, roundValue(conditions.thresholdMin?.value, 'decimal'), separator);
  }
  return '';
};
