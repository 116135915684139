import { TVolumeDataPoint } from '@marlin/asset/data-access/home-dashboard';
import { TPeriodDate } from '@marlin/shared/ui/chart';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';
import Plot from 'react-plotly.js';

import { useTotalWaterChartLayout } from '../hooks/use-total-water-chart-layout.hook';
import { useTotalWaterChartSeries } from '../hooks/use-total-water-chart-series.hook';
import { TotalWaterChartLegend } from './total-water-chart-legend.component';

interface ITotalWaterChartComponent {
  uoM?: TUnitOfMeasure | null;
  volumeTotal: TVolumeDataPoint[];
  previousPeriodVolumeTotal: TVolumeDataPoint[];
  isFullScreen: boolean;
  selectedUnit: 'volume' | 'dollars';
  currentPeriod: { from: TPeriodDate; to: TPeriodDate };
}

export const TotalWaterChart = ({
  uoM,
  volumeTotal,
  previousPeriodVolumeTotal,
  isFullScreen,
  selectedUnit,
  currentPeriod,
}: ITotalWaterChartComponent) => {
  const series = useTotalWaterChartSeries({ volumeTotal, previousPeriodVolumeTotal, selectedUnit });
  const layout = useTotalWaterChartLayout({
    uoM,
    currentPeriod,
    selectedUnit,
  });

  return (
    <>
      <TotalWaterChartLegend series={series} uoM={uoM} selectedUnit={selectedUnit} />
      <Plot
        data={series}
        layout={layout}
        useResizeHandler={true}
        config={{
          displaylogo: false,
          displayModeBar: false,
          responsive: true,
        }}
        style={{ width: '100%', height: isFullScreen ? '100%' : '468px' }}
      />
    </>
  );
};
