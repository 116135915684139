import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  labelIcon: {
    display: 'flex',
    padding: theme.typography.pxToRem(8),
    alignItems: 'center',
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    borderRadius: theme.typography.pxToRem(32),
  },
  labelRow: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
  },
}));
