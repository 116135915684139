import { TExtractFnReturnType, TQueryConfig, useQuery } from '@marlin/shared/utils/react-query';

import { getAutomation } from '../infrastructure/automation';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getAutomation;

type TUseAutomationOptions = {
  automationId: string;
  config?: TQueryConfig<TQueryFnType>;
};

export const useAutomation = ({ automationId, config }: TUseAutomationOptions) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>, Error>({
    ...config,
    queryKey: queryKey.AUTOMATION(automationId),
    queryFn: () => getAutomation(automationId),
  });
};
