import { TMutationConfig, getHttpClient, queryClient } from '@marlin/shared/utils/react-query';
import { UseMutationOptions, useMutation } from '@marlin/shared/utils/react-query';
import { FieldValues } from 'react-hook-form';

import { ILocation } from '../location.model';
import { queryKey } from './query-key.enum';

const updateLocationDto = (data: FieldValues) => {
  for (const variableKey in data) {
    if (data[variableKey] === '') {
      delete data[variableKey];
    }
  }
  return data;
};

type TCreateLocationType = {
  data: ILocation;
  locationId: string;
};

export const updateLocation = ({ data, locationId }: TCreateLocationType): Promise<ILocation> => {
  return getHttpClient().put(`/api/Location/{locationId}`, updateLocationDto(data), { params: { locationId } });
};

type TUseUpdateLocationOptions = {
  config?: TMutationConfig<typeof updateLocation>;
  onSuccess?: () => void;
  onError?: () => void;
};

export const useUpdateLocation = ({ config, onSuccess, onError }: TUseUpdateLocationOptions = {}) => {
  return useMutation<ILocation, unknown, TCreateLocationType>({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onMutate: async (updatingLocation: any) => {
      await queryClient.cancelQueries({ queryKey: ['location', updatingLocation?.locationId] });

      const previousLocation = queryClient.getQueryData<ILocation[]>(['location', updatingLocation?.locationId]);

      queryClient.setQueryData(['location', updatingLocation?.locationId], {
        ...previousLocation,
        ...updatingLocation.data,
        id: updatingLocation.locationId,
      });

      return { previousLocation };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (_: any, __: TCreateLocationType, context: any) => {
      onError && onError();

      if (context?.previousLocation) {
        queryClient.setQueryData(['location'], context.previousLocation);
      }
    },
    onSuccess: (data: ILocation) => {
      queryClient.invalidateQueries({ queryKey: queryKey.LOCATIONS() });
      queryClient.refetchQueries({ queryKey: ['location', data.id] });
      // TODO: (REFACTOR_QUERY) Move snackbar to feature layer
      onSuccess && onSuccess();
    },
    ...config,
    mutationFn: updateLocation,
  } as UseMutationOptions<ILocation, unknown, TCreateLocationType>);
};
