import { MarlinTheme } from '@marlin/shared/theme';
import SaveIcon from '@mui/icons-material/Save';
import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: `-${theme.typography.pxToRem(16)}`,
    marginTop: theme.typography.pxToRem(16),
  },
  button: {
    marginLeft: theme.typography.pxToRem(8),
  },
}));

export interface ICalibrationFormButtonsProps {
  onConfirm: () => void;
  onSaveForLater: () => void;
  onCancel: () => void;
}

export const CalibrationFormButtons = ({ onConfirm, onCancel, onSaveForLater }: ICalibrationFormButtonsProps) => {
  const { classes } = useStyles();
  const { formState } = useFormContext();

  return (
    <div data-testid="calibration-modal-footer" className={classes.footer}>
      <Button variant="text" onClick={onCancel} data-testid="cancel-button">
        {content.CALIBRATION_MODAL.CANCEL}
      </Button>
      <div>
        <Button
          className={`${classes.button}`}
          variant="text"
          onClick={onSaveForLater}
          endIcon={<SaveIcon />}
          data-testid="save-for-later-button"
        >
          {content.CALIBRATION_MODAL.SAVE_FOR_LATER}
        </Button>
        <Button
          disabled={!formState.isValid}
          className={`${classes.button}`}
          variant="contained"
          onClick={onConfirm}
          data-testid="calibrate-button"
        >
          {content.CALIBRATION_MODAL.CALIBRATE}
        </Button>
      </div>
    </div>
  );
};
