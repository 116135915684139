import { useReactFlow } from '@xyflow/react';
import { useCallback } from 'react';

import { useTouchedState$ } from '../../buttons/use-observable-touched-state.hook';

export const useResetLinkContextMenu = () => {
  const reactFlow = useReactFlow();
  const [, setTouched] = useTouchedState$(false);

  const resetLink = useCallback(
    async (id: string) => {
      const edge = reactFlow.getEdge(id);
      if (edge) {
        reactFlow.updateEdgeData(id, { ...edge.data, points: undefined });
        setTouched(true);
      }
    },
    [reactFlow, setTouched]
  );

  return {
    resetLink,
  };
};
