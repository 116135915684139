import { TEquipmentDetailsRow } from '@marlin/asset/shared/equipment-config';
import { dateFormatterValueCallback } from '@marlin/shared/utils-common-date';

import { content } from '../../content';

export const detailsConfig: TEquipmentDetailsRow[] = [
  { name: 'serialNumber', label: content.SERIAL_NUMBER, valueSource: 'equipment' },
  { name: 'lastReadingTime', label: content.LAST_HEARTBEAT, valueSource: 'datapoints' },
  { name: 'automationCount', label: content.AUTOMATIONS, valueSource: 'equipment' },
  { name: 'id', label: content.EQUIPMENT_ID, valueSource: 'equipment' },
  {
    name: 'gatewayName',
    label: content.GATEWAY_NAME,
    valueSource: 'additionalProperties',
  },
  {
    name: 'gatewayId',
    label: content.GATEWAY_ID,
    valueSource: 'additionalProperties',
  },
  {
    name: 'connectionAddressNumber',
    label: content.MODBUS_ADDRESS,
    valueSource: 'additionalProperties',
  },
  {
    name: 'registrationStatusChangeDate',
    label: content.REGISTRATION_DATE,
    valueSource: 'equipment',
    valueCallback: dateFormatterValueCallback,
  },
  {
    name: 'commissionDate',
    label: content.COMMISSION_DATE,
    valueSource: 'equipment',
    valueCallback: dateFormatterValueCallback,
  },
  { name: 'description', label: content.DESCRIPTION, valueSource: 'equipment' },
];
