import { INode } from './types';
import { hasWidthAndHeight } from './utils';

export const areNodesOverlapping = <T extends INode>(node1: T, node2: T): boolean => {
  if (hasWidthAndHeight(node1) && hasWidthAndHeight(node2)) {
    return (
      node1.position.x < node2.position.x + node2.measured.width &&
      node1.position.x + node1.measured.width > node2.position.x &&
      node1.position.y < node2.position.y + node2.measured.height &&
      node1.position.y + node1.measured.height > node2.position.y
    );
  }

  return false;
};
