import type { IConfig } from '@marlin/asset/shared/equipment-config';
import { EQUIPMENT_TAB_VALUES } from '@marlin/shared/utils-routes';

import {
  aercoBoilerConfig as aercoSharedBoilerConfig,
  aercoWaterHeaterConfig as aercoSharedWaterHeaterConfig,
} from '../../../../shared/config/aerco/config';
import { HistoricalChart } from '../../../components/chart/historical-chart';
import { EventLog } from '../../components/event-log/event-log.component';

const tabs = {
  [EQUIPMENT_TAB_VALUES.EVENT_LOG]: <EventLog />,
  [EQUIPMENT_TAB_VALUES.HISTORICAL_CHART]: <HistoricalChart />,
};

export const aercoBoilerConfig: IConfig = {
  ...aercoSharedBoilerConfig,
  tabs,
};

export const aercoWaterHeaterConfig: IConfig = {
  ...aercoSharedWaterHeaterConfig,
  tabs,
};
