import { useCreateRegisteredGateway } from '@marlin/asset/data-access/gateway';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import {
  GATEWAY_REGISTRATION_ERROR,
  TUpsertGatewayForm,
  getRegistrationErrorCode,
} from '@marlin/shared/utils/gateway-utils';
import { useCallback, useState } from 'react';

import { content } from '../content';

type TProps = {
  onSuccess: () => void;
};

export const useGatewayHook = ({ onSuccess: onSubmitSuccess }: TProps) => {
  const [registrationErrorCode, setRegistrationErrorCode] = useState<GATEWAY_REGISTRATION_ERROR | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const onSuccess = () => {
    enqueueSnackbar(content.SUCCESS_MESSAGE, {
      variant: 'success',
      preventDuplicate: true,
    });
  };

  const createRegisteredEquipmentMutation = useCreateRegisteredGateway({ onSuccess });

  const onSubmit = useCallback(
    async (data: TUpsertGatewayForm) => {
      try {
        if (registrationErrorCode) {
          setRegistrationErrorCode(null);
        }
        await createRegisteredEquipmentMutation.mutateAsync({ data });
        onSubmitSuccess();
      } catch (error) {
        const registrationErrorCode = getRegistrationErrorCode(error);

        if (registrationErrorCode) {
          setRegistrationErrorCode(registrationErrorCode);
        } else {
          enqueueSnackbar(content.CREATE_ERROR, {
            variant: 'error',
            preventDuplicate: true,
          });
        }
      }
    },
    [createRegisteredEquipmentMutation, enqueueSnackbar, onSubmitSuccess, registrationErrorCode]
  );

  return { onSubmit, registrationErrorCode, isPendingMutation: createRegisteredEquipmentMutation.isPending };
};
