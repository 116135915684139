import { Skeleton } from '@mui/material';

interface ISectionSkeletonLoaderProps {
  skeletonCount: number;
}

export const SectionSkeletonLoader = ({ skeletonCount }: ISectionSkeletonLoaderProps) => {
  return (
    <>
      {[...new Array(skeletonCount)].map((_, index) => (
        <Skeleton key={index} variant="text" width="100%" />
      ))}
    </>
  );
};
