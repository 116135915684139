import { VendorApiKey } from '@marlin/account-ui-vendor-api-key';
import { WebhookList } from '@marlin/account-ui-webhook-list';
import { MarlinTheme } from '@marlin/shared/theme';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    paddingTop: theme.typography.pxToRem(16),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      padding: theme.typography.pxToRem(16),
    },
  },
}));

export const IntegrationTab = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <Restricted to={PERMISSIONS.VENDOR_WEBHOOKS}>
        <WebhookList />
      </Restricted>
      <Restricted to={PERMISSIONS.VENDOR_API}>
        <VendorApiKey />
      </Restricted>
    </div>
  );
};
