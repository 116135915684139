import { TExtractFnReturnType, useInfiniteQuery, useMutation } from '@marlin/shared/utils/react-query';
import { AxiosError } from 'axios';

import { getCurrentLocationAssets, getLocationAssets } from '../infrastructure/assets';
import { TAssetLocationParamsSchema, TAssetResponseSchema } from '../schemas/assets/assets.schema';
import { queryKey } from './query-key.enum';

export const useGetAssetsLocation = () => {
  return useMutation<unknown, AxiosError, TAssetLocationParamsSchema, TAssetResponseSchema>({
    mutationFn: getLocationAssets,
  });
};

type TUseInfiniteAssetLocationProps = {
  params: Omit<TAssetLocationParamsSchema, 'page'>;
  currentLocationId?: string;
  enabled?: boolean;
};

type TQueryFnType = typeof getLocationAssets;

export const useFilteredAssetsLocationWithLoadMore = ({
  params,
  enabled = true,
  currentLocationId,
}: TUseInfiniteAssetLocationProps) => {
  return useInfiniteQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.LOCATION_ASSETS(currentLocationId, params),
    initialPageParam: 1,
    queryFn: ({ pageParam }) =>
      currentLocationId
        ? getCurrentLocationAssets(currentLocationId, { ...params, page: pageParam as number })
        : getLocationAssets({ ...params, page: pageParam as number }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.page * lastPage.pagination.pageSize < lastPage.pagination.totalItems
        ? lastPage.pagination.page + 1
        : undefined;
    },
    enabled,
  });
};
