import { TCalibrationParamsSchema, TSubtypesType } from '@marlin/asset/data-access/device';
import { FormField, Input, ToggleButtons } from '@marlin/shared/ui-form-common';
import { symbolPerPulse } from '@marlin/shared/utils-format-reading';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { UseFormReturn, useFormContext } from 'react-hook-form';

import { content } from '../../content';
import { subtitle } from '../types';
import { useStyles } from './forms.styles';
import { TUomType, uomGasToggleOptions, uomWaterToggleOptions } from './uom-toggle-options';

interface IMeterReadingsFormProps {
  form: UseFormReturn<TCalibrationParamsSchema>;
  meterType: TSubtypesType;
}

export const ManualForm = ({ form, meterType }: IMeterReadingsFormProps) => {
  const { classes } = useStyles();
  const { watch } = useFormContext<TCalibrationParamsSchema>();
  const uomOption = watch('measurementUnit') as TUomType;
  const uomSubtitle = useMemo(() => subtitle(uomOption), [uomOption]);
  const endAdornment = useMemo(
    () => <span className={classes.endAdornment}>{symbolPerPulse(uomOption)}</span>,
    [classes.endAdornment, uomOption]
  );

  return (
    <Box className={classes.container}>
      <div className={classes.inputSection}>
        <Typography className={classes.text}>{content.UNITS_OF_MEASUREMENT_LABEL}</Typography>
        <div>
          <FormField<TCalibrationParamsSchema> control={form.control} fieldName="measurementUnit">
            {(props) => (
              <ToggleButtons<TUomType>
                options={meterType === 'Water' ? uomWaterToggleOptions : uomGasToggleOptions}
                value={props.value as TUomType}
                onChange={(_, value) => {
                  if (value !== null) {
                    props.onChange && props.onChange(_, value);
                  }
                }}
              />
            )}
          </FormField>
          <Typography className={classes.subTitleText}>{uomSubtitle}</Typography>
        </div>
      </div>
      <div className={classes.inputSection}>
        {content.CALIBRATION_MODAL.MULTIPLIER_SECTION}
        <div className={classes.inputRow}>
          <FormField<TCalibrationParamsSchema> control={form.control} fieldName="unitsPerPulse">
            {(props) => (
              <Input
                className={classes.input}
                label={content.CALIBRATION_MODAL.METER_MULTIPLIER_LABEL}
                placeholder={content.CALIBRATION_MODAL.METER_MULTIPLIER_LABEL}
                required
                {...props}
                externalEndAdornment={{ endAdornment }}
              />
            )}
          </FormField>
        </div>
      </div>
    </Box>
  );
};
