import { TExtractFnReturnType, TQueryConfig, useQuery } from '@marlin/shared/utils/react-query';

import { IFilterParams } from '../automation.model';
import { getAutomations } from '../infrastructure/automation';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getAutomations;

type TUseAutomationsOptions = {
  config?: TQueryConfig<TQueryFnType>;
  params?: IFilterParams;
  enabled?: boolean;
};

export const useAutomations = ({ config, params, enabled }: TUseAutomationsOptions = {}) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>, Error>({
    queryKey: queryKey.AUTOMATIONS(params),
    queryFn: () => getAutomations(params),
    enabled,
    staleTime: 2000,
    ...config,
  });
};
