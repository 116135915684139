const invitationDetails = '/api/invitation/{invitationId}';
const invitation = `${invitationDetails}/validate`;
const invitations = '/api/invitations';
const supportInvitation = '/api/invitation/{invitationId}/support/validate';
const invitationStatus = '/api/invitation/status/{invitationStatus}';

export const paths = {
  INVITATION: invitation,
  INVITATION_DETAILS: invitationDetails,
  INVITATIONS: invitations,
  SUPPORT_INVITATION: supportInvitation,
  INVITATION_STATUS: invitationStatus,
};
