import { useRegenerateVendorApiKey } from '@marlin/account-data-access-webhook';
import { VendorApiKey as VendorApiKeyInput } from '@marlin/account/shared-api-key-utils';
import { environment } from '@marlin/environment';
import { MarlinTheme } from '@marlin/shared/theme';
import { ActionCustom, ContextMenu } from '@marlin/shared/ui-context-menu';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { Paper, SectionHeader } from '@marlin/shared/ui-page';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import KeyIcon from '@mui/icons-material/Key';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button } from '@mui/material';
import { useCallback, useContext } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { ModalBody } from './generate-modal/modal-body';
import { ModalFooter } from './generate-modal/modal-footer';
import { useVendorApiKey } from './use-vendor-api-key.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    maxWidth: theme.typography.pxToRem(746),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),
  },
  menu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

export const VendorApiKey = () => {
  const { classes } = useStyles();
  const { showButton, apiKey, generateNewApiKey, isLoading } = useVendorApiKey();
  const { modalDispatch } = useContext(ModalContext);
  const { mutateAsync: regenerateVendorApiKey, isPending: isRegenerating } = useRegenerateVendorApiKey();
  const { enqueueSnackbar } = useSnackbar();

  const handleGenerateNewKey = useCallback(async () => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
    try {
      await regenerateVendorApiKey({ vendorSubscriptionId: apiKey?.id || '' });
      enqueueSnackbar(content.GENERATE_SUCCESS, 'success');
    } catch (error) {
      enqueueSnackbar(content.GENERATE_ERROR, 'error');
    }
  }, [enqueueSnackbar, regenerateVendorApiKey, apiKey, modalDispatch]);

  const handleCancelModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch]);

  const handleDispatchGenerateModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: {
        title: <>{content.GENERATE_NEW_API_KEY_MODAL.MODAL_TITLE}</>,
        body: <ModalBody />,
        footer: <ModalFooter onCancel={handleCancelModal} onGenerateNewKey={handleGenerateNewKey} />,
      },
    });
  }, [modalDispatch, handleGenerateNewKey, handleCancelModal]);

  const openApiDeveloperPortal = () => {
    window.open(environment.module.apiDeveloperPortalUrl, '_blank');
  };

  return (
    <Paper testId="vendor-api-key" className={classes.wrapper}>
      <SectionHeader
        testId="vendor-api-key"
        title={content.API_TITLE}
        options={
          showButton && !isLoading ? (
            <Button variant="contained" onClick={generateNewApiKey} data-testid="generate-new-key">
              {content.GENERATE_NEW_KEY_BUTTON}
            </Button>
          ) : (
            <div className={classes.menu}>
              <ContextMenu data-testid="vendor-api-key-card-menu">
                <ActionCustom
                  icon={<KeyIcon />}
                  optionText={content.GENERATE_NEW_KEY_BUTTON}
                  onClick={handleDispatchGenerateModal}
                />
                {!!environment.module.apiDeveloperPortalUrl && (
                  <ActionCustom
                    icon={<OpenInNewIcon />}
                    optionText={content.API_DEVELOPER_PORTAL}
                    onClick={openApiDeveloperPortal}
                  />
                )}
              </ContextMenu>
            </div>
          )
        }
      />
      {isLoading && <LoadingSpinner />}
      {apiKey?.apikey && (isRegenerating ? <LoadingSpinner /> : <VendorApiKeyInput keyValue={apiKey.apikey} />)}
    </Paper>
  );
};
