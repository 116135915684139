import { TDatapointWithMetadata } from '@marlin/shared/utils/datapoint-mappers';
import { TGatewayInfoUpdateResponsePayload, TLastReadingValue } from '@marlin/shared/utils/zod';

import { content } from './content';
import { TGatewayDetailsRow } from './model';

export const mapGatewayDataSource = (
  row: TGatewayDetailsRow & { valueSource: 'gateway' },
  gateway: TGatewayInfoUpdateResponsePayload
) => {
  const value = row.valueCallback ? row.valueCallback(gateway?.[row.name]) : gateway?.[row.name];
  if (!value || typeof value === 'number' || typeof value === 'string') {
    return getRowValue(row.label, String(value ?? ''), row.column);
  }

  return undefined;
};

export const mapGatewayDatapointsDataSource = (
  row: TGatewayDetailsRow & { valueSource: 'datapoints' },
  datapoints: TDatapointWithMetadata[] | TLastReadingValue[],
  getDatapoint: (name: string, options?: { displayOutdatedValues: boolean }) => TDatapointWithMetadata | undefined
) => {
  if (!Array.isArray(row.name)) {
    const datapoint = getDatapoint(row.name, { displayOutdatedValues: true });
    const value = row.valueCallback ? row.valueCallback(datapoint?.value) : datapoint?.value;

    return getRowValue(row.label, value, row.column);
  }

  return mapDatapointsMultipleNames(row, datapoints);
};

const getRowValue = (label: string, value: string | undefined, column?: number) => ({
  label,
  value: value ? value : content.EMPTY_DATAPOINT_VALUE,
  column: column,
});

const mapDatapointsMultipleNames = (
  row: TGatewayDetailsRow,
  datapoints: TDatapointWithMetadata[] | TLastReadingValue[]
) => {
  if (Array.isArray(row.name)) {
    const callbackValues = row.name.map(
      (name) => datapoints?.find((datapoint) => datapoint.name.toLowerCase() === name.toLowerCase())?.value
    );
    const value = row.valueCallback?.(callbackValues);

    return getRowValue(row.label, value, row.column);
  }

  return undefined;
};
