import { IConfig } from '@marlin/asset/shared/equipment-config';
import { EQUIPMENT_TAB_VALUES } from '@marlin/shared/utils-routes';

import { partitionConfig as sharedPartitionConfig } from '../../../../shared/config/partition/config';
import { BradleyPartitionSettings } from '../../../components/settings/bradley-partition/settings.component';

export const partitionConfig: IConfig = {
  ...sharedPartitionConfig,
  tabs: {
    [EQUIPMENT_TAB_VALUES.SETTINGS]: <BradleyPartitionSettings />,
  },
  headerBanner: null,
};
