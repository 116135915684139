import { queryClient, useMutation } from '@marlin/shared/utils/react-query';
import { AxiosError } from 'axios';

import { changeWebhookStatus } from '../infrastructure/change-webhook-status';
import { TWebhookStatusParams } from '../schemas/change-webhook-status.schema';
import {
  TGetFilteredWebhooksResponse,
  defaultGetFilteredWebhooksParams,
} from '../schemas/get-filtered-webhooks.schema';
import { queryKey } from './query-key.enum';

export const useChangeWebhookStatus = () => {
  return useMutation<
    unknown,
    AxiosError,
    TWebhookStatusParams,
    { previousWebhooks: TGetFilteredWebhooksResponse | undefined }
  >({
    mutationFn: async (params: TWebhookStatusParams) => changeWebhookStatus(params),

    onMutate: async ({ action, webhookId }) => {
      await queryClient.cancelQueries({ queryKey: queryKey.FILTERED_WEBHOOKS(defaultGetFilteredWebhooksParams) });
      const previousWebhooks: TGetFilteredWebhooksResponse | undefined =
        queryClient.getQueryData<TGetFilteredWebhooksResponse>(
          queryKey.FILTERED_WEBHOOKS(defaultGetFilteredWebhooksParams)
        );

      const updatedWebhooks = previousWebhooks?.data?.map((webhook) => {
        if (webhook.id === webhookId) {
          if (action === 'enable') {
            return { ...webhook, isEnabled: true };
          }
          if (action === 'disable') {
            return { ...webhook, isEnabled: false };
          }
          if (action === 'unsuspend') {
            return { ...webhook, isEnabled: true, suspended: false };
          }
        }
        return webhook;
      });

      const updatedWebhooksResponse = {
        ...previousWebhooks,
        data: updatedWebhooks,
      };

      queryClient.setQueryData(queryKey.FILTERED_WEBHOOKS(defaultGetFilteredWebhooksParams), updatedWebhooksResponse);
      return { previousWebhooks };
    },

    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey.FILTERED_WEBHOOKS(defaultGetFilteredWebhooksParams), context?.previousWebhooks);
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.WEBHOOK() });
      queryClient.invalidateQueries({ queryKey: queryKey.FILTERED_WEBHOOKS() });
    },
  });
};
