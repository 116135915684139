import { TFilteredOrganizationRequest, TOrganizationListParams } from '../infrastructure/organization.schema';
import { TOrgDetailsParams } from '../schemas/org';
import { ITierFilters } from '../schemas/tier';

export enum QUERY_KEY {
  // This query key need to be same as in alert data-access
  CURRENT_USER = 'currentUser',
  USER = 'user',
  PROFILE = 'profile',
  TIERS = 'tiers',
  ORGANIZATION_SETTINGS = 'organizationSettings',
  ANALYTICS_SETTINGS = 'analyticsSettings',
  ACCOUNT_DELETE = 'accountDelete',
  CREATE_ORGANIZATION = 'organizationCreate',
  ORGANIZATION_DETAILS = 'organizationDetails',
  ORGANIZATION_LIST = 'organization_list',
  ANALYTICS_REPORTS = 'analyticsReports',
  FILTERED_ORGANIZATIONS = 'filteredOrganizations',
  ORGANIZATION_STATUS = 'organizationStatus',
  CURRENT_ORGANIZATION = 'currentOrganization',
  WATER_USAGE = 'water_usage',
  SUPPORT_ORGANIZATIONS = 'supportOrganizations',
}

export const queryKey = {
  CURRENT_USER: () => [QUERY_KEY.CURRENT_USER],
  USER: (userId: string) => [QUERY_KEY.USER, userId],
  PROFILE: () => [QUERY_KEY.PROFILE],
  TIERS: (filters?: ITierFilters) => [QUERY_KEY.TIERS, filters],
  ORGANIZATION_SETTINGS: () => [QUERY_KEY.ORGANIZATION_SETTINGS],
  ORGANIZATION_SETTINGS_BY_ID: (organizationId: string) => [QUERY_KEY.ORGANIZATION_SETTINGS, organizationId],
  ANALYTICS_SETTINGS: () => [QUERY_KEY.ANALYTICS_SETTINGS],
  ACCOUNT_DELETE: () => [QUERY_KEY.ACCOUNT_DELETE],
  CREATE_ORGANIZATION: () => [QUERY_KEY.CREATE_ORGANIZATION],
  ORGANIZATION_DETAILS: (params: TOrgDetailsParams) =>
    params ? [QUERY_KEY.ORGANIZATION_DETAILS, params.organizationId] : [QUERY_KEY.ORGANIZATION_DETAILS],
  ORGANIZATION_STATUS: (organizationId: string) => [QUERY_KEY.ORGANIZATION_STATUS, organizationId],
  ORGANIZATION_LIST: (params?: TOrganizationListParams) => [QUERY_KEY.ORGANIZATION_LIST, params],
  ANALYTICS_REPORTS: () => [QUERY_KEY.ANALYTICS_REPORTS],
  FILTERED_ORGANIZATIONS: (filterParams?: Partial<TFilteredOrganizationRequest>) =>
    filterParams ? [QUERY_KEY.FILTERED_ORGANIZATIONS, filterParams] : [QUERY_KEY.FILTERED_ORGANIZATIONS],
  SUPPORT_ORGANIZATIONS: (filterParams?: Partial<TFilteredOrganizationRequest>) =>
    filterParams ? [QUERY_KEY.SUPPORT_ORGANIZATIONS, filterParams] : [QUERY_KEY.SUPPORT_ORGANIZATIONS],
  CURRENT_ORGANIZATION: () => [QUERY_KEY.CURRENT_ORGANIZATION],
  WATER_USAGE: () => [QUERY_KEY.WATER_USAGE],
};
