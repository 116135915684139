import { CREATE_RECIPIENT_ERROR_CODE, useCreateRecipient, useRecipients } from '@marlin/account-data-access-recipient';
import { ICreateRecipient } from '@marlin/account/ui/recipient-create';
import {
  CHANNEL_TYPE,
  CRITICALITY,
  IChannels,
  TCreateAutomation,
  TDeviceType,
  useCreateAutomation,
} from '@marlin/alert/data-access/automated-action';
import { useDevice } from '@marlin/asset/data-access/device';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { isDataLoading } from '@marlin/shared/utils/react-query';
// TODO: move useAssets to assets domain
// eslint-disable-next-line @nx/enforce-module-boundaries,ordered-imports/ordered-imports
import { ASSET_TYPE, useAssets } from '@marlin/system-map/data-access/system-map';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo } from 'react';

import { content } from './content';

const defaultChannels: IChannels = {
  [CHANNEL_TYPE.IN_APP]: {
    type: CHANNEL_TYPE.IN_APP,
  },
};

const defaultValues: TCreateAutomation = {
  name: '',
  criticality: CRITICALITY.LOW,
  device: {
    id: '',
    name: '',
    manufacturerId: '',
  },
  manufacturerId: '',
  channels: defaultChannels,
  snoozeIntervalInMinutes: 0,
  conditions: {},
  duration: {
    value: null,
    unit: null,
  },
};

export type TCreateRecipientError = AxiosError<{ errorCode: CREATE_RECIPIENT_ERROR_CODE }>;

export const useStore = (closeRecipientModal: () => void, initialDeviceId?: string | null) => {
  const { enqueueSnackbar } = useSnackbar();
  const locationsQuery = useAssets(
    {
      filter: ASSET_TYPE.LOCATION,
    },
    true
  );
  const createAutomationMutation = useCreateAutomation();

  const recipientsQuery = useRecipients();
  const createRecipientMutation = useCreateRecipient();
  const { data: device, isPending: isDeviceLoading } = useDevice({
    deviceId: initialDeviceId || '',
    enabled: !!initialDeviceId,
  });

  const isLoading = useMemo(
    () =>
      isDataLoading([locationsQuery]) || createAutomationMutation.isPending || (!!initialDeviceId && isDeviceLoading),
    [locationsQuery, createAutomationMutation.isPending, initialDeviceId, isDeviceLoading]
  );

  const isSuccess = useMemo(() => createAutomationMutation.isSuccess, [createAutomationMutation.isSuccess]);

  const saveRecipient = useCallback(
    (data: ICreateRecipient) =>
      createRecipientMutation
        .mutateAsync(data)
        .then(() => {
          enqueueSnackbar(content.CONFIRMATION_MESSAGE_CREATE_RECIPIENT_SUCCESS, {
            variant: 'success',
            preventDuplicate: true,
          });
          closeRecipientModal();
        })
        .catch((error) => {
          if (!error.response?.data?.errorCode) {
            enqueueSnackbar(content.CONFIRMATION_MESSAGE_CREATE_RECIPIENT_ERROR, {
              variant: 'error',
              preventDuplicate: true,
            });
          }
        }),

    [closeRecipientModal, createRecipientMutation, enqueueSnackbar]
  );

  const save = useCallback(
    (data: TCreateAutomation) => {
      return createAutomationMutation.mutateAsync(data);
    },
    [createAutomationMutation]
  );

  useEffect(() => {
    if (createAutomationMutation.isError) {
      enqueueSnackbar(content.CONFIRMATION_MESSAGE_CREATE_AUTOMATION_ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    }

    if (createAutomationMutation.isSuccess) {
      enqueueSnackbar(content.CONFIRMATION_MESSAGE_CREATE_AUTOMATION_SUCCESS, {
        variant: 'success',
        preventDuplicate: true,
      });
    }
  }, [createAutomationMutation.isError, createAutomationMutation.isSuccess, enqueueSnackbar]);

  const saveRecipientErrorCode = useMemo(() => {
    const error = createRecipientMutation.error as TCreateRecipientError;
    return error?.response?.data?.errorCode;
  }, [createRecipientMutation.error]);

  const closeModal = useCallback(() => {
    closeRecipientModal();
    createRecipientMutation.reset();
  }, [closeRecipientModal, createRecipientMutation]);

  const initialDefaultValues = useMemo(() => {
    if (!device) {
      return defaultValues;
    }

    return {
      ...defaultValues,
      device: {
        id: device.id,
        manufacturerId: device.manufacturerId,
        name: device.name,
        deviceType: device.deviceType as TDeviceType,
        locationId: device.locationId,
      },
    };
  }, [device]);

  return {
    locations: locationsQuery?.data ?? [],
    recipients: recipientsQuery?.data ?? [],
    saveRecipient,
    saveRecipientErrorCode,
    defaultValues: initialDefaultValues,
    isLoading,
    isSuccess,
    save,
    closeModal,
  };
};
