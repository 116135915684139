import { TEquipmentDetailsRow } from '@marlin/asset/shared/equipment-config';
import { dateFormatterValueCallback } from '@marlin/shared/utils-common-date';
import { modelContent } from '@marlin/shared/utils/datapoint-mappers';
import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';

import { content } from '../../content';

export const equipmentTypeLabelsMap: Map<EQUIPMENT_TYPE, string> = new Map([
  [EQUIPMENT_TYPE.NONE, content.EQUIPMENT_TYPES_OPTIONS.NONE],
  [EQUIPMENT_TYPE.DIGITAL_MIXING_VALVE, content.EQUIPMENT_TYPES_OPTIONS.DIGITAL_MIXING_VALVE],
  [EQUIPMENT_TYPE.LEAK_DEFENSE_SYSTEM, content.EQUIPMENT_TYPES_OPTIONS.LEAK_DEFENSE_SYSTEM],
]);

const equipmentTypeValueCallback = (value: unknown) => {
  if (typeof value === 'string' && Object.values<string>(EQUIPMENT_TYPE).includes(value)) {
    return equipmentTypeLabelsMap.get(value as EQUIPMENT_TYPE);
  }

  return EQUIPMENT_TYPE.NONE;
};

const modelCallback = (value: unknown) => {
  const model = Array.isArray(value) ? value[0] : undefined;
  const modelVariant = Array.isArray(value) ? value[1] : undefined;

  return [modelContent.MODELS.get(model) ?? model, modelVariant].filter(Boolean).join(' ');
};

export const detailsConfig: TEquipmentDetailsRow[] = [
  { name: 'name', label: content.EQUIPMENT_NAME, valueSource: 'equipment' },
  {
    name: 'type',
    label: content.EQUIPMENT_TYPE,
    valueSource: 'equipment',
    valueCallback: equipmentTypeValueCallback,
  },
  {
    name: 'WifiRssi',
    label: content.SIGNAL_STRENGTH,
    valueSource: 'datapoints',
  },
  { name: 'brand', label: content.BRAND, valueSource: 'equipment' },
  {
    name: 'manufacturerId',
    label: content.EQUIPMENT_ID,
    valueSource: 'device',
    tooltip: content.EQUIPMENT_ID_TOOLTIP,
  },
  { name: ['model', 'modelVariant'], label: content.MODEL, valueSource: 'mix', valueCallback: modelCallback },
  {
    name: 'registrationStatusChangeDate',
    label: content.REGISTRATION_DATE,
    valueSource: 'equipment',
    valueCallback: dateFormatterValueCallback,
  },
  { name: 'description', label: content.DESCRIPTION, valueSource: 'equipment' },
];
