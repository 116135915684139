import { MarlinTheme } from '@marlin/shared/theme';
import { customFormat, formatTimestamp } from '@marlin/shared/utils-common-date';
import { Icon, Typography, alpha } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../shared/content';

interface IStyles {
  isOnlineDot?: boolean;
  fullWidth?: boolean;
}

const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { isOnlineDot, fullWidth }) => ({
  wrapper: {
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.alternative,
    flexWrap: 'wrap',
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderRadius: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(4)} 0 0`,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      gap: theme.typography.pxToRem(8),
      padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
    },
  },
  dot: {
    height: theme.typography.pxToRem(18),
    minWidth: theme.typography.pxToRem(18),
    borderRadius: '50%',
    display: 'inline-block',
    backgroundColor: isOnlineDot ? theme.palette.success.light : theme.palette.error.main,
    marginRight: theme.typography.pxToRem(14),
  },
  statusWrapper: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  icon: {
    color: isOnlineDot ? theme.palette.action.active : theme.palette.error.main,
    marginRight: theme.typography.pxToRem(8),
  },
  mode: {
    display: 'flex',
    marginRight: theme.typography.pxToRem(8),
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      marginRight: 0,
      padding: 0,
    },
  },
  status: {
    marginRight: theme.typography.pxToRem(8),
    whiteSpace: 'nowrap',
  },
  time: {
    whiteSpace: 'nowrap',
  },
  equipmentModeLabel: {
    color: isOnlineDot ? 'inherit' : theme.palette.error.main,
  },
  modeIcon: {
    color: alpha(theme.palette.text.primary, 0.54),
    marginRight: theme.typography.pxToRem(8),
  },
}));

interface IStatusProps {
  fullWidth?: boolean;
  isOnline?: boolean;
  time: string;
  mode?: 'Active' | 'Standby' | null;
  displayMode?: boolean;
}

export const StatusBar = ({ fullWidth, time, mode, isOnline, displayMode }: IStatusProps) => {
  const { classes } = useStyles({
    fullWidth,
    isOnlineDot: isOnline,
  });

  const displayTime = useMemo(() => formatTimestamp(time, customFormat), [time]);

  return (
    <div className={classes.wrapper} data-testid="header-equpment-operational">
      <div className={classes.mode}>{displayMode && <ModeIcon mode={mode} />}</div>
      <div className={classes.statusWrapper}>
        <div className={classes.dot} data-testid="equipment-status-dot"></div>
        <Typography variant="body1" className={classes.status} data-testid="equipment-status">
          {isOnline ? content.ONLINE : content.OFFLINE}
        </Typography>
        <Typography variant="body1" data-testid="equipment-time" className={classes.time}>
          {displayTime}
        </Typography>
      </div>
    </div>
  );
};

const ModeIcon = ({ mode }: { mode?: 'Active' | 'Standby' | null }) => {
  const { classes } = useStyles({
    isOnlineDot: mode === 'Active',
  });

  switch (mode) {
    case 'Active':
      return (
        <>
          <Icon baseClassName="material-symbols-outlined" className={classes.modeIcon} data-testid="check-circle">
            check_circle
          </Icon>
          {content.MODE.ACTIVE}
        </>
      );
    case 'Standby':
      return (
        <>
          <Icon baseClassName="material-symbols-outlined" className={classes.modeIcon} data-testid="check-circle">
            pending
          </Icon>
          {content.MODE.STANDBY}
        </>
      );
    default:
      return (
        <>
          <Icon baseClassName="material-symbols-outlined" className={classes.modeIcon} data-testid="check-circle">
            pending
          </Icon>
          {content.MODE.INACTIVE}
        </>
      );
  }
};
