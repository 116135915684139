export enum QUERY_KEY {
  VALIDATE_INVITATION = 'invitation-validate',
  VALIDATE_SUPPORT_INVITATION = 'invitation-support-validate',
  USER = 'user',
}

export const queryKey = {
  VALIDATE_INVITATION: (id: string) => [QUERY_KEY.VALIDATE_INVITATION, id],
  VALIDATE_SUPPORT_INVITATION: (id: string) => [QUERY_KEY.VALIDATE_SUPPORT_INVITATION, id],
};
