import { useQuery } from '@marlin/shared/utils/react-query';

import { getTiers } from '../infrastructure/get-tiers';
import { ITierFilters } from '../schemas/tier';
import { queryKey } from './query-key.enum';

export const useGetTiers = (filters: ITierFilters) => {
  return useQuery({
    queryKey: queryKey.TIERS(filters),
    queryFn: () => getTiers(filters),
  });
};
