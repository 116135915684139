import { Icon as MuiIcon } from '@mui/material';

import { TIconProps } from '../types';

export const Icon = ({ name, ...props }: { name: string } & Omit<TIconProps, 'color'>) => {
  return (
    <MuiIcon baseClassName="material-symbols-outlined" {...props}>
      {name}
    </MuiIcon>
  );
};
