import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';

import { DEVICE_TYPE, IDevice } from '../../../../types';
import { FlowMeterConditions } from '../condition-type/flow-meter-conditions.component';
import { LeakConditions } from '../condition-type/leak-conditions.component';
import { PressureConditions } from '../condition-type/pressure-conditions.component';
import { TemperatureConditions } from '../condition-type/temperature-conditions.component';

interface IConditionSwitcherProps {
  device?: IDevice | undefined;
  uom?: TUnitOfMeasure | null;
}

const getUoM = (device?: IDevice) => {
  if (device?.lastReadingValues) {
    const defaultDatapoint = device?.lastReadingValues?.find((reading) => reading.isDefault);
    if (!defaultDatapoint) {
      return undefined;
    }
    return defaultDatapoint.name === 'Leak' ? 'WaterDetect' : defaultDatapoint.unitOfMeasure ?? undefined;
  }
  return undefined;
};

export function ConditionSwitcher({ device, uom }: IConditionSwitcherProps): JSX.Element | null {
  if (!device) {
    return null;
  }

  const unitOfMeasure = uom ?? getUoM(device);

  switch (device.deviceType) {
    case DEVICE_TYPE.TEMPERATURE: {
      return <TemperatureConditions unitOfMeasure={unitOfMeasure} />;
    }
    case DEVICE_TYPE.PRESSURE: {
      return <PressureConditions unitOfMeasure={unitOfMeasure} />;
    }
    case DEVICE_TYPE.LEAK: {
      return <LeakConditions />;
    }
    case DEVICE_TYPE.PULSE_METER: {
      return <FlowMeterConditions unitOfMeasure={unitOfMeasure} />;
    }
    default: {
      return null;
    }
  }
}
