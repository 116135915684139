import { TLastReadingValue } from '@marlin/shared/utils/zod';

export enum DEVICE_TYPE {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  LEAK = 'LEAK',
  PULSE_METER = 'PULSE_METER',
  UNKNOWN = 'UNKNOWN',
  EQUIPMENT = 'EQUIPMENT',
}

export interface IDevice {
  id: string;
  name: string;
  locationId?: string;
  equipmentId?: string;
  deviceType: DEVICE_TYPE;
  manufacturerId: string;
  lastReadingValues?: TLastReadingValue[];
}

export type TRecipientType = 'Recipient' | 'User';

export interface IEquipment {
  id: string;
  name: string;
  locationId?: string;
}

export interface ILocation {
  id: string;
  name: string;
  parentLocationId?: string;
}

export interface IRecipient {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  recipientType: TRecipientType;
}

export interface IAutomationDevice {
  device: {
    type: DEVICE_TYPE;
  };
}
