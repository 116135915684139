import { IconEquipmentMode } from '@marlin/asset/shared/ui/assets';

import { content } from './content';
import type { TStatusMap } from './types';

export const statusIdToKeyAdapter = (id: string): string | undefined =>
  ({
    HOME: 'Home',
    AWAY: 'Away',
    STANDBY: 'Standby',
  }[id]);

export const sentinelEquipmentModeLabelMap: TStatusMap = new Map([
  [
    'Home',
    {
      mode: content.EQUIPMENT_MODE_LABELS_SENTINEL.HOME,
      Icon: IconEquipmentMode.SentinelInHome,
    },
  ],
  ['Away', { mode: content.EQUIPMENT_MODE_LABELS_SENTINEL.AWAY, Icon: IconEquipmentMode.Remote }],
  ['Standby', { mode: content.EQUIPMENT_MODE_LABELS_SENTINEL.STANDBY, Icon: IconEquipmentMode.Standby }],
]);
