import { TSettings, useUpdateSettings } from '@marlin/account-data-access-organization';
import { MarlinTheme } from '@marlin/shared/theme';
import { UomControl } from '@marlin/shared/ui-form';
import { Paper } from '@marlin/shared/ui-page';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import EnergyIcon from '@mui/icons-material/Bolt';
import TemperatureIcon from '@mui/icons-material/DeviceThermostat';
import GasIcon from '@mui/icons-material/GasMeter';
import WaterIcon from '@mui/icons-material/PanoramaPhotosphere';
import PressureIcon from '@mui/icons-material/Speed';
import { Divider, Typography } from '@mui/material';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { defaultUsersSettingsValues } from '../consts';
import { content } from '../content';
import { IUnitsOfMeasurementForm } from '../types';
import { energyOptions, gasOptions, pressureOptions, temperatureOptions, waterOptions } from './options';
import { TEnergy, TGas, TPressure, TTemperature, TWater } from './schema';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
    maxWidth: theme.typography.pxToRem(724),
    padding: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginLeft: theme.typography.pxToRem(16),
      marginRight: theme.typography.pxToRem(16),
    },
  },
  uomTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    marginBottom: theme.typography.pxToRem(24),
  },
  uomContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(8),
  },
}));

interface IUomSettingsProps {
  userSettings?: TSettings;
}

export const UomSettings = ({ userSettings }: IUomSettingsProps) => {
  const { classes } = useStyles();
  const update = useUpdateSettings();
  const { enqueueSnackbar } = useSnackbar();

  const defaultFormValues: IUnitsOfMeasurementForm = useMemo(
    () => ({
      defaultHomePage: userSettings?.defaultHomePage || defaultUsersSettingsValues.defaultHomePage,
      energyUnit: userSettings?.energyUnit || defaultUsersSettingsValues.energyUnit,
      pressureUnit: userSettings?.pressureUnit || defaultUsersSettingsValues.pressureUnit,
      temperatureUnit: userSettings?.temperatureUnit || defaultUsersSettingsValues.temperatureUnit,
      timeFormat: userSettings?.timeFormat || defaultUsersSettingsValues.timeFormat,
      gasUnit: userSettings?.gasUnit || defaultUsersSettingsValues.gasUnit,
      gasFlowRateUnit: userSettings?.gasFlowRateUnit || defaultUsersSettingsValues.gasFlowRateUnit,
      waterUnit: userSettings?.waterUnit || defaultUsersSettingsValues.waterUnit,
      waterFlowRateUnit: userSettings?.waterFlowRateUnit || defaultUsersSettingsValues.waterFlowRateUnit,
    }),
    [userSettings]
  );

  const form = useForm<IUnitsOfMeasurementForm>({
    defaultValues: defaultFormValues,
  });

  const onSubmit = useCallback(
    (data: IUnitsOfMeasurementForm) => {
      update
        .mutateAsync(data)
        .then(() => {
          enqueueSnackbar(content.DEFAULT_UOM_SUCCESS, {
            variant: 'success',
            preventDuplicate: true,
          });
        })
        .catch(() => {
          enqueueSnackbar(content.SOMETHING_WENT_WRONG, {
            variant: 'error',
            preventDuplicate: true,
          });
        });
    },
    [enqueueSnackbar, update]
  );

  useEffect(() => {
    const debouncedSubmit = debounce(() => form.handleSubmit(onSubmit)(), 100);
    const subscription = form.watch(() => debouncedSubmit());
    return () => subscription.unsubscribe();
  }, [form, onSubmit]);

  return (
    <Paper className={classes.container} data-testid="units-of-measurement-card">
      <Typography className={classes.uomTitle}>{content.UNITS_OF_MEASUREMENT}</Typography>
      <FormProvider {...form}>
        <div className={classes.uomContainer}>
          <UomControl<TTemperature, IUnitsOfMeasurementForm>
            LabelIcon={TemperatureIcon}
            options={temperatureOptions}
            fieldName="temperatureUnit"
            label={content.TEMPERATURE_CONTROL_LABEL}
          />
          <Divider />
          <UomControl<TPressure, IUnitsOfMeasurementForm>
            LabelIcon={PressureIcon}
            options={pressureOptions}
            fieldName="pressureUnit"
            label={content.PRESSURE_CONTROL_LABEL}
          />
          <Divider />
          <UomControl<TWater, IUnitsOfMeasurementForm>
            LabelIcon={WaterIcon}
            options={waterOptions}
            fieldName="waterUnit"
            label={content.WATER_CONTROL_LABEL}
            flowFieldName="waterFlowRateUnit"
          />
          <Divider />
          <UomControl<TEnergy, IUnitsOfMeasurementForm>
            LabelIcon={EnergyIcon}
            options={energyOptions}
            fieldName="energyUnit"
            label={content.ENERGY_CONTROL_LABEL}
          />
          <Divider />
          <UomControl<TGas, IUnitsOfMeasurementForm>
            LabelIcon={GasIcon}
            options={gasOptions}
            fieldName="gasUnit"
            label={content.GAS_CONTROL_LABEL}
            flowFieldName="gasFlowRateUnit"
          />
        </div>
      </FormProvider>
    </Paper>
  );
};
