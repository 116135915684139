import { useDeleteInvitation } from '@marlin/account-data-access-invitation';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useCallback, useMemo } from 'react';

import { content } from './content';

interface IUseDeleteCurrentInvitation {
  deleteCurrentInvitation: (id: string) => Promise<void>;
}

export const useDeleteCurrentInvitation = (): IUseDeleteCurrentInvitation => {
  const { mutateAsync } = useDeleteInvitation();
  const { enqueueSnackbar } = useSnackbar();

  const deleteCurrentInvitation = useCallback(
    async (id: string) => {
      return mutateAsync(id)
        .then(() => {
          enqueueSnackbar(content.DELETE_INVITATION_SUCCESS, {
            variant: 'success',
            preventDuplicate: true,
          });
        })
        .catch(() => {
          enqueueSnackbar(content.DELETE_INVITATION_ERROR, {
            variant: 'error',
            preventDuplicate: true,
          });
        });
    },
    [enqueueSnackbar, mutateAsync]
  );

  return useMemo(() => ({ deleteCurrentInvitation }), [deleteCurrentInvitation]);
};
