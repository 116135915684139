import { ALERT_STATUS_FILTER, useFilteredAlertsWithPaging } from '@marlin/alert/data-access/alert-action';
import { TEquipmentAlert, useLiveAlerts } from '@marlin/asset/data-access/equipment';
import { TopAlerts } from '@marlin/asset/shared/ui/top-alerts';
import { MarlinTheme } from '@marlin/shared/theme';
import { TTopOpenAlert } from '@marlin/shared/ui/top-open-alerts';
import { DEVICE_TYPE, ERROR_TYPES } from '@marlin/shared/utils/format-alert';
import { TGatewayInfoUpdateResponsePayload } from '@marlin/shared/utils/zod';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  alertsWrapper: {
    flexBasis: '40%',
  },
}));

const maxAlertCount = 5;

export const DashboardAlerts = ({ gateway }: { gateway: TGatewayInfoUpdateResponsePayload }) => {
  const { classes } = useStyles();

  const { data, isLoading } = useFilteredAlertsWithPaging({
    params: {
      equipmentIds: [],
      locationIds: [],
      status: ALERT_STATUS_FILTER.CURRENT,
      deviceIds: [gateway.id ?? ''],
      pageSize: maxAlertCount,
      page: 1,
      deviceTypes: [],
      ruleCategories: [],
      eventCodes: [],
    },
  });

  const { currentAlerts: liveAlerts } = useLiveAlerts();

  const alerts = useMemo((): TEquipmentAlert[] => {
    if (!liveAlerts) {
      return (data?.data || []).map<TEquipmentAlert>((alert) => ({
        deviceEventCode: alert.deviceEventCode || '',
        startTime: alert.startTime || '',
        title: alert.title || '',
        criticality: alert.criticality,
        uoM: alert.uoM,
      }));
    }

    return liveAlerts.slice(0, maxAlertCount);
  }, [data?.data, liveAlerts]);

  const parsedAlerts = useMemo<
    {
      alert: TTopOpenAlert;
      alertErrorType: ERROR_TYPES;
      alertDeviceType: DEVICE_TYPE;
    }[]
  >(() => {
    return alerts.map(({ criticality, startTime, title, uoM }) => ({
      alert: {
        criticality,
        startTime,
        title,
        deviceId: gateway.id ?? '',
        deviceName: gateway.name ?? '',
        deviceType: 'Gateway',
        uoM,
      },
      alertErrorType: ERROR_TYPES.DEVICE_ALERT,
      alertDeviceType: DEVICE_TYPE.EQUIPMENT,
    }));
  }, [alerts, gateway]);

  return <TopAlerts topOpenAlerts={parsedAlerts} classes={classes.alertsWrapper} isLoading={isLoading} />;
};
