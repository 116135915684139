import { content } from '../content';

export const firmwareVersionCallback = (value: unknown) => {
  if (Array.isArray(value)) {
    return value.join('/') ?? content.EMPTY_DATAPOINT_VALUE;
  }

  if (typeof value === 'string') {
    try {
      const configObject = JSON.parse(value);
      return configObject?.Firmware?.Version;
    } catch {
      return undefined;
    }
  }

  return undefined;
};
