import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import Button from '@mui/material/Button';
import { useCallback, useContext } from 'react';

import { content } from '../content';

export const useDeleteChartModal = (onRemoveChart: () => void, title: string) => {
  const { modalDispatch } = useContext(ModalContext);
  const closeModal = useCallback(() => modalDispatch({ type: MODAL_ACTION_TYPE.DISMISS }), [modalDispatch]);
  const { enqueueSnackbar } = useSnackbar();

  const submitDeleteChart = useCallback(() => {
    onRemoveChart();
    enqueueSnackbar(content.MODAL.TOAST_MESSAGE(title), {
      variant: 'success',
      preventDuplicate: true,
    });

    closeModal();
  }, [closeModal, enqueueSnackbar, onRemoveChart, title]);

  const openChartDeleteModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: {
        title: <>{content.MODAL.TITLE}</>,
        body: <div>{content.MODAL.BODY(title)}</div>,
        footer: (
          <div>
            <Button variant="text" data-testid="chart-delete-modal-cancel-button" onClick={closeModal}>
              {content.MODAL.CANCEL}
            </Button>
            <Button
              variant="contained"
              color="error"
              data-testid="chart-delete-modal-submit-button"
              onClick={submitDeleteChart}
            >
              {content.MODAL.DELETE}
            </Button>
          </div>
        ),
      },
    });
  }, [closeModal, modalDispatch, submitDeleteChart, title]);

  return {
    openChartDeleteModal,
  };
};
