import { TGatewayMode, TGatewayStatus } from '@marlin/asset/data-access/gateway';
import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import CircleIcon from '@mui/icons-material/Circle';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  cell: {
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    width: theme.typography.pxToRem(12),
    marginRight: theme.typography.pxToRem(6),
  },

  activeIcon: {
    color: theme.palette.success.light,
  },

  inactiveIcon: {
    color: theme.palette.error.main,
  },

  standbyIcon: {
    color: theme.palette.warning.main,
  },
}));

export interface IStatusCellProps {
  status: TGatewayStatus;
  model?: string;
  mode: TGatewayMode | null | undefined;
}

export const StatusCell = ({ model, status, mode }: IStatusCellProps) => {
  const { classes } = useStyles();

  const getStatusIconClass = (): string => {
    if (status === 'ACTIVE') {
      if (mode === 'Standby') {
        return classes.standbyIcon;
      }
      return classes.activeIcon;
    }

    return classes.inactiveIcon;
  };

  const getStatusLabel = (): string => {
    if (status === 'ACTIVE') {
      if (mode) {
        return mode === 'Standby' ? content.STATUS_CELL_STANDBY : content.STATUS_CELL_ACTIVE;
      }
      return content.STATUS_CELL_ONLINE;
    }

    return content.STATUS_CELL_OFFLINE;
  };

  return (
    <Tooltip text={content.STATUS_HEADER_CELL_TOOLTIP.get(model ?? '') ?? ''} placement="top">
      <div className={classes.cell}>
        <CircleIcon className={`${classes.icon} ${getStatusIconClass()}`} />
        {getStatusLabel()}
      </div>
    </Tooltip>
  );
};
