import { TFlowLink } from '@marlin/asset/data-access/asset';
import { useModal } from '@marlin/shared/ui-modal';
import { Link } from '@marlin/shared/ui-page';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Button, Typography } from '@mui/material';

import { AttachmentValidationModal } from './attachment-validation.modal';
import { useStyles } from './attachment-validation.styles';
import { content } from './content';
import { getAttachmentName, getAttachmentUrl } from './utils';

interface IAttachmentValidationProps {
  variant: 'success' | 'warning' | 'error';
  data?: TFlowLink[];
  assetId?: string;
  deviceAttachment?: TFlowLink | null;
}

export const AttachmentValidation = ({ variant, data, assetId, deviceAttachment }: IAttachmentValidationProps) => {
  const { classes } = useStyles();

  const [attachmentModal, openAttachmentModal, closeAttachmentModal] = useModal();

  if (variant === 'warning') {
    return (
      <div className={classes.container} data-testid="attachment-validation-warning">
        <div className={classes.iconContainer}>
          <div className={classes.warningIcon}>
            <WarningRoundedIcon />
          </div>
          <div>
            <Typography className={classes.attachmentInfo} data-testid="attachment-validation-text">
              {content.FLOW_LINK_FOUND}
            </Typography>
          </div>
        </div>
        <div>
          <Button variant="text" onClick={openAttachmentModal}>
            {content.SHOW_FLOW_LINK_ATTACHMENT}
          </Button>
        </div>
        {attachmentModal && <AttachmentValidationModal assetId={assetId} data={data} onClose={closeAttachmentModal} />}
      </div>
    );
  }

  if (variant === 'error') {
    if (deviceAttachment) {
      return (
        <div className={classes.container} data-testid="attachment-validation-error">
          <div className={classes.iconContainer}>
            <div className={classes.errorIcon}>
              <WarningRoundedIcon />
            </div>
            <div>
              <Typography className={classes.attachmentError} data-testid="attachment-validation-text">
                {content.FLOW_LINK_ATTACHMENTS_ERROR(
                  <Link to={getAttachmentUrl(deviceAttachment, assetId)}>
                    {getAttachmentName(deviceAttachment, assetId)}
                  </Link>
                )}
              </Typography>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div className={classes.successContainer} data-testid="attachment-validation-success">
      <div className={classes.iconContainer}>
        <div className={classes.successIcon}>
          <CheckCircleRoundedIcon />
        </div>
        <div>
          <Typography className={classes.attachmentInfo} data-testid="attachment-validation-text">
            {content.NO_FLOW_LINK_ATTACHMENTS}
          </Typography>
        </div>
      </div>
    </div>
  );
};
