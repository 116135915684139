import { TExtractFnReturnType } from '@marlin/shared/utils/react-query';
import { useIsFetching, useQuery, useQueryClient } from '@marlin/shared/utils/react-query';

import { TRangeTelemetryParams } from '../infrastructure/schema/telemetry.request.schema';
import { getRangeTelemetry } from '../infrastructure/telemetry';
import { QUERY_KEY, queryKey } from './query-key.enum';

type TUseTelemetryOptions = TRangeTelemetryParams & {
  enabled: boolean;
  keepPreviousData?: boolean;
  calculateBuckets?: boolean;
};

export const useRangeTelemetry = ({
  manufacturerId,
  to,
  from,
  enabled,
  keepPreviousData,
  excludeAlerts,
  calculateBuckets,
}: TUseTelemetryOptions) => {
  return useQuery<TExtractFnReturnType<typeof getRangeTelemetry>>({
    queryKey: queryKey.RANGE_TELEMETRY(manufacturerId, to, from, excludeAlerts, calculateBuckets),
    queryFn: () => getRangeTelemetry({ manufacturerId, to, from, excludeAlerts }, calculateBuckets),
    enabled,
    placeholderData: keepPreviousData ? (previousData, previousQuery) => previousData : undefined,
  });
};

export const useBrushChartRangeTelemetry = ({
  manufacturerId,
  to,
  from,
  enabled,
  keepPreviousData,
}: TUseTelemetryOptions) => {
  return useQuery<TExtractFnReturnType<typeof getRangeTelemetry>>({
    queryKey: queryKey.RANGE_TELEMETRY(manufacturerId, to, from, true),
    queryFn: () => getRangeTelemetry({ manufacturerId, to, from, excludeAlerts: true }, false),
    enabled,
    placeholderData: keepPreviousData ? (previousData, previousQuery) => previousData : undefined,
  });
};

export const useIsRangeTelemetryLoading = ({ manufacturerId, to, from }: TRangeTelemetryParams) => {
  return useIsFetching({ queryKey: queryKey.RANGE_TELEMETRY(manufacturerId, to, from) }) > 0;
};

export const useInvalidateRangeTelemetry = (params?: TRangeTelemetryParams) => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries(
      params
        ? { queryKey: [queryKey.RANGE_TELEMETRY(params.manufacturerId, params.to, params.from)] }
        : { queryKey: [QUERY_KEY.RANGE_TELEMETRY] }
    );
};
