import type { IConfig } from '@marlin/asset/shared/equipment-config';
import { EQUIPMENT_TAB_VALUES } from '@marlin/shared/utils-routes';

import {
  pviBrigadeConfig as sharedPviBrigadeConfig,
  pviConquestConfig as sharedPviConquestConfig,
} from '../../../../shared/config/pvi/config';
import { HistoricalChart } from '../../../components/chart/historical-chart';
import { EventLog } from '../../components/event-log/event-log.component';

export const pviBrigadeConfig: IConfig = {
  ...sharedPviBrigadeConfig,
  tabs: {
    [EQUIPMENT_TAB_VALUES.HISTORICAL_CHART]: <HistoricalChart />,
    [EQUIPMENT_TAB_VALUES.EVENT_LOG]: <EventLog />,
  },
};

export const pviConquestConfig: IConfig = {
  ...sharedPviConquestConfig,
  tabs: {
    [EQUIPMENT_TAB_VALUES.HISTORICAL_CHART]: <HistoricalChart />,
    [EQUIPMENT_TAB_VALUES.EVENT_LOG]: <EventLog />,
  },
};
