import { useUpdateGatewaySettings } from '@marlin/asset/data-access/gateway';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useCallback } from 'react';

import { content } from '../content';
import { useGatewayDatapointsContext } from '../context/gateway-datapoints.context';

type TProps = {
  onSuccess?: () => void;
  onError?: () => void;
};
export const useUpdateSettings = ({ onError: onErrorCallback, onSuccess: onSuccessCallback }: TProps = {}) => {
  const { gateway } = useGatewayDatapointsContext();

  const { enqueueSnackbar } = useSnackbar();

  const gatewayId = gateway.id || '';

  const onSuccess = useCallback(() => {
    enqueueSnackbar(content.SAVE_SUCCESS_MSG(gateway?.name ?? ''), { variant: 'success', preventDuplicate: true });
    onSuccessCallback?.();
  }, [enqueueSnackbar, gateway?.name, onSuccessCallback]);

  const onError = useCallback(() => {
    enqueueSnackbar(content.ERROR, {
      variant: 'error',
      preventDuplicate: true,
    });
    onErrorCallback?.();
  }, [enqueueSnackbar, onErrorCallback]);

  const updateSettingsMutation = useUpdateGatewaySettings({ gatewayId, onError, onSuccess });

  const updateSettings = async (name: string, value: string, prevValue: string) => {
    await updateSettingsMutation.mutateAsync({ name, value, prevValue });
  };

  return { updateSettings, isLoading: updateSettingsMutation.isPending };
};
