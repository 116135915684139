import { Edge, XYPosition, useReactFlow } from '@xyflow/react';
import { useCallback } from 'react';

import { useTouchedState$ } from '../../buttons/use-observable-touched-state.hook';
import {
  getMidpoint,
  getSegmentType,
  mapPointsToSegmentIndex,
  mapPointsToSegments,
  mapToControlPoints,
} from '../../edges/path/points.utils';
import { TControlPointData, TEditableEdgeData, TSegmentIndex } from '../../edges/path/types';
import { calculateSnappedValue } from '../../use-snap-to-grid.hook';

export const useDeletePointContextMenu = () => {
  const reactFlow = useReactFlow();
  const [, setTouched] = useTouchedState$(false);

  const getPointContextMenu = useCallback(
    (points: XYPosition[], event: XYPosition) => {
      const segments = mapPointsToSegments(points);
      const midpoints = segments
        ?.map((segment) => getMidpoint(segment))
        .map((midpoint) => ({ x: calculateSnappedValue(midpoint.x), y: calculateSnappedValue(midpoint.y) }));
      const eventFlowPosition = reactFlow.screenToFlowPosition(event);
      const pointIndex = midpoints?.findIndex(
        (point) => point.x === eventFlowPosition.x && point.y === eventFlowPosition.y
      );
      const isPointContextMenu = pointIndex !== -1;
      const segmentIndex = isPointContextMenu ? mapPointsToSegmentIndex(points as XYPosition[])[pointIndex] : [];

      return {
        isPointContextMenu,
        segmentIndex,
      };
    },
    [reactFlow]
  );

  const deletePoint = useCallback(
    async (id: string, segmentIndex: TSegmentIndex) => {
      const edge = reactFlow.getEdge(id);
      if (edge) {
        const points = (edge as Edge<TEditableEdgeData>).data?.points;
        if (points) {
          const filteredPoints = points.filter((_: XYPosition, index: number) => !segmentIndex.includes(index));
          const newPoints = filteredPoints.reduce(
            (
              acc: (XYPosition | TControlPointData)[],
              point: TControlPointData,
              index: number,
              arr: TControlPointData[]
            ) => {
              if (index < arr.length - 1) {
                const notDiagonal = !!getSegmentType(point, arr[index + 1]);
                if (notDiagonal) {
                  acc.push(point);
                } else {
                  acc.push(point);
                  // create additional point to keep the lines horizontal or vertical
                  const helperPoint = { x: point.x, y: arr[index + 1].y };
                  acc.push(helperPoint);
                }
                return acc;
              }
              acc.push(point);
              return acc;
            },
            [] as TControlPointData[]
          );
          const newPointsWithId = mapToControlPoints(newPoints, id, 'point');

          reactFlow.updateEdgeData(id, {
            ...edge.data,
            points: newPointsWithId,
          });
          setTouched(true);
        }
      }
    },
    [reactFlow, setTouched]
  );

  return {
    getPointContextMenu,
    deletePoint,
  };
};
