import { SIGNAL_STRENGTH, signalBoundaries } from './consts';
import { ISignalStrengthCellProps } from './desktop/connection-status';

export const getSignalPerModel = ({
  signalStrength,
  gatewayModel,
  isOnline,
  connectionType,
}: ISignalStrengthCellProps) => {
  const { high, medium, low } = signalBoundaries[gatewayModel];

  if (!isOnline) {
    return SIGNAL_STRENGTH.NO_SIGNAL;
  }

  if (gatewayModel === 'SmartBaseStationDT550') {
    return signalBoundaries[gatewayModel][connectionType ?? ''] || SIGNAL_STRENGTH.NO_SIGNAL;
  }

  if (signalStrength > high) {
    return SIGNAL_STRENGTH.EXCELLENT;
  }
  if (signalStrength > medium) {
    return SIGNAL_STRENGTH.GOOD;
  }
  if (signalStrength > low) {
    return SIGNAL_STRENGTH.FAIR;
  }
  return SIGNAL_STRENGTH.POOR;
};
