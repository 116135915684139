import { useAuth } from '@marlin/shared/utils-auth';
import { warrantyStorageService } from '@marlin/shared/utils-common-warranty-storage';
import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { z } from 'zod';

import { getOrganizationAddress } from '../infrastructure/onboard';
import { queryKey } from './keys';

type TQueryFnType = typeof getOrganizationAddress;

export const useOrganizationLatestAddress = () => {
  const { token } = useAuth();
  const { email = '' } = token ? jwtDecode<JwtPayload & { email: string }>(token) : {};
  const isWarranty = Boolean(warrantyStorageService.get());

  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: [queryKey.ORGANIZATION_ADDRESS, email],
    queryFn: () => getOrganizationAddress(email),
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);
    },
    enabled: !!email && isWarranty,
    staleTime: Infinity,
    refetchOnMount: true,
  });
};
