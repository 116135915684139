import { TEquipmentSchema } from '@marlin/asset/data-access/equipment';
import { PowerSource } from '@marlin/asset/shared/ui/battery';
import { ConnectionStatus } from '@marlin/asset/shared/ui/connection-status';
import {
  EquipmentCard,
  EquipmentListMobileUi,
  EquipmentListUi,
  useEquipmentHubContextMenu,
  usePagination,
} from '@marlin/asset/shared/ui/equipment-list';
import { MarlinTheme } from '@marlin/shared/theme';
import { ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import { TGatewayInfoUpdateResponsePayload } from '@marlin/shared/utils/zod';
import { ProductDetails } from '@marlin/ui/product-details';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { z } from 'zod';

import { content } from '../../../shared/content';
import { useGatewayDatapointsContext } from '../../../shared/context/gateway-datapoints.context';
import { useGatewayDetailsConfigContext } from '../../../shared/context/gateway-details-config.context';
import { useEquipmentsList } from '../../../shared/hooks/use-equipment-list.hook';
import { getDatapoint, getDatapointWithSchema } from '../../../shared/utils/get-datapoint.utils';
import { getIsOnline } from '../../../shared/utils/get-is-online.utils';
import { useNavigation } from '../../hooks/use-navigation.hook';
import { ConnectionType } from './connection-type.component';
import { DashboardAlerts } from './gateway-alerts.component';
import { GatewayDetailsSection } from './gateway-details.component';
import { StatusBar } from './top-bar-status.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.typography.pxToRem(24),
    rowGap: theme.typography.pxToRem(24),
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.down('md')]: {
      rowGap: theme.typography.pxToRem(4),
    },
  },
  productDetailsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(8),
    width: '100%',
    overflow: 'auto',
    margin: 0,
  },
  tileOverride: {
    // override default HeaderDatapoint styles
    '& > div, & > span': {
      minWidth: theme.typography.pxToRem(170),
    },
    '& > span': {
      padding: 0,
    },
  },
  accordion: {
    '&.Mui-expanded': {
      margin: 0,
    },
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
  },
  accordionSummary: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
    [theme.breakpoints.up('md')]: {
      '&.Mui-expanded:hover': {
        cursor: 'default',
      },
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.typography.pxToRem(0),
    [theme.breakpoints.down('lg')]: {
      gap: 'unset',
      marginTop: 0,
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.typography.pxToRem(12),
    height: theme.typography.pxToRem(74),
    maxWidth: theme.typography.pxToRem(170),
    flexDirection: 'column',
    borderRadius: theme.typography.pxToRem(4),
    backgroundColor: theme.palette.background.alternative,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  grid: {
    gridColumn: '1 / -1',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  dataGrid: {
    borderRadius: 0,
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  dataGridWrapper: {
    height: theme.typography.pxToRem(600),
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
    width: '100%',
  },
  header: {
    '& > div': {
      padding: theme.typography.pxToRem(16),
      [theme.breakpoints.down('md')]: {
        padding: 0,
        fontSize: theme.typography.pxToRem(16),
      },
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  mobilePaper: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  mobileMenu: {
    padding: 0,
  },
  tableTitle: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('md')]: {
      padding: 0,
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

interface IDashboard {
  gateway: TGatewayInfoUpdateResponsePayload;
}

export const Dashboard = ({ gateway }: IDashboard) => {
  const { classes, cx } = useStyles();
  const { datapoints, timestamp } = useGatewayDatapointsContext();
  const { setPage, setPageSize, paginationModel, filterParams } = usePagination();
  const { editEquipment } = useEquipmentHubContextMenu();
  const isOnline = getIsOnline(timestamp, gateway.lastReadingTime, gateway.model);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigation = useNavigation();
  const navigate = useNavigate();
  const {
    config: {
      dashboard: { equipmentAndSensorsList, topAlerts, displayGatewayMode, productDetails },
    },
  } = useGatewayDetailsConfigContext();

  const signalStrength = getDatapoint(
    productDetails?.datapoints.connectionStatus?.signalStrengthDatapointName ?? '',
    datapoints
  );
  const connectionType = getDatapoint(
    productDetails?.datapoints.connectionType?.connectionTypeDatapointName ?? '',
    datapoints
  );
  const powerSource = getDatapointWithSchema(
    productDetails?.datapoints.powerSource?.powerSourceDatapointName ?? '',
    datapoints,
    z.literal('LINE').or(z.literal('BATTERY'))
  );
  const batteryLevel = getDatapoint(
    productDetails?.datapoints.powerSource?.batteryLevelDatapointName ?? '',
    datapoints
  );

  const { rows, totalItems, hasNextPage, fetchNextPage } = useEquipmentsList(
    [gateway.id ?? ''],
    equipmentAndSensorsList.show
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.column}>
        <ProductDetails
          statusPanel={
            <StatusBar
              mode={gateway.mode}
              displayMode={displayGatewayMode}
              fullWidth={false}
              time={timestamp ?? gateway.lastReadingTime}
              isOnline={isOnline}
            />
          }
          logoSrc={productDetails?.logoSrc}
          model={gateway.model}
          equipmentModel={productDetails?.modelName}
          imageSrc={productDetails?.imageSrc}
        >
          <div
            data-testid="equipment-details-datapoint-tiles"
            className={cx(classes.productDetailsWrapper, classes.tileOverride)}
          >
            {productDetails?.datapoints?.connectionStatus?.display && (
              <div className={classes.container} data-testid="reading-datapoint-connection-status">
                <div className={classes.titleWrapper}>
                  <Typography variant="overline" className={classes.title}>
                    {content.CONNECTION_STATUS}
                  </Typography>
                </div>
                <div data-testid="connection-status">
                  <ConnectionStatus
                    signalStrength={Number(signalStrength)}
                    connectionType={connectionType}
                    gatewayModel={gateway.model}
                    isOnline={isOnline}
                  />
                </div>
              </div>
            )}
            {productDetails?.datapoints?.connectionType?.display && (
              <div className={classes.container} data-testid="reading-datapoint-connection-type">
                <div className={classes.titleWrapper}>
                  <Typography variant="overline" className={classes.title}>
                    {content.CONNECTION_TYPE}
                  </Typography>
                </div>
                <div data-testid="connection-type">
                  <ConnectionType connectionType={connectionType} />
                </div>
              </div>
            )}
            {productDetails?.datapoints?.powerSource?.display && (
              <div className={classes.container} data-testid="reading-datapoint-power-source">
                <div className={classes.titleWrapper}>
                  <Typography variant="overline" className={classes.title}>
                    {content.POWER_SOURCE}
                  </Typography>
                </div>
                <div data-testid="power-source">
                  <PowerSource powerSource={powerSource || undefined} batteryLevel={Number(batteryLevel)} />
                </div>
              </div>
            )}
          </div>
        </ProductDetails>
      </div>
      {topAlerts.show && (
        <div className={classes.column}>
          <DashboardAlerts gateway={gateway} />
        </div>
      )}
      {equipmentAndSensorsList.show && (
        <Accordion
          className={cx(classes.accordion, classes.grid)}
          data-testid="gateway-table-section"
          defaultExpanded
          expanded={!isMobile ? true : undefined}
          elevation={0}
        >
          <AccordionSummary className={classes.header} expandIcon={isMobile ? <ExpandMoreIcon /> : undefined}>
            <Typography variant="h5" className={classes.tableTitle}>
              {content.TABLE_TITLE}
            </Typography>
            {!isMobile && (
              <Button
                variant="outlined"
                onClick={() => navigate(navigation.getGatewayConfigurationPageLink(gateway.id ?? ''))}
                data-testid="edit-gateway-button"
              >
                {content.CONFIGURE_CONNECTION}
              </Button>
            )}
          </AccordionSummary>
          <AccordionDetails className={cx(classes.paper, classes.dataGridWrapper, classes.mobilePaper)}>
            {isMobile && (
              <EquipmentListMobileUi
                rows={rows}
                fetchNextPage={fetchNextPage}
                totalItems={totalItems}
                hasNextPage={hasNextPage}
              >
                {(equipment: TEquipmentSchema) => (
                  <EquipmentCard key={equipment.id} equipment={equipment} data-testid="equipment-card">
                    <ContextMenu className={classes.mobileMenu} data-testid="equipment-card-menu">
                      <ActionEdit onClick={() => editEquipment(equipment.id)} />
                    </ContextMenu>
                  </EquipmentCard>
                )}
              </EquipmentListMobileUi>
            )}
            {!isMobile && (
              <EquipmentListUi
                paginationModel={paginationModel}
                setPageSize={setPageSize}
                setPage={setPage}
                allowDelete={false}
                allowEdit={true}
                hideGatewayColumn
                filterParams={{ ...filterParams, gatewayIds: [gateway.id ?? ''] }}
              />
            )}
          </AccordionDetails>
        </Accordion>
      )}
      <div className={classes.grid} data-testid="gateway-details">
        <GatewayDetailsSection gateway={gateway} datapoints={datapoints} />
      </div>
    </div>
  );
};
