import { z } from 'zod';

import { content } from './content';

export enum GATEWAY_REGISTRATION_ERROR {
  INVALID_DEVICE = 11,
  GATEWAY_IS_NOT_CONFIGURED = 600,
  INVALID_GATEWAY_MODE = 601,
  REGISTRATION_CODE_EXPIRED = 602,
  REGISTRATION_CODE_ALREADY_USED = 603,
  REGISTRATION_CODE_DOES_NOT_EXIST = 604,
  REGISTRATION_CODE_OBSOLETE = 605,
  INSTALLATION_DATE_CANNOT_BE_IN_FUTURE = 606,
  NAME_MUST_BE_UNIQUE = 607,
  ALREADY_REGISTERED = 608,
}

export const GatewayRegistrationErrorCodeEnum = z.nativeEnum(GATEWAY_REGISTRATION_ERROR);

export const UpsertGatewayForm = z
  .object({
    name: z.string(),
    locationId: z.string(),
    locationName: z.string().optional(),
    registrationCode: z.string(),
    description: z.string().nullish(),
    commissionDate: z.string().nullish(),
  })
  .transform((data) => ({
    ...data,
    location: { id: data.locationId ?? '', name: data.locationName ?? '' },
  }));

export const UpsertGatewayUpdateForm = z
  .object({
    name: z.string(),
    locationId: z.string(),
    locationName: z.string().optional(),
    description: z.string().nullish(),
    commissionDate: z.string().nullish(),
  })
  .transform((data) => ({
    ...data,
    location: { id: data.locationId ?? '', name: data.locationName ?? '' },
  }));

export const configGatewayFormSchema = z
  .object({
    name: z
      .string({ required_error: content.REQUIRED })
      .trim()
      .min(1, content.REQUIRED)
      .min(3, content.MIN_MAX)
      .max(200, content.MIN_MAX),
    locationId: z.string({ required_error: content.REQUIRED }),
    locationName: z.string().optional(),
    registrationCode: z
      .string({ required_error: content.REQUIRED })
      .min(8, content.REGISTER_CODE_MIN_MAX)
      .regex(/^\d+$/, content.REGISTER_CODE_MIN_MAX),
    description: z.string().nullish(),
    commissionDate: z.string().nullish(),
  })
  .transform((data) => ({
    ...data,
    location: { id: data.locationId ?? '', name: data.locationName ?? '' },
  }));

export const updateGatewayFormSchema = z
  .object({
    name: z
      .string({ required_error: content.REQUIRED })
      .trim()
      .min(1, content.REQUIRED)
      .min(3, content.MIN_MAX)
      .max(200, content.MIN_MAX),
    locationId: z.string({ required_error: content.REQUIRED }),
    locationName: z.string().optional(),
    location: z.object({ id: z.string(), name: z.string() }).optional(),
    description: z.string().nullish(),
    commissionDate: z.string().nullish(),
  })
  .transform((data) => ({
    ...data,
    location: { id: data.locationId ?? '', name: data.locationName ?? '' },
  }));

export type TUpsertGatewayForm = z.infer<typeof UpsertGatewayForm>;
export type TUpsertGatewayUpdateForm = z.infer<typeof UpsertGatewayUpdateForm>;
