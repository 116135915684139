export enum SIGNAL_STRENGTH {
  EXCELLENT = 'Excellent',
  GOOD = 'Good',
  FAIR = 'Fair',
  POOR = 'Poor',
  NO_SIGNAL = 'NoSignal',
}

export const signalBoundaries = {
  Gateway: {
    high: -65,
    medium: -75,
    low: -85,
  },
  NexaGateway: {
    high: 75,
    medium: 50,
    low: 11,
  },
  SmartBaseStationDT550: {
    Ethernet: SIGNAL_STRENGTH.EXCELLENT,
    WiFi: SIGNAL_STRENGTH.EXCELLENT,
    Cellular: SIGNAL_STRENGTH.FAIR,
  },
};
