import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { ActionDelete, ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import { EquipmentType } from '@marlin/shared/ui-device-type';
import { Link } from '@marlin/shared/ui-page';
import { defaultDateTime, formatDate, getTimeFromNow } from '@marlin/shared/utils-common-date';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { modelContent } from '@marlin/shared/utils/datapoint-mappers';
import {
  EQUIPMENT_TYPE,
  TEquipment,
  gatewayEquipmentModels,
  registerableEquipmentModels,
} from '@marlin/shared/utils/zod';
import { GridColDef } from '@mui/x-data-grid';
import { useCallback, useContext } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { ModeMap } from '../../utils/equipment-hub.model';
import { getConnectionTimeout, getModeDatapoint, getStatusAdapter } from '../../utils/utils';
import { StatusBar } from '../components/equipment-status-bar';
import { useEquipmentHubContextMenu } from './use-equipment-hub-context-menu.hook';
import { useNavigation } from './use-navigation.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  filters: {
    marginLeft: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  filterFields: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.typography.pxToRem(16),
    width: '100%',
  },

  fieldWrapper: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(320),
  },

  searchFilter: {
    width: '100%',
  },

  locationFilter: {
    minWidth: '100%',
  },

  clearAll: {
    marginRight: theme.typography.pxToRem(16),
    marginLeft: theme.typography.pxToRem(16),
    whiteSpace: 'nowrap',
  },

  locationChipsWrapper: {
    marginLeft: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
  },

  cellStyle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexWrap: 'nowrap',
  },

  dataGrid: {
    borderRadius: 0,
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
  },
}));

export interface IPayload {
  title: ({ deviceCount }: { deviceCount?: number }) => JSX.Element;
  body: ({ id, name, type }: { id: string; name: string; deviceCount?: number; type?: EQUIPMENT_TYPE }) => JSX.Element;
  footer: ({ deviceCount, id }: { deviceCount: number; id: string }) => JSX.Element;
}

export const useCreateColumns = ({
  payloadDelete,
  payloadDeregister,
}: {
  payloadDelete?: IPayload;
  payloadDeregister?: IPayload;
}): GridColDef[] => {
  const { getEquipmentDetailsPageLink, getLocationDetailsPageLink, getGatewayDetailsPageLink } = useNavigation();
  const { classes } = useStyles();
  const { modalDispatch } = useContext(ModalContext);
  const { allowDelete, allowEdit, editEquipment, allowDeregister } = useEquipmentHubContextMenu();

  const getGatewayInfo = useCallback(
    (
      devices: {
        gatewayId: string;
        gatewayName: string;
      }[]
    ) => {
      if (devices && devices.length > 0 && devices[0].gatewayName && devices[0].gatewayId) {
        return {
          gatewayName: devices[0].gatewayName,
          gatewayId: devices[0].gatewayId,
        };
      }

      return null;
    },
    []
  );

  const deleteEquipment = useCallback(
    (equipment: TEquipment) => {
      if (!payloadDelete) {
        return;
      }

      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          title: payloadDelete.title({ deviceCount: equipment?.deviceCount || 0 }),
          body: payloadDelete.body({
            id: equipment.id,
            name: equipment.name,
            deviceCount: equipment?.deviceCount || 0,
            type: equipment.type,
          }),
          footer: payloadDelete.footer({ id: equipment.id, deviceCount: equipment?.deviceCount || 0 }),
        },
      });
    },
    [modalDispatch, payloadDelete]
  );

  const deregisterEquipment = useCallback(
    (equipment: TEquipment) => {
      if (!payloadDeregister) {
        return;
      }

      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          title: payloadDeregister.title({}),
          body: payloadDeregister.body({
            id: equipment?.devices?.[0]?.deviceId ?? equipment?.id,
            name: equipment.name,
            type: equipment.type,
          }),
          footer: payloadDeregister.footer({ deviceCount: equipment.deviceCount || 0, id: equipment?.id }),
        },
      });
    },
    [modalDispatch, payloadDeregister]
  );

  return [
    {
      field: 'name',
      headerName: content.EQUIPMENT_HEADER_NAME,
      flex: 3,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      renderCell: (cellValues) => {
        return (
          <Tooltip text={cellValues.value} placement="top">
            <Link
              className={classes.cellStyle}
              to={getEquipmentDetailsPageLink(
                cellValues.row.id,
                (registerableEquipmentModels.includes(cellValues.row.model) && cellValues.row.isRegistered) ||
                  (gatewayEquipmentModels.includes(cellValues.row.model) && cellValues.row.devices[0]?.gatewayId)
              )}
            >
              {cellValues.value}
            </Link>
          </Tooltip>
        );
      },
    },
    {
      field: 'locationName',
      headerName: content.LOCATION_HEADER_NAME,
      type: 'string',
      flex: 3,
      headerAlign: 'left',
      align: 'left',
      renderCell: (cellValues) => {
        return (
          <Tooltip text={cellValues.row.locationName} placement="top">
            <Link
              className={classes.cellStyle}
              to={getLocationDetailsPageLink(cellValues.row.locationId, cellValues.row.locationName)}
            >
              {cellValues.row.locationName}
            </Link>
          </Tooltip>
        );
      },
    },
    {
      field: 'gateway',
      headerName: content.GATEWAY_HEADER_NAME,
      type: 'string',
      flex: 3,
      headerAlign: 'left',
      align: 'left',
      sortable: false,

      renderCell: (cellValues) => {
        const gateway = getGatewayInfo(cellValues.row.devices);

        return gateway ? (
          <Tooltip text={gateway.gatewayName} placement="top">
            <Link className={classes.cellStyle} to={getGatewayDetailsPageLink(gateway.gatewayId)}>
              {gateway.gatewayName}
            </Link>
          </Tooltip>
        ) : (
          content.EMPTY_DATAPOINT_VALUE
        );
      },
    },
    {
      field: 'type',
      headerName: content.TYPE_HEADER_NAME,
      type: 'string',
      flex: 3,
      maxWidth: 180,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: (cellValues) => {
        return <EquipmentType equipmentType={cellValues.row.type} />;
      },
    },
    {
      field: 'status',
      headerName: content.STATUS_HEADER_NAME,
      type: 'string',
      maxWidth: 180,
      flex: 3,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: (cellValues) => {
        const connectionTimeout = getConnectionTimeout(cellValues.row.model);
        const lastReadingTime = cellValues.row?.devices[0]?.lastReadingTime;

        if (!cellValues.row.isRegistered && !cellValues.row?.devices[0]?.gatewayId) {
          return content.EMPTY_DATAPOINT_VALUE;
        }

        return (
          <StatusBar
            isOperational={
              lastReadingTime
                ? (getTimeFromNow(lastReadingTime, 'seconds') ?? Number.MAX_SAFE_INTEGER) < connectionTimeout
                : false
            }
            tooltip={true}
          />
        );
      },
    },
    {
      field: 'mode',
      headerName: content.MODE_HEADER_NAME,
      type: 'string',
      width: 100,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: (cellValues) => {
        const statusIdToKeyAdapter = getStatusAdapter(cellValues.row?.model);

        if (!statusIdToKeyAdapter) {
          return content.EMPTY_DATAPOINT_VALUE;
        }

        const mode = cellValues.row?.devices[0]?.lastReadingValues.find(
          (datapoint: { name: string }) => datapoint.name.toLocaleLowerCase() === getModeDatapoint(cellValues.row.model)
        )?.value;
        const modeDisplayValue = statusIdToKeyAdapter(mode);
        const modeDisplayValueMap = ModeMap.get(cellValues.row.model);

        const value =
          (modeDisplayValue && modeDisplayValueMap?.get(modeDisplayValue.toLocaleLowerCase())) ??
          content.EMPTY_DATAPOINT_VALUE;

        return (
          <Tooltip placement="top" text={value || ''}>
            <span className={classes.cellStyle}>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'lastReadingTime',
      headerName: content.LAST_READING_TIME_HEADER_NAME,
      type: 'string',
      maxWidth: 180,
      flex: 3,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: (cellValues) => {
        const utcDate = cellValues.row?.devices[0]?.lastReadingTime;

        return utcDate ? (
          <Tooltip
            placement="top"
            text={`${content.LAST_READING_TIME_HEADER_NAME}: ${formatDate(utcDate, defaultDateTime)}`}
          >
            <span className={classes.cellStyle}>{formatDate(utcDate, defaultDateTime)}</span>
          </Tooltip>
        ) : (
          content.EMPTY_DATAPOINT_VALUE
        );
      },
    },
    {
      field: 'model',
      headerName: content.MODEL_HEADER_NAME,
      type: 'string',
      width: 120,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: (cellValues) => {
        const model = modelContent.MODELS.get(cellValues.row?.model) ?? cellValues.row?.model;
        const modelVariant = cellValues.row?.devices[0]?.lastReadingValues.find(
          (datapoint: { name: string }) => datapoint.name.toLocaleLowerCase() === 'modelvariant'
        )?.value;

        const value =
          model && modelVariant ? `${model} ${modelVariant}` : model ? model : content.EMPTY_DATAPOINT_VALUE;
        return (
          <Tooltip placement="top" text={value}>
            <span className={classes.cellStyle}>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'serialNumber',
      headerName: content.SERIAL_NUMBER_HEADER_NAME,
      type: 'string',
      flex: 3,
      maxWidth: 180,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: (cellValues) => {
        const value = cellValues.row?.serialNumber ?? content.EMPTY_DATAPOINT_VALUE;
        return (
          <Tooltip placement="top" text={value}>
            <span className={classes.cellStyle}>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      flex: 0.5,
      align: 'center',
      renderCell: (cellValues) => {
        const isRegistered = cellValues.row.isRegistered;
        const onDeleteActionClick = isRegistered ? deregisterEquipment : deleteEquipment;
        const gateway = getGatewayInfo(cellValues.row.devices);
        const isDeletionAllowed = (isRegistered ? allowDeregister : allowDelete) && !gateway;

        return (
          <ContextMenu>
            {allowEdit && <ActionEdit onClick={() => editEquipment(cellValues.row.id)} />}
            {isDeletionAllowed && (
              <ActionDelete onClick={() => onDeleteActionClick(cellValues.row)} deregisterText={isRegistered} />
            )}
          </ContextMenu>
        );
      },
    },
  ];
};
