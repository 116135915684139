export const sankeyStyle = { alignSelf: 'center', height: '470px' };

export const sankeyLayout = {
  font: { size: 16, family: 'Inter' },
  margin: {
    t: 32,
    b: 144,
    l: 56,
    r: 144,
  },
};

export const sankeyConfig = { displaylogo: false, displayModeBar: false, responsive: true };
