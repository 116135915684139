import { DateSchema } from '@marlin/shared/utils-common-date';
import { lastReadingValuesSchema } from '@marlin/shared/utils-format-reading';
import { DeviceTypeSchemaParser } from '@marlin/system-map/shared/data-access-schemas';
import { z } from 'zod';

import { mapLastReadingValues } from './flow-map.utils';
import { NodeAlertSchema } from './node-alerts.model.schema';

export const systemMapUpdateDataSchema = z
  .object({
    lastReadingValue: z.number().nullish(),
    lastReadingTime: DateSchema.nullish(),
    deviceType: DeviceTypeSchemaParser,
    alerts: z.array(NodeAlertSchema).nullish(),
    datapoints: z.array(lastReadingValuesSchema),
  })
  .transform(({ lastReadingValue, deviceType, lastReadingTime, alerts, datapoints }) => {
    const reading = mapLastReadingValues(datapoints);

    return {
      lastReading: lastReadingValue,
      lastReadingTime: lastReadingTime,
      reading,
      alerts,
      lastReadingValues: datapoints,
    };
  });

export const systemMapUpdateSchema = z.object({
  data: systemMapUpdateDataSchema,
  nodeId: z.string(),
});

export const systemMapUpdatesSchema = z.object({
  nodes: z.array(systemMapUpdateSchema),
});

export type TSystemMapUpdate = z.infer<typeof systemMapUpdateSchema>;
export type TSystemMapUpdates = z.infer<typeof systemMapUpdatesSchema>;
