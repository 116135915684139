import { FormField } from '@marlin/shared/ui-form-common';
import { CommonChip } from '@marlin/shared/ui/chips';
import { Autocomplete, Paper, PaperProps, TextField } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete';
import React from 'react';

import { content } from './content';
import { TSelectedFlowMeter, TWebhookForm } from './schema';

interface IReadOnlySelectedFlowMetersProps {
  isPartition?: boolean;
}

export const ReadOnlySelectedFlowMeters = ({ isPartition }: IReadOnlySelectedFlowMetersProps) => {
  return (
    <FormField<TWebhookForm> fieldName="devices">
      {(props) => (
        <Autocomplete
          multiple
          value={props.value}
          isOptionEqualToValue={(option: TSelectedFlowMeter, value: TSelectedFlowMeter) =>
            option.deviceId === value.deviceId
          }
          id="selected-devices"
          options={props.value as TSelectedFlowMeter[]}
          disabled={false}
          renderTags={(flowMeters: TSelectedFlowMeter[], getTagProps: AutocompleteRenderGetTagProps) =>
            flowMeters.map((flowMeter, index) => (
              <div style={{ margin: 1 }}>
                <CommonChip key={index} label={flowMeter.name} active />
              </div>
            ))
          }
          filterSelectedOptions
          limitTags={2}
          renderInput={(params) => (
            <TextField
              {...params}
              label={isPartition ? content.SELECTED_DEVICES_LABEL : content.SELECTED_FLOW_METERS_LABEL}
              inputProps={{ ...params.inputProps, readOnly: true }}
            />
          )}
          disableClearable={true}
          open={false}
          forcePopupIcon={true}
          PaperComponent={(props: PaperProps) => <Paper {...props} />}
        />
      )}
    </FormField>
  );
};
