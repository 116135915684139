import { IConfig } from '@marlin/asset/shared/equipment-config';
import { environment } from '@marlin/environment';
import { EQUIPMENT_TAB_VALUES } from '@marlin/shared/utils-routes';

import { tekmarSmartBoilerConfig as sharedTekmarSmartBoilerConfig } from '../../../../shared/config/tekmar-smart-boiler/config';
import { HistoricalChart } from '../../../components/chart/historical-chart';
import { EquipmentFlow } from '../../../components/flow/equipment-flow.component';
import { EquipmentSettings } from '../../../components/settings/equipment-settings.component';
import { BoilersAndPumps } from '../../components/boilers-and-pumps/boilers-and-pumps.component';
import { EventLog } from '../../components/event-log/event-log.component';

const tabs = {
  [EQUIPMENT_TAB_VALUES.EVENT_LOG]: <EventLog />,
  [EQUIPMENT_TAB_VALUES.HISTORICAL_CHART]: <HistoricalChart />,
  [EQUIPMENT_TAB_VALUES.BOILERS_AND_PUMPS]: <BoilersAndPumps />,
  [EQUIPMENT_TAB_VALUES.SETTINGS]: <EquipmentSettings />,
};

if (environment.module.features?.equipmentFlow) {
  tabs[EQUIPMENT_TAB_VALUES.EQUIPMENT_FLOW] = <EquipmentFlow />;
}

export const tekmarSmartBoilerConfig: IConfig = {
  ...sharedTekmarSmartBoilerConfig,
  headerBanner: null,
  tabs,
};
