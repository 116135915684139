import { useActivateGateway } from '@marlin/asset/data-access/gateway';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { Button, CircularProgress } from '@mui/material';
import { AxiosError } from 'axios';
import { useCallback } from 'react';

import { content } from '../../content';
import { useModalStyles } from '../../hooks/modals/use-modal-styles.hook';

export const ActivateModalFooter = ({
  deviceId,
  handleCloseModal,
}: {
  deviceId?: string;
  handleCloseModal: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const { classes } = useModalStyles();

  const onActivateGatewaySuccess = useCallback(() => {
    enqueueSnackbar(content.ACTIVATE_MESSAGE, {
      variant: 'success',
      preventDuplicate: true,
    });
    handleCloseModal();
    router.goTo(routes.gateway.list.url());
  }, [enqueueSnackbar, router, handleCloseModal]);

  const onActivateGatewayError = useCallback(
    (error: AxiosError<{ errorMessage: string; status: string }>) => {
      if (error?.response?.data?.status) {
        enqueueSnackbar(error?.response?.data.errorMessage, { variant: 'error', preventDuplicate: true });
      } else {
        enqueueSnackbar(content.ERROR_MESSAGE, {
          variant: 'error',
          preventDuplicate: true,
        });
      }

      handleCloseModal();
    },
    [enqueueSnackbar, handleCloseModal]
  );

  const activateGatewayMutation = useActivateGateway({
    deviceId: deviceId ?? '',
    onSuccess: onActivateGatewaySuccess,
    onError: onActivateGatewayError,
  });

  const onActivateClick = useCallback(() => {
    activateGatewayMutation.mutate({ deviceId: deviceId ?? '' });
  }, [activateGatewayMutation, deviceId]);

  return (
    <div>
      {activateGatewayMutation.isPending && (
        <div className={classes.overlay}>
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        </div>
      )}
      <Button
        className={classes.cancelButton}
        variant="text"
        data-testid="activate-gateway-modal-footer-cancel"
        onClick={handleCloseModal}
      >
        {content.ACTIVATE_GATEWAY_MODAL.CANCEL}
      </Button>
      <Button variant="contained" data-testid="activate-gateway-modal-footer-confirm" onClick={onActivateClick}>
        {content.ACTIVATE_GATEWAY_MODAL.ACTIVATE}
      </Button>
    </div>
  );
};
