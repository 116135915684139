import { IconEquipmentMode } from '@marlin/asset/shared/ui/assets';

import { content } from './content';
import type { TStatusMap } from './types';

export const statusIdToKeyAdapter = (id: string): string | undefined =>
  ({
    '0': 'Disabled',
    '1': 'Standby',
    '2': 'Manual',
    '3': 'Remote',
    '4': 'Auto',
    '5': 'Fault',
  }[id]);

export const aercoEquipmentModeLabelMap: TStatusMap = new Map([
  [
    'Disabled',
    {
      mode: content.EQUIPMENT_MODE_LABELS_AERCO.DISABLED,
      equipmentLabel: content.EQUIPMENT_STATUS_LABELS.LOST_COMMUNICATION,
      Icon: IconEquipmentMode.Offline,
    },
  ],
  ['Standby', { mode: content.EQUIPMENT_MODE_LABELS_AERCO.STANDBY, Icon: IconEquipmentMode.Standby }],
  ['Manual', { mode: content.EQUIPMENT_MODE_LABELS_AERCO.MANUAL, Icon: IconEquipmentMode.Manual }],
  [
    'Remote',
    {
      mode: content.EQUIPMENT_MODE_LABELS_AERCO.REMOTE,
      Icon: IconEquipmentMode.Remote,
    },
  ],
  ['Auto', { mode: content.EQUIPMENT_MODE_LABELS_AERCO.AUTO, Icon: IconEquipmentMode.Auto }],
  [
    'Fault',
    {
      mode: content.EQUIPMENT_MODE_LABELS_AERCO.FAULT,
      equipmentLabel: content.EQUIPMENT_STATUS_LABELS.FAULT,
      Icon: IconEquipmentMode.Error,
    },
  ],
]);
