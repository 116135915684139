import { MarlinTheme } from '@marlin/shared/theme';
import { statusIdToKeyAdapterIntellistation, statusMode } from '@marlin/shared/utils/datapoint-mappers';
import { createDataTestId } from '@marlin/shared/utils/testing-utils';
import { TLastReadingValue } from '@marlin/system-map/data-access/system-map';
import { makeStyles } from 'tss-react/mui';

interface IStatusModeProps {
  statusReadingValue?: TLastReadingValue;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  mode: {
    border: `1px solid ${theme.palette.divider}`,
    padding: `${theme.typography.pxToRem(2)} ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(
      2
    )} ${theme.typography.pxToRem(4)}`,
    borderRadius: theme.typography.pxToRem(32),
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightMedium,
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: theme.typography.pxToRem(6),
    marginBottom: theme.typography.pxToRem(6),
  },
  icon: {
    '&.material-symbols-outlined': {
      marginRight: theme.typography.pxToRem(4),
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export const StatusMode = ({ statusReadingValue }: IStatusModeProps) => {
  const { classes } = useStyles();

  const idToKey = statusIdToKeyAdapterIntellistation(statusReadingValue?.value || '');
  const mode = statusMode.intellistation.get(idToKey || '');

  if (!mode) {
    return null;
  }

  return (
    <div className={classes.mode} data-testid={createDataTestId('statusMode', mode.mode, mode.equipmentLabel)}>
      {mode.Icon && <mode.Icon data-testid="equipment-mode-icon" className={classes.icon} />}
      {mode.mode}
    </div>
  );
};
