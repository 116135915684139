import { z } from 'zod';

export const orgAddressSchema = z.object({
  country: z.string(),
  address1: z.string(),
  address2: z.string().nullish(),
  city: z.string(),
  state: z.string(),
  postalCode: z.string(),
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
});

export type TOrgAddress = z.infer<typeof orgAddressSchema>;

export const AddressSelectSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const AddressSchema = orgAddressSchema.merge(AddressSelectSchema.partial());

export type TAddressSelect = z.infer<typeof AddressSelectSchema>;
export type TAddress = z.infer<typeof AddressSchema>;
