import { TPeriodDate } from '@marlin/shared/ui/chart';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure, getUomSuffix } from '@marlin/shared/utils-format-reading';
import * as Plotly from 'plotly.js';
import { useMemo } from 'react';

interface IUseTotalWaterConsumptionLayoutProps {
  uoM?: TUnitOfMeasure | null;
  selectedUnit: 'volume' | 'dollars';
  currentPeriod: { from: TPeriodDate; to: TPeriodDate };
}

export const useTotalWaterChartLayout = ({
  uoM,
  selectedUnit,
  currentPeriod,
}: IUseTotalWaterConsumptionLayoutProps): Partial<Plotly.Layout> => {
  const range = useMemo(() => {
    return {
      from: dateAdapter.date(currentPeriod.from),
      to: dateAdapter.date(currentPeriod.to),
    };
  }, [currentPeriod]);

  const layout: Partial<Plotly.Layout> = useMemo(() => {
    return {
      autosize: true,
      responsive: true,
      xaxis: { type: 'date', range: [range?.from?.toISOString(true), range?.to?.toISOString(true)] },
      xaxis2: {
        type: 'date',
        side: 'top',
        overlaying: 'x',
        visible: false,
      },
      yaxis: {
        side: 'right',
        ticksuffix: selectedUnit === 'volume' ? getUomSuffix(uoM) || '' : '',
        tickprefix: selectedUnit === 'dollars' ? '$' : '',
      },
      margin: {
        t: 16,
        b: 32,
        l: 16,
      },
      dragmode: false,
      showlegend: false,
      hovermode: false,
    };
  }, [range?.from, range?.to, selectedUnit, uoM]);

  return layout;
};
