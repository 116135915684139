import { TDurationDetails } from '@marlin/shared/utils-chart';
import { TExtractFnReturnType, TQueryConfig, queryClient, useQuery } from '@marlin/shared/utils/react-query';

import { TAnalyticsTelemetryRequestParams } from '../infrastructure/schema/telemetry-analytics.request.schema';
import { getAnalyticsTelemetry } from '../infrastructure/telemetry';
import { queryKey } from './query-key.enum';

export type TUseAnalyticsTelemetryReturnedType = TExtractFnReturnType<typeof getAnalyticsTelemetry>;

export const useAnalyticsTelemetry = (
  params: TAnalyticsTelemetryRequestParams,
  config?: Omit<TQueryConfig<typeof getAnalyticsTelemetry>, 'queryKey' | 'queryFn'>
) => {
  return useQuery<TUseAnalyticsTelemetryReturnedType, Error>({
    networkMode: 'always',
    enabled: params.requestedTelemetry.length > 0 && Boolean(params.dateFrom) && Boolean(params.dateTo),
    queryKey: queryKey.ANALYTICS_TELEMETRY(params),
    queryFn: () => getAnalyticsTelemetry(params),
    placeholderData: (previousData, previousQuery) => previousData,
    ...config,
  });
};

export const invalidateAnalyticsTelemetry = (details: TDurationDetails<string>) => {
  Object.values(details).forEach(({ currentDuration }) => {
    if (currentDuration.isZoomed) {
      const zoomedChartQueryParams = queryClient
        .getQueriesData({ queryKey: queryKey.ANALYTICS_TELEMETRY() })
        .find(([query]) => {
          const { dateFrom, dateTo } = (query[1] || {}) as { dateFrom: string; dateTo: string };

          return dateFrom === currentDuration.from && dateTo === currentDuration.to;
        });

      if (zoomedChartQueryParams) {
        const [query] = zoomedChartQueryParams;
        const params = query[1] as TAnalyticsTelemetryRequestParams;

        queryClient.invalidateQueries({ queryKey: queryKey.ANALYTICS_TELEMETRY(params) });
      }
    }
  });
};
