import { areNodesOverlapping } from './are-nodes-overlapping';
import { INode, TNodesGroup, TOverlappingNode } from './types';

export const findOverlappingNodes = <T extends INode>(nodes: T[]) => {
  const overlappingNodes = nodes.reduce((acc, node, index) => {
    const isOverlapping = nodes
      .filter((otherNode) => otherNode.id !== node.id)
      .some((otherNode) => {
        return areNodesOverlapping(node, otherNode);
      });

    if (isOverlapping) {
      acc.set(node.id, node);
    }
    return acc;
  }, new Map<string, T>());

  return Array.from(overlappingNodes.values());
};

export const findGroupsWithOverlappingNodes = <T extends INode>(
  groupedNodes: TNodesGroup<T>
): TOverlappingNode<T>[] => {
  const groupsWithOverlappingNodes = Object.entries(groupedNodes)
    .map(([groupId, nodeGroup]): [string, T[]] => {
      return [groupId, findOverlappingNodes(nodeGroup)];
    })
    .filter(([_key, nodes]) => {
      return nodes.length > 0;
    });

  const overlappingNodesWithGroupId: TOverlappingNode<T>[] = groupsWithOverlappingNodes
    .map(([groupId, nodes]) => {
      return nodes.map((node): TOverlappingNode<T> => [node, groupId]);
    })
    .flat();

  return overlappingNodesWithGroupId;
};
