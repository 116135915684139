import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import {
  GATEWAY_REGISTRATION_ERROR,
  TUpsertGatewayForm,
  configGatewayFormSchema,
  handleRegistrationError,
  updateGatewayFormSchema,
} from '@marlin/shared/utils/gateway-utils';
import { TGatewayInfoUpdatePayload } from '@marlin/shared/utils/zod';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ValidationMode, useForm } from 'react-hook-form';

interface IGatewayUpsertFormProps {
  defaultValues?: TGatewayInfoUpdatePayload;
  registrationErrorCode?: GATEWAY_REGISTRATION_ERROR | null;
  mode?: keyof ValidationMode | undefined;
}

export const useGatewayUpsertForm = ({
  defaultValues,
  registrationErrorCode,
  mode = 'onTouched',
}: IGatewayUpsertFormProps = {}) => {
  const { enqueueSnackbar } = useSnackbar();

  const form = useForm<TUpsertGatewayForm>({
    mode,
    resolver: zodResolver(defaultValues ? updateGatewayFormSchema : configGatewayFormSchema),
    defaultValues: defaultValues ? updateGatewayFormSchema.parse(defaultValues) : undefined,
  });

  const {
    watch,
    setValue,
    setError,
    formState: { errors },
  } = form;

  const registerCode = watch('registrationCode');

  const handleClearRegisterCode = useCallback(() => {
    setValue('registrationCode', '');
  }, [setValue]);

  useEffect(() => {
    if (registrationErrorCode) {
      handleRegistrationError(registrationErrorCode, setError, enqueueSnackbar);
    }
  }, [registrationErrorCode, setError, enqueueSnackbar]);

  const [dateValidationErrorMessage, setDateValidationErrorMessage] = useState<string | undefined>(undefined);

  const disableSubmitButton = useMemo(
    () =>
      !form.formState.isDirty ||
      !form.formState.isValid ||
      !!Object.keys(form.formState.errors).length ||
      Boolean(dateValidationErrorMessage),
    [dateValidationErrorMessage, form.formState]
  );

  return {
    form,
    errors,
    registerCode,
    dateValidationErrorMessage,
    disableSubmitButton,
    setDateValidationErrorMessage,
    handleClearRegisterCode,
  };
};
