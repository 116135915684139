import { IChartOptions } from '@marlin/asset/shared/ui/chart-options';
import { ChartSwitcherWrapper } from '@marlin/asset/shared/ui/chart-switcher-wrapper';
import { IChartSeries } from '@marlin/shared/utils-chart';

interface IFullScreenChart extends IChartOptions {
  chartData: IChartSeries[];
  isFetching: boolean;
  isLoading: boolean;
  from: number;
  to: number;
  isFullScreen?: boolean;
}

export const FullScreenChart = ({
  chartData,
  isFetching,
  isLoading,
  from,
  to,
  layout,
  config,
  handleRelayout,
  isEmpty,
  handleUnhover,
  handleHover,
  tooltip,
  isFullScreen,
  uoms,
}: IFullScreenChart) => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ChartSwitcherWrapper
        from={from}
        to={to}
        isFetching={isFetching}
        isLoading={isLoading}
        chartDisplayType={'line'}
        chartData={chartData}
        currentAnnotationTooltip={null}
        chartId={'full-screen-chart'}
        layout={layout}
        config={config}
        handleRelayout={handleRelayout}
        isEmpty={isEmpty}
        handleUnhover={handleUnhover}
        handleHover={handleHover}
        tooltip={tooltip}
        isFullScreen={isFullScreen}
        uoms={uoms}
      />
    </div>
  );
};
