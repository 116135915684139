import { createDateString } from '@marlin/shared/utils-common-date';
import { UnitOfMeasureType } from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

import { DEVICE_RESPONSE_TYPE, DEVICE_TYPE } from '../automation.model';
import { CRITICALITY_RESPONSE } from '../infrastructure/automation.model';
import { mapCriticalityResponse, mapDevice } from '../infrastructure/dtos/utils';
import { channelsResponseSchema, channelsSchema } from './channel.schema';
import { mapConditions } from './condition.schema';
import { TDeviceType } from './device-type.schema';
import {
  inactivityDurationTimeUnitResponseSchema,
  mapDuration,
  mapInactivityDurationTimeUnit,
} from './duration.schema';

export const deviceSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.nativeEnum(DEVICE_RESPONSE_TYPE).transform((type) => mapDevice(type)),
});

export const locationSchema = z.object({
  id: z.string(),
  name: z.string(),
  parentLocationId: z.string().nullish(),
  parentLocationName: z.string().nullish(),
});

export const equipmentSchema = z.object({
  id: z.string().nullable(),
  name: z.string().nullable(),
});

export enum AUTOMATION_RULE_TYPE_RESPONSE {
  TEMPERATURE = 'Temperature',
  PRESSURE = 'Pressure',
  LEAK = 'Leak',
  FLOW_METER_RATE = 'FlowMeterRate',
  BATTERY_LEVEL = 'BatteryLevel',
  GATEWAY_LOST_COMMUNICATION = 'GatewayLostCommunication',
  DEVICE_LOST_COMMUNICATION = 'DeviceLostCommunication',
  DEVICE_ALERT = 'DeviceAlert',
  DEVICE_ERROR = 'DeviceError',
  EQUIPMENT_INFORMATION = 'EquipmentInformation',
}

export enum AUTOMATION_RULE_TYPE {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  LEAK = 'LEAK',
  FLOW_METER_RATE = 'FLOW_METER_RATE',
  BATTERY_LEVEL = 'BATTERY_LEVEL',
  GATEWAY_LOST_COMMUNICATION = 'GATEWAY_LOST_COMMUNICATION',
  DEVICE_LOST_COMMUNICATION = 'DEVICE_LOST_COMMUNICATION',
  DEVICE_ALERT = 'DEVICE_ALERT',
  DEVICE_ERROR = 'DEVICE_ERROR',
  EQUIPMENT_INFORMATION = 'EQUIPMENT_INFORMATION',
}

export const automationRuleTypeResponseSchema = z
  .nativeEnum(AUTOMATION_RULE_TYPE_RESPONSE)
  .default(AUTOMATION_RULE_TYPE_RESPONSE.TEMPERATURE);
export const automationRuleTypeSchema = z.nativeEnum(AUTOMATION_RULE_TYPE);

export const mapAutomationRuleType = (automationRuleResponse: AUTOMATION_RULE_TYPE_RESPONSE): AUTOMATION_RULE_TYPE => {
  switch (automationRuleResponse) {
    case AUTOMATION_RULE_TYPE_RESPONSE.TEMPERATURE: {
      return AUTOMATION_RULE_TYPE.TEMPERATURE;
    }
    case AUTOMATION_RULE_TYPE_RESPONSE.PRESSURE: {
      return AUTOMATION_RULE_TYPE.PRESSURE;
    }
    case AUTOMATION_RULE_TYPE_RESPONSE.LEAK: {
      return AUTOMATION_RULE_TYPE.LEAK;
    }
    case AUTOMATION_RULE_TYPE_RESPONSE.FLOW_METER_RATE: {
      return AUTOMATION_RULE_TYPE.FLOW_METER_RATE;
    }
    case AUTOMATION_RULE_TYPE_RESPONSE.BATTERY_LEVEL: {
      return AUTOMATION_RULE_TYPE.BATTERY_LEVEL;
    }
    case AUTOMATION_RULE_TYPE_RESPONSE.GATEWAY_LOST_COMMUNICATION: {
      return AUTOMATION_RULE_TYPE.GATEWAY_LOST_COMMUNICATION;
    }
    case AUTOMATION_RULE_TYPE_RESPONSE.DEVICE_LOST_COMMUNICATION: {
      return AUTOMATION_RULE_TYPE.DEVICE_LOST_COMMUNICATION;
    }
    case AUTOMATION_RULE_TYPE_RESPONSE.DEVICE_ALERT: {
      return AUTOMATION_RULE_TYPE.DEVICE_ALERT;
    }
    case AUTOMATION_RULE_TYPE_RESPONSE.DEVICE_ERROR: {
      return AUTOMATION_RULE_TYPE.DEVICE_ERROR;
    }
    case AUTOMATION_RULE_TYPE_RESPONSE.EQUIPMENT_INFORMATION: {
      return AUTOMATION_RULE_TYPE.EQUIPMENT_INFORMATION;
    }
  }
};

export const mapAutomationRuleTypeResponse = (
  automationRuleResponse: AUTOMATION_RULE_TYPE
): AUTOMATION_RULE_TYPE_RESPONSE => {
  switch (automationRuleResponse) {
    case AUTOMATION_RULE_TYPE.TEMPERATURE: {
      return AUTOMATION_RULE_TYPE_RESPONSE.TEMPERATURE;
    }
    case AUTOMATION_RULE_TYPE.PRESSURE: {
      return AUTOMATION_RULE_TYPE_RESPONSE.PRESSURE;
    }
    case AUTOMATION_RULE_TYPE.LEAK: {
      return AUTOMATION_RULE_TYPE_RESPONSE.LEAK;
    }
    case AUTOMATION_RULE_TYPE.FLOW_METER_RATE: {
      return AUTOMATION_RULE_TYPE_RESPONSE.FLOW_METER_RATE;
    }
    case AUTOMATION_RULE_TYPE.BATTERY_LEVEL: {
      return AUTOMATION_RULE_TYPE_RESPONSE.BATTERY_LEVEL;
    }
    case AUTOMATION_RULE_TYPE.GATEWAY_LOST_COMMUNICATION: {
      return AUTOMATION_RULE_TYPE_RESPONSE.GATEWAY_LOST_COMMUNICATION;
    }
    case AUTOMATION_RULE_TYPE.DEVICE_LOST_COMMUNICATION: {
      return AUTOMATION_RULE_TYPE_RESPONSE.DEVICE_LOST_COMMUNICATION;
    }
    case AUTOMATION_RULE_TYPE.DEVICE_ALERT: {
      return AUTOMATION_RULE_TYPE_RESPONSE.DEVICE_ALERT;
    }
    case AUTOMATION_RULE_TYPE.DEVICE_ERROR: {
      return AUTOMATION_RULE_TYPE_RESPONSE.DEVICE_ERROR;
    }
    case AUTOMATION_RULE_TYPE.EQUIPMENT_INFORMATION: {
      return AUTOMATION_RULE_TYPE_RESPONSE.EQUIPMENT_INFORMATION;
    }
  }
};

export const automationBaseSchema = z.object({
  id: z.string(),
  ruleType: automationRuleTypeResponseSchema.transform(mapAutomationRuleType),
  name: z.string(),
  manufacturerId: z.string().nullable(),
  isEnabled: z.boolean(),
  snoozeIntervalInMinutes: z.number(),
  thresholdMin: z.number().nullable(),
  thresholdMax: z.number().nullable(),
  deadbandMin: z.number().nullable(),
  deadbandMax: z.number().nullable(),
  snoozeUntil: z.string().nullable(),
  inactivityDuration: z.number().nullable(),
  inactivityDurationTimeUnit: inactivityDurationTimeUnitResponseSchema
    .nullable()
    .transform(mapInactivityDurationTimeUnit)
    .nullable(),
  isSystemRule: z.boolean(),
  criticality: z.nativeEnum(CRITICALITY_RESPONSE).transform((criticality) => mapCriticalityResponse(criticality)),
  channels: channelsResponseSchema.transform((channels) => channelsSchema.parse(channels)),
  uoM: UnitOfMeasureType.nullish(),
});

export const automationSchema = automationBaseSchema
  .extend({
    device: deviceSchema.nullable(),
    location: locationSchema.nullable(),
    equipment: equipmentSchema.nullable(),
  })
  .refine(({ isSystemRule, location, equipment, device, manufacturerId }) => {
    if (!isSystemRule) {
      if (location === null) {
        return false;
      }

      if (device === null) {
        return false;
      }

      if (manufacturerId === null) {
        return false;
      }
    }

    return true;
  })
  .transform((data) => {
    const {
      thresholdMin,
      thresholdMax,
      deadbandMin,
      deadbandMax,
      inactivityDuration,
      inactivityDurationTimeUnit,
      ...rest
    } = data;

    return {
      ...rest,
      device: data.device
        ? {
            ...data.device,
            manufacturerId: data.manufacturerId,
            deviceType:
              data.device.type === DEVICE_TYPE.GATEWAY || data.device.type === DEVICE_TYPE.EQUIPMENT
                ? undefined
                : (data.device.type as TDeviceType),
          }
        : null,
      snoozeUntil: createDateString(data.snoozeUntil ? data.snoozeUntil : undefined),
      conditions: mapConditions({ thresholdMin, thresholdMax, deadbandMin, deadbandMax }),
      duration: mapDuration({ inactivityDuration, inactivityDurationTimeUnit }),
    };
  });

export type TAutomation = z.infer<typeof automationSchema>;

export const pagedAutomationSchema = z.object({
  data: z
    .array(automationSchema)
    .nullish()
    .transform((arr: TAutomation[] | null | undefined) => arr || []),
  pagination: z.object({
    totalItems: z.number(),
    page: z.number(),
    pageSize: z.number(),
  }),
});

export type TPagedAutomation = z.infer<typeof pagedAutomationSchema>;
