import { environment } from '@marlin/environment';
import { TCreateSystemMapNodeParams } from '@marlin/system-map/data-access/system-map';
import { TSourceSinkTag, TSystemTag } from '@marlin/system-map/shared/data-access-schemas';
import { Edge, XYPosition } from '@xyflow/react';
import { type MouseEvent as ReactMouseEvent, RefObject, useCallback, useMemo, useState } from 'react';

import { TSystemMapNode, TTeeGraphAsset } from '../types';
import { content } from './content';
import { IOption } from './context-menu/context-menu.component';
import { useAddNodeToTeeContextMenu } from './context-menu/hooks/use-add-node-to-tee-context-menu.hook';
import { useAddTeeContextMenu } from './context-menu/hooks/use-add-tee-context-menu.hook';
import { useDeleteLinkContextMenu } from './context-menu/hooks/use-delete-link-context-menu.hook';
import { useDeletePointContextMenu } from './context-menu/hooks/use-delete-point-context-menu.hook';
import { useDeleteTeeContextMenu } from './context-menu/hooks/use-delete-tee-context-menu.hook';
import { useRenameTeeContextMenu } from './context-menu/hooks/use-rename-tee-context-menu.hook';
import { useResetLinkContextMenu } from './context-menu/hooks/use-reset-link-context-menu.hook';
import { useSetSourceTagContextMenu } from './context-menu/hooks/use-set-source-tag-context-menu.hook';
import { useSetSystemTagContextMenu } from './context-menu/hooks/use-set-system-tag-context-menu.hook';
import { TSegmentIndex } from './edges/path/types';

interface IContextMenuProps {
  ref: RefObject<HTMLDivElement> | undefined;
  onAddNodeToTee: (tee: TCreateSystemMapNodeParams) => void;
  openRenameTeeModal: (teeToRename: TTeeGraphAsset) => void;
}

interface IMenuProps {
  id: string;
  top: number;
  left: number;
  options: IOption[];
}

interface IMenuCoords {
  x: number;
  y: number;
}

export const useContextMenu = ({ ref, onAddNodeToTee, openRenameTeeModal }: IContextMenuProps) => {
  const [menu, setMenu] = useState<IMenuProps | null>(null);
  const { deleteTee } = useDeleteTeeContextMenu();
  const { deleteLink } = useDeleteLinkContextMenu();
  const { createTee } = useAddTeeContextMenu();
  const { addNodeToTee } = useAddNodeToTeeContextMenu({ onAddNodeToTee });
  const { renameTee } = useRenameTeeContextMenu({
    openRenameTeeModal,
  });
  const { resetLink } = useResetLinkContextMenu();
  const { deletePoint, getPointContextMenu } = useDeletePointContextMenu();
  const { getSourceTagLabel, setSourceTag } = useSetSourceTagContextMenu();
  const { systemTagMenuState, handleSystemTagMenuOpen, handleSystemTagMenuClose, setSystemTag } =
    useSetSystemTagContextMenu();

  const onPaneClick = useCallback(() => {
    setMenu(null);
    handleSystemTagMenuClose();
  }, [setMenu, handleSystemTagMenuClose]);

  const handleAddFlowLink = useCallback(
    (id: string) => {
      addNodeToTee(id);
      onPaneClick();
    },
    [addNodeToTee, onPaneClick]
  );

  const handleRenameTee = useCallback(
    (teeId: string) => {
      renameTee(teeId);
      onPaneClick();
    },
    [onPaneClick, renameTee]
  );

  const handleDeleteTee = useCallback(
    (id: string) => {
      deleteTee(id);
      onPaneClick();
    },
    [deleteTee, onPaneClick]
  );

  const handleAddTee = useCallback(
    (id: string, coords: IMenuCoords) => {
      createTee(id, coords.x, coords.y);
      onPaneClick();
    },
    [createTee, onPaneClick]
  );

  const handleDeleteFlowLink = useCallback(
    (id: string) => {
      deleteLink(id);
      onPaneClick();
    },
    [deleteLink, onPaneClick]
  );

  const handleResetFlowLink = useCallback(
    (id: string) => {
      resetLink(id);
      onPaneClick();
    },
    [resetLink, onPaneClick]
  );

  const handleDeletePoint = useCallback(
    (id: string, segmentIndex: TSegmentIndex) => {
      deletePoint(id, segmentIndex);
      onPaneClick();
    },
    [deletePoint, onPaneClick]
  );

  const nodeMockedContextMenuOptions: IOption[] = useMemo(
    () => [
      {
        label: content.ADD_FLOW_LINK,
        onClick: (id: string) => handleAddFlowLink(id),
        divider: true,
      },
      {
        label: content.RENAME_TEE,
        onClick: (id: string) => handleRenameTee(id),
      },
      {
        label: content.DELETE_TEE,
        onClick: (id: string) => handleDeleteTee(id),
      },
    ],
    [handleAddFlowLink, handleDeleteTee, handleRenameTee]
  );

  const locationContextMenuOptionsFactory: (
    systemTag?: TSystemTag | null,
    sourceSinkTag?: TSourceSinkTag | null
  ) => IOption[] = useCallback(
    (systemTag?: TSystemTag | null, sourceSinkTag?: TSourceSinkTag | null) => [
      {
        label: getSourceTagLabel(sourceSinkTag),
        onClick: (nodeId: string) => setSourceTag(nodeId, onPaneClick),
        divider: true,
      },
      {
        label: content.SYSTEM_TAG.SET_TAG,
        onClick: (nodeId: string, event?: ReactMouseEvent<HTMLElement>) => {
          if (event) {
            handleSystemTagMenuOpen(nodeId, event);
          }
        },
        nested: true,
      },
    ],
    [getSourceTagLabel, handleSystemTagMenuOpen, onPaneClick, setSourceTag]
  );

  const devicesContextMenuOptionsFactory: (systemTag?: TSystemTag | null) => IOption[] = useCallback(
    (systemTag?: TSystemTag | null) => [
      {
        label: content.SYSTEM_TAG.SET_TAG,
        onClick: (nodeId: string, event?: ReactMouseEvent<HTMLElement>) => {
          if (event) {
            handleSystemTagMenuOpen(nodeId, event);
          }
        },
        nested: true,
      },
    ],
    [handleSystemTagMenuOpen]
  );

  const edgeMockedContextMenuOptions: (coords: IMenuCoords, hasPoints: boolean) => IOption[] = useCallback(
    ({ x, y }, hasPoints) =>
      [
        {
          label: content.ADD_TEE,
          onClick: (id: string) => handleAddTee(id, { x, y }),
          divider: true,
        },
        {
          label: content.DELETE_FLOW_LINK,
          onClick: (id: string) => handleDeleteFlowLink(id),
        },
        hasPoints && {
          label: content.RESET_FLOW_LINK,
          onClick: (id: string) => handleResetFlowLink(id),
        },
      ].filter(Boolean) as IOption[],
    [handleAddTee, handleDeleteFlowLink, handleResetFlowLink]
  );

  const pointMockedContextMenuOptions: (coords: IMenuCoords, segmentIndex: TSegmentIndex) => IOption[] = useCallback(
    ({ x, y }, segmentIndex) =>
      [
        {
          label: content.DELETE_POINT,
          onClick: (id: string) => handleDeletePoint(id, segmentIndex),
          divider: true,
        },
      ].filter(Boolean) as IOption[],
    [handleDeletePoint]
  );

  const onNodeContextMenu = useCallback(
    (event: ReactMouseEvent, node: TSystemMapNode) => {
      event.preventDefault();

      const pane = ref?.current?.getBoundingClientRect();

      if (pane && node.type === 'TEE') {
        setMenu({
          id: node.id,
          top: event.clientY,
          left: event.clientX,
          options: nodeMockedContextMenuOptions,
        });
      }

      if (environment.module.features.tagAsSource && pane && ['LOCATION'].includes(node.type || '')) {
        const [systemTag] = node.data.systemTags || [];
        const [sourceSinkTag] = node.data.sourceSinkTags || [];

        setMenu({
          id: node.id,
          top: event.clientY,
          left: event.clientX,
          options: locationContextMenuOptionsFactory(systemTag, sourceSinkTag),
        });
      }

      if (pane && ['DEVICE', 'EQUIPMENT'].includes(node.type || '')) {
        const [systemTag] = node.data.systemTags || [];

        setMenu({
          id: node.id,
          top: event.clientY,
          left: event.clientX,
          options: devicesContextMenuOptionsFactory(systemTag),
        });
      }
    },
    [ref, nodeMockedContextMenuOptions, locationContextMenuOptionsFactory, devicesContextMenuOptionsFactory]
  );

  const onEdgeContextMenu = useCallback(
    (event: ReactMouseEvent<Element, MouseEvent>, edge: Edge) => {
      event.preventDefault();

      const pane = ref?.current?.getBoundingClientRect();
      const hasPoints = !!edge.data?.points;
      if (hasPoints) {
        const { isPointContextMenu, segmentIndex } = getPointContextMenu(edge.data?.points as XYPosition[], {
          x: event.clientX,
          y: event.clientY,
        });
        if (isPointContextMenu && pane) {
          setMenu({
            id: edge.id,
            top: event.clientY,
            left: event.clientX,
            options: pointMockedContextMenuOptions({ x: event.clientX, y: event.clientY }, segmentIndex),
          });
          return;
        }
      }
      if (pane) {
        setMenu({
          id: edge.id,
          top: event.clientY,
          left: event.clientX,
          options: edgeMockedContextMenuOptions({ x: event.clientX, y: event.clientY }, hasPoints),
        });
      }
    },
    [ref, getPointContextMenu, pointMockedContextMenuOptions, edgeMockedContextMenuOptions]
  );

  return {
    onEdgeContextMenu,
    onNodeContextMenu,
    onPaneClick,
    menu,
    systemTagMenuState,
    setSystemTag,
  };
};
