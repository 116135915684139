import { MarlinTheme } from '@marlin/shared/theme';
import SignalCellularAltRoundedIcon from '@mui/icons-material/SignalCellularAltRounded';
import { makeStyles } from 'tss-react/mui';

import { SIGNAL_STRENGTH } from '../consts';
import { content } from '../content';
import { getSignalPerModel } from '../utils';

interface IStyles {
  signal: SIGNAL_STRENGTH;
}

const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { signal }) => ({
  cell: {
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    marginRight: theme.typography.pxToRem(6),
  },

  signal: {
    color: getColor(signal, theme),
  },
}));

export interface ISignalStrengthCellProps {
  signalStrength: number;
  gatewayModel: string;
  isOnline: boolean;
  connectionType?: string | null;
}

export const ConnectionStatus = ({
  signalStrength,
  gatewayModel,
  isOnline,
  connectionType,
}: ISignalStrengthCellProps) => {
  const signal = getSignalPerModel({ signalStrength, gatewayModel, isOnline, connectionType });
  const { classes, cx } = useStyles({ signal });
  const label = content.GATEWAY_SIGNAL.get(signal.toUpperCase());

  return (
    <div className={classes.cell}>
      <SignalCellularAltRoundedIcon className={cx(classes.icon, classes.signal)} />
      {label}
    </div>
  );
};

const getColor = (signal: SIGNAL_STRENGTH, theme: MarlinTheme) => {
  switch (signal) {
    case SIGNAL_STRENGTH.EXCELLENT:
      return theme.palette.success.light;
    case SIGNAL_STRENGTH.GOOD:
      return theme.palette.success.light;
    case SIGNAL_STRENGTH.FAIR:
      return theme.palette.warning.main;
    case SIGNAL_STRENGTH.POOR:
      return theme.palette.error.main;
    case SIGNAL_STRENGTH.NO_SIGNAL:
      return theme.palette.text.disabled;
    default:
      return theme.palette.error.main;
  }
};
