import {
  AssetTypeResponseSchema,
  AssetTypeSchemaParser,
  DeviceTypeResponseSchema,
  DeviceTypeSchemaParser,
} from '@marlin/system-map/shared/data-access-schemas';
import { z } from 'zod';

export enum FLOW_LINK_ERROR_CODES {
  LOOP_DETECTED = 200,
  EXISTING_LINK = 206,
  EXISTING_ATTACHMENT = 209,
  ATTACHED_LOCATION_HIERARCHY = 207,
  ATTACHED_EQUIPMENT_HIERARCHY = 212,
}

export const cycleNodeResponseSchema = z.object({
  AssetType: AssetTypeResponseSchema,
  DeviceType: DeviceTypeResponseSchema.nullable(),
  Id: z.string(),
  AssetId: z.string(),
  ParentId: z.string().nullish(),
  Name: z.string(),
});

export const cycleAssetSchema = cycleNodeResponseSchema.transform((cycleNodeResponse) => {
  return {
    id: cycleNodeResponse.AssetId,
    name: cycleNodeResponse.Name,
    type: AssetTypeSchemaParser.parse(cycleNodeResponse.AssetType),
    deviceType: DeviceTypeSchemaParser.parse(cycleNodeResponse.DeviceType) || null,
    parentId: cycleNodeResponse.ParentId,
  };
});

export type TCycleNodeResponse = z.infer<typeof cycleNodeResponseSchema>;
export type TCycleAsset = z.infer<typeof cycleAssetSchema>;

export interface IFlowLinkError {
  errorCode?: FLOW_LINK_ERROR_CODES;
  title?: string;
  cyclePath?: TCycleNodeResponse[];
  inletId?: string;
  outletId?: string;
}
