import { useRouter } from '@marlin/shared/utils-router';
import { EQUIPMENT_TAB_VALUES, TTabValues } from '@marlin/shared/utils-routes';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { ReactNode, useCallback } from 'react';

import { content } from './content';
import { Tab, Tabs } from './tabs.component';

interface IEquipmentDetailTabs {
  tabs: {
    [key in EQUIPMENT_TAB_VALUES]?: ReactNode;
  };
  getRoute: (id: string, tabId?: EQUIPMENT_TAB_VALUES) => string;
  currentTab: TTabValues;
}

export const DetailTabs = ({ tabs, getRoute, currentTab }: IEquipmentDetailTabs) => {
  const router = useRouter();
  const equipmentId = useIdFromPathname();

  const handleChange = useCallback(
    (_: unknown, newTab: EQUIPMENT_TAB_VALUES) => {
      if (currentTab !== newTab) {
        const newTabUrl = getRoute(equipmentId, newTab);
        router.goTo(newTabUrl);
      }
    },
    [equipmentId, router, currentTab, getRoute]
  );

  return (
    <Tabs value={currentTab} onChange={handleChange}>
      <Tab data-testid="dashboard-tab" label={content['dashboard']} value={EQUIPMENT_TAB_VALUES.DASHBOARD} />
      {Object.keys(tabs).map((key) => {
        const tabKey = key as EQUIPMENT_TAB_VALUES;

        return <Tab key={tabKey} data-testid={`${tabKey}-tab`} label={content[tabKey]} value={tabKey} />;
      })}
    </Tabs>
  );
};
