export const content = {
  DEFAULT_HOME_VIEW: 'Default Home View',
  SELECT_LAYOUT_SUBTITLE: 'Select which layout should be set as default home view.',
  DASHBOARD_LAYOUT: 'Dashboard layout',
  DASHBOARD_LAYOUT_SUBTITLE: 'Compact view presenting the health of your system at glance.',
  LOCATIONS_LAYOUT: 'Locations layout',
  LOCATIONS_LAYOUT_SUBTITLE: 'Locations view presenting the detailed view of alerts.',
  DEFAULT_HOME_VIEW_SUCCESS: 'Default Home View has been changed',
  DEFAULT_UOM_SUCCESS: 'Default Unit of Measurement has been changed',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  ACTIVE: 'Active',
  UNITS_OF_MEASUREMENT: 'Units of Measurement',
  TIME_FORMAT: 'Time Format',

  TEMPERATURE_CONTROL_LABEL: 'Temperature',
  WATER_CONTROL_LABEL: 'Water',
  PRESSURE_CONTROL_LABEL: 'Pressure',
  ENERGY_CONTROL_LABEL: 'Energy',
  GAS_CONTROL_LABEL: 'Gas',

  THEME: 'Theme',
  THEME_BRIGHT: 'Bright',
  THEME_DARK: 'Dark',
};
