import { DataLoader } from '@marlin/shared/ui-loader';
import { Paper } from '@marlin/shared/ui-page';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { formatTimezone } from '@marlin/shared/utils-common-date';
import { AddressToAddressLinesSchema, getAddressValidationService } from '@marlin/shared/utils/maps-api';
import { Grid } from '@mui/material';
import { useCallback } from 'react';

import { SectionSkeletonLoader } from '../components/section-skeleton-loader.component';
import { useEditMode } from '../hooks/use-edit-mode.hook';
import { useUpsertOrg } from '../hooks/use-upsert-org.hook';
import { content } from './content';
import { TAddressForm } from './form/address-form-data-schema';
import { UpsertAddress } from './form/upsert-address.component';
import { useStyles } from './organization-address.styles';
import { ReadOnlyAddress } from './read-only-address.component';

interface IOrganizationAddressProps {
  organizationId?: string;
  support?: boolean;
}

export function OrganizationAddress({ organizationId, support }: IOrganizationAddressProps) {
  const { classes } = useStyles();
  const { editMode, toggleEditMode } = useEditMode();
  const { enqueueSnackbar } = useSnackbar();
  const { updateOrganizationDetails, isOrganizationDetailsUpdating, data, isFetched } = useUpsertOrg({
    organizationId,
    support,
  });

  const getAddressCoordinates = useCallback(
    async (address: TAddressForm) =>
      getAddressValidationService()?.getAddressCoordinates({
        address: {
          addressLines: AddressToAddressLinesSchema.parse(address),
        },
      }),
    []
  );

  const submit = useCallback(
    async (address: TAddressForm) => {
      const coordinates = await getAddressCoordinates(address);

      return updateOrganizationDetails({
        ...(support && !!data ? data : {}),
        name: data?.name || '',
        address: { ...address, ...coordinates },
        timeZone: address.timeZone?.id ?? '',
        organizationId,
      })
        .then(() => {
          enqueueSnackbar(content.UPDATE_SUCCESS, { variant: 'success' });
          toggleEditMode();
        })
        .catch(() => {
          enqueueSnackbar(content.UPDATE_ERROR, { variant: 'error' });
        });
    },
    [getAddressCoordinates, updateOrganizationDetails, support, data, organizationId, enqueueSnackbar, toggleEditMode]
  );

  const defaultValues = data?.address ? { ...data.address, timeZone: formatTimezone(data.timeZone || '') } : undefined;

  if (isFetched && !editMode) {
    return <ReadOnlyAddress defaultValues={defaultValues} toggleEditMode={toggleEditMode} />;
  }

  return (
    <Paper data-testid="card">
      {isOrganizationDetailsUpdating ? (
        <SectionSkeletonLoader skeletonCount={5} />
      ) : (
        <>
          <Grid container columnSpacing={2} className={classes.section}>
            <Grid item className={classes.sectionTitle} data-testid="card-title">
              {content.ORGANIZATION_ADDRESS}
            </Grid>
          </Grid>
          <Grid container className={classes.section}>
            {!isFetched ? (
              <DataLoader />
            ) : (
              <UpsertAddress
                onSubmit={submit}
                disableSubmit={isOrganizationDetailsUpdating}
                defaultValues={defaultValues}
                editMode={editMode}
                toggleEditMode={toggleEditMode}
              />
            )}
          </Grid>
        </>
      )}
    </Paper>
  );
}
