import { smartBaseStationImage, theDetectionGroupLogo } from '@marlin/asset/shared/ui/assets';

import { content } from '../../content';
import { IGatewayDetailsConfig } from '../model';
import { SmartBaseStationDetailsConfig } from './details-config';

export const SmartBaseStationConfig: IGatewayDetailsConfig = {
  details: SmartBaseStationDetailsConfig,
  tabs: {},
  dashboard: {
    equipmentAndSensorsList: { show: false },
    productDetails: {
      datapoints: {
        connectionStatus: {
          display: true,
          // This is the datapoint name that is used to display the signal strength it's not the actual datapoint name. it's displayed based on the value of the connection type
          signalStrengthDatapointName: '',
        },
        connectionType: {
          display: true,
          connectionTypeDatapointName: 'Interface',
        },
        powerSource: {
          display: true,
          powerSourceDatapointName: 'PowerSource',
          batteryLevelDatapointName: 'BatteryLevelPercentage',
        },
      },
      modelName: content.SMART_BASE_STATION,
      logoSrc: theDetectionGroupLogo,
      imageSrc: smartBaseStationImage,
    },
    topAlerts: { show: true },
    displayGatewayMode: false,
  },
};
