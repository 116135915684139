import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { deleteInvitationsByStatus } from '../infrastructure/delete-invitations-by-status';
import { QUERY_KEY } from './query-key.enum';

export const useDeleteInvitationsByStatus = () => {
  return useMutation({
    mutationFn: deleteInvitationsByStatus,
    onSettled: () => {
      return queryClient.invalidateQueries({ queryKey: [QUERY_KEY.USER] });
    },
  });
};
