import { content } from './content';
import { getFormattedValue } from './datapoint-display/displayed-value-utils';
import { uomSettingsService } from './settings/uom-settings.service';

export interface ILeakReading {
  value: boolean | null;
  type: 'LEAK';
}

export interface ITemperatureReading {
  value: string | null;
  type: 'TEMPERATURE';
}

export interface IPressureReading {
  value: string | null;
  type: 'PRESSURE';
}

export interface IFlowMeterReading {
  value: string | null;
  type: 'PULSE_METER';
}

export interface IUnknownReading {
  value: null;
  type: 'UNKNOWN';
}

export type TKnownReading = ILeakReading | ITemperatureReading | IPressureReading | IFlowMeterReading;

const fallbackFormatter = (reading: TKnownReading | IUnknownReading) => {
  if (reading.value === null) {
    return content.SENSOR_READINGS_NOT_APPLICABLE;
  }

  switch (reading.type) {
    case 'TEMPERATURE': {
      return content.SENSOR_READINGS_TEMPERATURE(reading.value);
    }

    case 'PRESSURE': {
      return content.SENSOR_READINGS_PRESSURE(reading.value);
    }

    case 'PULSE_METER': {
      return content.SENSOR_READINGS_FLOW_METER(reading.value);
    }

    default: {
      return '';
    }
  }
};

/**
 * @deprecated formatReading - use parseDisplayedValue instead
 */
export const formatReading = (reading: TKnownReading | IUnknownReading) => {
  if (reading.value === null) {
    return content.SENSOR_READINGS_NOT_APPLICABLE;
  }

  if (reading.type === 'LEAK') {
    return reading.value ? content.SENSOR_READINGS_LEAK : content.SENSOR_READINGS_NO_LEAK;
  }

  const uomSettings = uomSettingsService.getUomSettings();

  if (!uomSettings) {
    return fallbackFormatter(reading);
  }

  switch (reading.type) {
    case 'TEMPERATURE': {
      return getFormattedValue(reading.value, uomSettings.temperatureUnit, 'detail');
    }

    case 'PRESSURE': {
      return getFormattedValue(reading.value, uomSettings.pressureUnit, 'detail');
    }

    case 'PULSE_METER': {
      return getFormattedValue(reading.value, uomSettings.waterFlowRateUnit, 'detail');
    }

    default: {
      return '';
    }
  }
};
