import { TExtractFnReturnType, UseQueryOptions, useQuery } from '@marlin/shared/utils/react-query';

import { getHomeDashboard } from '../infrastructure/home-dashboard';
import { useDashboardHubNotification } from './get-home-dashboard-notification.hook';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getHomeDashboard;
type TQueryOptions = Omit<UseQueryOptions<TExtractFnReturnType<TQueryFnType>>, 'queryFn' | 'queryKey'>;

export const useHomeDashboard = (queryProps: TQueryOptions = {}) => {
  useDashboardHubNotification();
  return useQuery<TExtractFnReturnType<TQueryFnType>, Error>({
    queryKey: queryKey.HOME_DASHBOARD(),
    queryFn: () => getHomeDashboard(),
    ...queryProps,
  });
};
