import { parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import { TDatapointWithMetadata } from '@marlin/shared/utils/datapoint-mappers';
import { z } from 'zod';

export const getDatapoint = (name: string, datapoints?: TDatapointWithMetadata[]) => {
  const datapoint = datapoints?.find((datapoint) => datapoint.name.toLowerCase() === name.toLowerCase());

  return datapoint ? parseDisplayedValue(datapoint.value, datapoint.unitOfMeasure) : '';
};

export function getDatapointWithSchema<T extends z.Schema>(
  name: string,
  datapoints?: TDatapointWithMetadata[],
  schema?: T
): z.infer<T> | '' {
  const datapoint = datapoints?.find((datapoint) => datapoint.name.toLowerCase() === name.toLowerCase());

  if (!schema || !datapoint) return '';

  const parsed = schema.safeParse(datapoint.value.toUpperCase());
  return parsed.success ? parseDisplayedValue(parsed.data, datapoint.unitOfMeasure) : '';
}
