import { areNodesOverlapping } from './are-nodes-overlapping';
import { findGroupsWithOverlappingNodes } from './detect-overlapping';
import { INode } from './types';
import { groupByParentId, groupNodesByParent } from './utils';

export const isNodeOverlappingOthersInTheGroup = <T extends INode>(node: T, nodes: T[]): boolean => {
  return nodes.some((otherNode) => {
    return areNodesOverlapping(node, otherNode);
  });
};

export const findAvailableSpaceForNode = <T extends INode>(
  node: T,
  nodes: T[],
  step: number,
  maxIterations = 100
): { x: number; y: number } => {
  const x = node.position.x;
  let y = node.position.y;
  let iterations = 0;

  while (isNodeOverlappingOthersInTheGroup({ ...node, position: { x, y } }, nodes) && iterations < maxIterations) {
    y += step;

    iterations++;
  }

  if (iterations >= maxIterations) {
    throw new Error('Could not find available space for node');
  }

  return { x, y };
};

interface IResolvedOverlapping<T extends INode> {
  updatedNodes: T[];
  newOverlappingNodesCount: number;
}

export const resolveOverlapping = <T extends INode>(
  currentNodes: T[],
  nodesWithoutSetPosition: string[],
  step: number
): IResolvedOverlapping<T> => {
  const groupedNodes = groupNodesByParent(currentNodes);
  const groupsWithOverlappingNodes = findGroupsWithOverlappingNodes(groupedNodes);
  const newOverlappingNodes = groupsWithOverlappingNodes.filter(([node]) => nodesWithoutSetPosition.includes(node.id));

  const updatedNodes = newOverlappingNodes.reduce((nodes: T[], overlappingNode) => {
    const [node, groupId] = overlappingNode;
    const nodesInGroup = groupByParentId(nodes, groupId);

    try {
      const position = findAvailableSpaceForNode(node, nodesInGroup, step);
      const nodeIndex = nodes.findIndex((n) => n.id === node.id);

      const newNodes = [...nodes];

      newNodes[nodeIndex] = {
        ...node,
        position,
      };

      return newNodes;
    } catch (e) {
      return nodes;
    }
  }, structuredClone(currentNodes));

  return {
    updatedNodes,
    newOverlappingNodesCount: newOverlappingNodes.length,
  };
};
