import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import { TInvitationResponse, invitationResponseSchema } from './invitations.schema';

export const deleteInvitation = async (invitationId: string): Promise<TInvitationResponse> => {
  const response = await getHttpClient().delete(paths.INVITATION_DETAILS, { params: { invitationId } });
  return safeParseData(response, invitationResponseSchema);
};
