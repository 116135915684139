import { FC, PropsWithChildren } from 'react';

import { QueryClient, QueryClientProvider } from './react-query-wrapper';

const testQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const createTestReactQueryWrapper = () => {
  const wrapper: FC<PropsWithChildren> = ({ children }) => (
    <QueryClientProvider client={testQueryClient}>{children}</QueryClientProvider>
  );
  return { wrapper, testQueryClient };
};
