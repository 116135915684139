import { IConfig } from '@marlin/asset/shared/equipment-config';
import { EQUIPMENT_TAB_VALUES } from '@marlin/shared/utils-routes';

import { tekmarSmartSteamConfig as sharedTekmarSmartSteamConfig } from '../../../../shared/config/tekmar-smart-steam/config';
import { HistoricalChart } from '../../../components/chart/historical-chart';
import { EquipmentSettings } from '../../../components/settings/equipment-settings.component';
import { BoilersAndPumps } from '../../components/boilers-and-pumps/boilers-and-pumps.component';
import { EventLog } from '../../components/event-log/event-log.component';
import { OverrideModeBanner } from '../override-mode-banner/override-mode-banner.component';

const tabs = {
  [EQUIPMENT_TAB_VALUES.EVENT_LOG]: <EventLog />,
  [EQUIPMENT_TAB_VALUES.HISTORICAL_CHART]: <HistoricalChart />,
  [EQUIPMENT_TAB_VALUES.BOILERS]: <BoilersAndPumps />,
  [EQUIPMENT_TAB_VALUES.SETTINGS]: <EquipmentSettings />,
};

export const tekmarSmartSteamConfig: IConfig = {
  ...sharedTekmarSmartSteamConfig,
  headerBanner: <OverrideModeBanner />,
  tabs,
};
