export const content = {
  ADD_FLOW_LINK: 'Add flow link',
  RENAME_TEE: 'Rename tee',
  DELETE_TEE: 'Delete tee',
  ADD_TEE: 'Add tee',
  DELETE_FLOW_LINK: 'Delete flow link',
  RESET_FLOW_LINK: 'Reset flow link',
  DELETE_POINT: 'Delete point',
  SYSTEM_SOURCE_TAG: {
    SET_TAG: 'Set as water source',
    REMOVE_TAG: 'Remove water source',
    SET_TAG_CONFIRMATION_TITLE: 'Change Water Source?',
    SET_TAG_CONFIRMATION_MESSAGE: (previousName: string, currentName: string) =>
      `Change water source from ${previousName} to ${currentName}?`,
    SET_TAG_SUCCESS_MESSAGE: 'Water source has been set.',
    REMOVE_TAG_SUCCESS_MESSAGE: 'Water source has been removed.',
    SET_TAG_ERROR_MESSAGE: 'Something went wrong. Water source has not been set.',
    REMOVE_TAG_ERROR_MESSAGE: 'Something went wrong. Water source has not been removed.',
    YES_BUTTON_LABEL: 'Yes',
    CANCEL_BUTTON_LABEL: 'Cancel',
  },
  SYSTEM_TAG: {
    WATER_SOURCE_NODE_NAME: (name: string) => `${name} (Water Source)`,
    SET_TAG: 'Tags',
    TAGS: {
      DOMESTIC_COLD_WATER: 'Domestic Cold Water',
      DOMESTIC_HOT_WATER: 'Domestic Hot Water',
      HEATING_HOT_WATER: 'Heating Hot Water',
    },
    SET_TAG_SUCCESS_MESSAGE: 'Tag has been set.',
    REMOVE_TAG_SUCCESS_MESSAGE: 'Tag has been removed.',
    SET_TAG_ERROR_MESSAGE: 'Something went wrong. Tag has not been set.',
    REMOVE_TAG_ERROR_MESSAGE: 'Something went wrong. Tag has not been removed.',
  },
  EQUIPMENT: {
    PERFORMANCE: {
      label: 'Performance',
      success: 'All Operational',
      warning: 'Open Alerts',
      error: 'Open Alerts',
    },
    HEALTH: {
      label: 'Health',
      success: 'All Operational',
      warning: 'Perform System Maintenance',
      error: 'Check Communication & Errors',
    },
    SANITIZATION_IN_PROGRESS: 'Sanitization in progress',
    SETPOINT: 'Setpoint',
    TIME_REMAINING: 'Time remaining',
    START_UP_IN_PROGRESS: 'Start up in progress',
    BURNER: {
      LABEL: 'Burner',
      ON: 'On',
      OFF: 'Off',
    },
    NOT_APPLICABLE: 'N/A',
  },
};
