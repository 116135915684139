import { VALIDATE_INVITATION_ERROR, useValidateSupportInvitation } from '@marlin/account-data-access-invitation';
import { useCreateSupportProfile } from '@marlin/account-data-access-organization';
import { DataLoader } from '@marlin/shared/ui-loader';
import { routesSupport } from '@marlin/shared/utils-routes';
import { Navigate, useParams } from 'react-router-dom';

import { InvalidUserEmail } from './invitation-not-accepted/invalid-user-email.component';
import { InvitationExpired } from './invitation-not-accepted/invitation-expired.component';
import { InvitationNotAccepted } from './invitation-not-accepted/invitation-not-accepted.component';
import { UpdateUserProfile } from './user-profile-update.component';

export const ValidateSupportInvitation = () => {
  const { invitationId } = useParams();

  const { status, data, error } = useValidateSupportInvitation({ invitationId: invitationId ?? '' });
  const { status: createProfileStatus, mutateAsync } = useCreateSupportProfile();

  switch (status) {
    case 'pending': {
      return <DataLoader />;
    }

    case 'error': {
      switch (error?.response?.data?.errorCode) {
        case VALIDATE_INVITATION_ERROR.INVITATION_ALREADY_ACCEPTED: {
          return <Navigate to={routesSupport.home.root} />;
        }

        case VALIDATE_INVITATION_ERROR.INVITATION_EXPIRED: {
          return <InvitationExpired organizationName={error?.response?.data?.OrganizationName} />;
        }

        case VALIDATE_INVITATION_ERROR.INVALID_EMAIL_ADDRESS: {
          return <InvalidUserEmail />;
        }

        default: {
          return <InvitationNotAccepted />;
        }
      }
    }

    case 'success': {
      return (
        <UpdateUserProfile
          user={data}
          redirectUrl={routesSupport.home.root}
          mutateAsync={mutateAsync}
          status={createProfileStatus}
          isSupport
        />
      );
    }

    default: {
      return null;
    }
  }
};
