import { EQUIPMENT_TAB_VALUES, TTabValues, TabValuesSchema } from '@marlin/shared/utils-routes';
import { useQueryParameters } from '@marlin/shared/utils/url-params';
import { useMediaQuery, useTheme } from '@mui/material';

const tabUrlQueryParam = 'tab';
const mobileUnavailableTabs: TTabValues[] = [EQUIPMENT_TAB_VALUES.HISTORICAL_CHART];

const mapParam = <TValues extends TTabValues>(
  param: string | null,
  isMobile: boolean,
  defaultValue: TValues
): TValues => {
  const parsedParam = TabValuesSchema.safeParse(param);
  const shouldDisplayDefaultTab =
    !parsedParam.success || (mobileUnavailableTabs.includes(parsedParam.data) && isMobile);
  return shouldDisplayDefaultTab ? defaultValue : (parsedParam.data as TValues);
};

export function useTab<TValues extends TTabValues>(defaultValue: TValues): TValues {
  const queryParameters = useQueryParameters();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return mapParam<TValues>(queryParameters.get(tabUrlQueryParam), isMobile, defaultValue);
}
