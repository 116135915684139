import { IconEquipment } from '@marlin/asset/shared/ui/assets';
import { useRouter } from '@marlin/asset/shared/ui/equipment-list';
import { MarlinTheme } from '@marlin/shared/theme';
import { PageHeader } from '@marlin/shared/ui-page';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  button: {
    width: theme.typography.pxToRem(123),
  },
}));

const EquipmentHubHeader = () => {
  const { goToNewEquipmentPage } = useRouter();
  const { classes } = useStyles();
  return (
    <PageHeader
      prefix="equipment-hub-header"
      title={content.EQUIPMENT_HUB_TITLE}
      subtitle={content.EQUIPMENT_HUB_SUBTITLE}
      icon={<IconEquipment.Other />}
      actions={
        <Restricted to={PERMISSIONS.CREATE_NEW_EQUIPMENT}>
          <Button
            id="equipment-hub-button"
            variant="contained"
            onClick={goToNewEquipmentPage}
            className={classes.button}
          >
            {content.CREATE_NEW_BUTTON}
          </Button>
        </Restricted>
      }
    />
  );
};

export { EquipmentHubHeader };
