import { createContext, useContext } from '@marlin/shared/utils-component-context';
import { PropsWithChildren } from 'react';

import { IGatewayDetailsConfig } from '../config/model';

interface IGatewayDetailsConfigContext {
  config: IGatewayDetailsConfig;
}

interface IGatewayDetailsConfigContextProviderProps extends PropsWithChildren<IGatewayDetailsConfigContext> {}

export const GatewayDetailsConfigContext = createContext<IGatewayDetailsConfigContext>();

export const GatewayDetailsConfigContextProvider = ({
  config,
  children,
}: IGatewayDetailsConfigContextProviderProps) => {
  return <GatewayDetailsConfigContext.Provider value={{ config }}>{children}</GatewayDetailsConfigContext.Provider>;
};

export const useGatewayDetailsConfigContext = () => useContext(GatewayDetailsConfigContext);
