import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getLatestSavedCalibration } from '../infrastructure/calibration';
import { queryKey } from './query-key.enum';

export const useLatestSavedCalibration = ({ manufacturerId }: { manufacturerId: string }) => {
  return useQuery<TExtractFnReturnType<typeof getLatestSavedCalibration>>({
    queryKey: queryKey.CALIBRATIONS({ type: 'saved', manufacturerId }),
    queryFn: () =>
      getLatestSavedCalibration({
        completed: false,
        manufacturerIds: [manufacturerId],
        pageSize: 1,
        page: 1,
        sorting: {
          direction: 'Descending',
          sortBy: 'CalibrationTimestamp',
        },
      }),
  });
};

export const useLatestProcessedCalibration = ({ manufacturerId }: { manufacturerId: string }) => {
  return useQuery<TExtractFnReturnType<typeof getLatestSavedCalibration>>({
    queryKey: queryKey.CALIBRATIONS({ type: 'processed', manufacturerId }),
    queryFn: () =>
      getLatestSavedCalibration({
        completed: true,
        manufacturerIds: [manufacturerId],
        pageSize: 1,
        page: 1,
        sorting: {
          direction: 'Descending',
          sortBy: 'CalibrationTimestamp',
        },
      }),
  });
};
