export const content = {
  MESSAGE_CONFIGURATION: 'Message Configuration',
  DATA_CONFIGURATION: 'Data Configuration',
  WEBHOOK_KEYS: 'Webhook Keys',
  WEBHOOK_NAME_LABEL: 'Name',
  WEBHOOK_MANAGER_EMAIL_LABEL: 'Webhook Manager',
  WEBHOOK_MANAGER_EMAIL_PLACEHOLDER: 'manager@mail.com',
  WEBHOOK_URL_LABEL: 'Webhook URL',
  WEBHOOK_URL_PLACEHOLDER: 'https://',
  FREQUENCY_LABEL: 'Frequency',
  FREQUENCY_OPTIONS: {
    ONE_HOUR: '1 hour',
    FOUR_HOUR: '4 hours',
    EIGHT_HOUR: '8 hours',
    ONE_DAY: '1 day',
    ONE_WEEK: '1 week',
    REAL_TIME: 'Real Time',
  },
  API_KEY_LABELS: {
    ADD_KEY_BUTTON: 'Add key',
    ADD_KEY_BUTTON_TOOLTIP: 'Add additional Key-Value pair to the webhook header',
    HEADER_KEY: (order: number) => `Header Key ${order}`,
    HEADER_VALUE: (order: number) => `Header Key ${order} Value`,
  },
  WEBHOOK_TYPE_LABEL: 'Type',
  WEBHOOK_TYPE_OPTIONS: {
    TOTAL_ORGANIZATION_VOLUME: 'Total Organization Volume',
    ALL_INDIVIDUAL_FLOW_METERS: 'All Individual Flow Meters',
    ALL_INDIVIDUAL_PARTITIONS: 'All Individual Partitions',
  },
  SELECTED_FLOW_METERS_LABEL: 'Selected Flow Meter(s)',
  SELECTED_DEVICES_LABEL: 'Selected Device(s)',
  SUBMIT_BUTTON: 'Submit',
  CANCEL_BUTTON: 'Cancel',
  URL_VALIDATION_ERROR: 'Please enter a valid URL',
};
