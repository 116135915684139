import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getAutomationsHistory } from '../infrastructure/automation';
import { TAutomationHistoryParams } from '../schemas/automation-history/automation-history.schema';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getAutomationsHistory;

export interface IAutomationHistoryQueryParams extends TAutomationHistoryParams {
  enabled?: boolean;
  keepPreviousData?: boolean;
}

export const useAutomationHistory = ({ enabled, keepPreviousData, ...params }: IAutomationHistoryQueryParams) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.AUTOMATION_HISTORY(params),
    queryFn: () => getAutomationsHistory(params),
    enabled,
    placeholderData: keepPreviousData ? (previousData, previousQuery) => previousData : undefined,
  });
};
