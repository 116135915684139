import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { useGetTiers } from '@marlin/account-data-access-organization';
import { FormField, Input, Select } from '@marlin/shared/ui-form-common';
import { Paper, ReadOnlySection } from '@marlin/shared/ui-page';
import { TierChip } from '@marlin/shared/ui/chips';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { Button, Typography } from '@mui/material';
import { useCallback, useContext, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { SectionSkeletonLoader } from '../components/section-skeleton-loader.component';
import { useEditMode } from '../hooks/use-edit-mode.hook';
import { useUpsertOrg } from '../hooks/use-upsert-org.hook';
import { ChangeOrganizationNameFormFieldsSchema, TChangeOrganizationNameForm } from './change-org-name.schema';
import { useStyles } from './change-org-name.styles';
import { Body, Footer, Title } from './confirmation-modal';
import { content } from './content';

interface IChangeOrgNameControlProps {
  organizationId?: string;
  support?: boolean;
}

export const ChangeOrgNameControl = ({ organizationId, support }: IChangeOrgNameControlProps) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { editMode, toggleEditMode } = useEditMode();
  const { updateOrganizationDetails, data, isOrganizationDetailsUpdating } = useUpsertOrg({
    organizationId,
    support,
  });

  const { isLoading, data: tierData } = useGetTiers({
    search: '',
    page: 0,
    pageSize: 10,
  });

  const { modalDispatch } = useContext(ModalContext);

  const form = useForm<TChangeOrganizationNameForm>({
    mode: 'onChange',
    resolver: zodResolver(ChangeOrganizationNameFormFieldsSchema),
    defaultValues: {
      organizationName: '',
    },
    values: {
      organizationName: data?.name || '',
      tierId: data?.tierId || '',
    },
  });

  const selectedTierId = form.watch('tierId');

  const tierOptions = useMemo(() => {
    if (isLoading || !tierData?.data) return [];
    return tierData?.data.map((tier) => ({ name: tier.tierName, id: tier.id })) ?? [];
  }, [isLoading, tierData?.data]);

  const newTierName = useMemo(() => {
    const selectedTierOption = tierOptions.find(({ id }) => id === selectedTierId);

    return selectedTierOption?.name || '';
  }, [selectedTierId, tierOptions]);

  const submit = useCallback(
    async (orgName: string, tierId?: string | null) =>
      updateOrganizationDetails({
        ...(support && !!data ? data : {}),
        name: orgName,
        organizationId,
        ...(support && !!tierId ? { tierId } : {}),
      })
        .then(() => {
          enqueueSnackbar(content.UPDATE_SUCCESS, { variant: 'success' });
        })
        .catch(() => {
          form.reset();
          enqueueSnackbar(content.UPDATE_ERROR, { variant: 'error' });
        }),
    [data, updateOrganizationDetails, organizationId, support, enqueueSnackbar, form]
  );

  const onSubmit = (data: TChangeOrganizationNameForm) => {
    submit(data.organizationName, data.tierId);
    modalDispatch({ type: MODAL_ACTION_TYPE.DISMISS });
    toggleEditMode();
  };

  const cancelEditMode = () => {
    toggleEditMode();
    modalDispatch({ type: MODAL_ACTION_TYPE.DISMISS });
    form.reset();
  };

  const openModal = () => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: {
        title: <Title />,
        body: (
          <Body
            currentOrgName={data?.name || ''}
            newOrgName={form.getValues().organizationName}
            currentTierName={data?.tierName || ''}
            newTierName={newTierName}
            support={support}
          />
        ),
        footer: <Footer onCancel={cancelEditMode} onSubmit={form.handleSubmit(onSubmit)} />,
      },
    });
  };

  return (
    <Paper data-testid="card-change-org-control" className={classes.container}>
      {isOrganizationDetailsUpdating ? (
        <SectionSkeletonLoader skeletonCount={5} />
      ) : (
        <>
          <Typography className={classes.orgNameText}>{content.ORGANIZATION_DETAILS}</Typography>
          {editMode ? (
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className={classes.inputsWrapper}>
                <FormField<TChangeOrganizationNameForm> fieldName="organizationName">
                  {(props) => <Input label={content.ORGANIZATION_NAME} {...props} required disabled={!editMode} />}
                </FormField>
                {support && (
                  <FormField<TChangeOrganizationNameForm> fieldName="tierId">
                    {(props) => (
                      <Select
                        {...props}
                        prefix="tier"
                        label={content.TIER}
                        data={tierOptions}
                        onChange={(e) => {
                          props.onChange && props.onChange(e.target.value);
                          form.trigger('tierId');
                        }}
                        required
                      />
                    )}
                  </FormField>
                )}
                <div className={classes.editModeButtons}>
                  <Button variant="outlined" onClick={cancelEditMode} data-testid="change-org-control-cancel">
                    {content.CANCEL}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={openModal}
                    disabled={!form.formState.isDirty || !form.formState.isValid}
                    data-testid="change-org-control-submit"
                  >
                    {content.UPDATE}
                  </Button>
                </div>
              </form>
            </FormProvider>
          ) : (
            <ReadOnlySection onEditClick={toggleEditMode} testid="organization-name">
              <div data-testid="organization-settings-readonly-organization-name" className={classes.readOnly}>
                {data?.name} {data?.tierName && support && <TierChip tierName={data?.tierName} size={'small'} />}
              </div>
            </ReadOnlySection>
          )}
        </>
      )}
    </Paper>
  );
};
