export const content = {
  HIGH_SENSORS: 'Inactive Gateways',
  LOW_SENSORS: 'Inactive Sensor',
  GATEWAYS: 'Gateways Operational',
  SENSORS: 'Sensors Operational',
  BATTERIES_GOOD: 'Batteries Good',
  BATTERIES_LOW: 'Batteries Low',
  BATTERIES_CRITICAL: 'Batteries Critical',
  ERRORS: 'Errors',
  INACTIVE: 'Lost Comm',
  ALL_ACTIVE: 'All Active',
  EQUIPMENT_ICON: (equipmentCount: string) => `${equipmentCount} Equipment`,
  SENSORS_ICON: (equipmentCount: string) => `${equipmentCount} Sensors`,
  GATEWAYS_ICON: (equipmentCount: string) => `${equipmentCount} Gateways`,
};
