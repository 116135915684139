import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { avatarUrl, firstMessage } from '../constants';
import { chat } from '../infrastracture/chat';
import { TChatMessage, TChatParams } from '../schemas/chat.schema';
import { queryKey } from './query-key.enum';

export const useChat = () => {
  return useMutation({
    mutationFn: (params: TChatParams) => chat(params),
    onMutate: async (params: TChatParams) => {
      await queryClient.cancelQueries({ queryKey: queryKey.CHAT(params.conversationId), exact: false });

      const previousData = queryClient.getQueryData<TChatMessage[]>(queryKey.CHAT(params.conversationId));

      queryClient.setQueriesData<TChatMessage[]>(
        { queryKey: queryKey.CHAT(params.conversationId) },
        (data?: TChatMessage[]) => {
          if (!data) {
            return data;
          }

          return [
            ...data,
            {
              from: 'me',
              message: params.userMessage,
              avatarUrl,
              timestamp: new Date().toISOString(),
            },
          ];
        }
      );

      return { previousData, previousConversationId: params.conversationId };
    },
    onSuccess: (data, conversationId, context) => {
      if (context?.previousConversationId !== data.conversationId) {
        queryClient.setQueryData<TChatMessage[]>(
          queryKey.CHAT(context?.previousConversationId || null),
          context?.previousData
        );
      }

      queryClient.setQueryData<TChatMessage[]>(queryKey.CHAT(data.conversationId), [firstMessage, ...data.messages]);
    },
    onError: (data, conversationId, context) => {
      queryClient.invalidateQueries({ queryKey: queryKey.CHAT(context?.previousConversationId || null) });
    },
  });
};
