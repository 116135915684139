import type { IConfig } from '@marlin/asset/shared/equipment-config';
import { EQUIPMENT_TAB_VALUES } from '@marlin/shared/utils-routes';
import { SETTINGS_GROUP } from '@marlin/shared/utils/datapoint-mappers';

import { intellistationConfig as globalIntellistationConfig } from '../../../../shared/config/intellistation/config';
import { HistoricalChart } from '../../../components/chart/historical-chart';
import { EquipmentSettings } from '../../../components/settings/equipment-settings.component';
import { EventLog } from '../../components/event-log/event-log.component';
import { OverrideModeBanner } from '../override-mode-banner/override-mode-banner.component';
import { IntellistationScheduleHeaderTooltip } from './schedule-header-tooltip.component';

export const intellistationConfig: IConfig = {
  ...globalIntellistationConfig,
  headerBanner: <OverrideModeBanner />,
  tabs: {
    [EQUIPMENT_TAB_VALUES.EVENT_LOG]: <EventLog />,
    [EQUIPMENT_TAB_VALUES.SETTINGS]: <EquipmentSettings />,
    [EQUIPMENT_TAB_VALUES.HISTORICAL_CHART]: <HistoricalChart />,
  },
  settings: {
    settingGroups: globalIntellistationConfig.settings?.settingGroups ?? [],
    schedule: {
      scheduleTempGroup: SETTINGS_GROUP.TEMPERATURE,
      scheduleDatapoints: ['tempSetpointTarget', 'tempSetbackDiff'],
      headerTooltip: <IntellistationScheduleHeaderTooltip />,
      tempGroupReadonly: true,
    },
  },
};
