import { TCalibrationParamsSchema, TSubtypesType } from '@marlin/asset/data-access/device';
import { FormField, Input, SingleDatePicker, ToggleButtons } from '@marlin/shared/ui-form-common';
import { detailedDateTimeForPickers, formatTimezone } from '@marlin/shared/utils-common-date';
import { getUomSymbol } from '@marlin/shared/utils-format-reading';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Box, FormHelperText, Typography } from '@mui/material';
import { useMemo } from 'react';
import { UseFormReturn, useFormContext } from 'react-hook-form';

import { content } from '../../content';
import { subtitle } from '../types';
import { useStyles } from './forms.styles';
import { TUomType, uomGasToggleOptions, uomWaterToggleOptions } from './uom-toggle-options';
import { useNotEnoughTime } from './use-not-enough-time.hook';

interface IMeterReadingsFormProps {
  form: UseFormReturn<TCalibrationParamsSchema>;
  timezoneName: string;
  meterType: TSubtypesType;
}

export const MeterReadingsForm = ({ form, timezoneName, meterType }: IMeterReadingsFormProps) => {
  const { classes } = useStyles();
  const timezone = useMemo(() => formatTimezone(timezoneName), [timezoneName]);
  const { watch } = useFormContext<TCalibrationParamsSchema>();
  const uomOption = watch('measurementUnit') as TUomType;
  const uomSubtitle = useMemo(() => subtitle(uomOption), [uomOption]);
  const endAdornment = useMemo(
    () => <span className={classes.endAdornment}>{getUomSymbol(uomOption)}</span>,
    [classes.endAdornment, uomOption]
  );
  const timeWarning = useNotEnoughTime();

  return (
    <Box className={classes.container}>
      <Typography className={classes.text}>{content.CALIBRATION_MODAL.MODAL_BODY}</Typography>
      <div className={classes.inputSection}>
        <Typography className={classes.text}>{content.UNITS_OF_MEASUREMENT_LABEL}</Typography>
        <div>
          <FormField<TCalibrationParamsSchema> control={form.control} fieldName="measurementUnit">
            {(props) => (
              <ToggleButtons<TUomType>
                options={meterType === 'Water' ? uomWaterToggleOptions : uomGasToggleOptions}
                value={props.value as TUomType}
                onChange={(_, value) => {
                  if (value !== null) {
                    props.onChange && props.onChange(_, value);
                  }
                }}
              />
            )}
          </FormField>
          <Typography className={classes.subTitleText}>{uomSubtitle}</Typography>
        </div>
      </div>
      <div className={classes.timeInputs}>
        <div className={classes.inputSection}>
          {content.CALIBRATION_MODAL.START_READING}
          <div className={classes.inputRow}>
            <FormField<TCalibrationParamsSchema> control={form.control} fieldName="startMeasurementTimestamp">
              {(props) => (
                <SingleDatePicker
                  required
                  className={classes.input}
                  variant="datetime"
                  label={content.CALIBRATION_MODAL.START_DATE_AND_TIME}
                  helperText={content.CALIBRATION_MODAL.ORGANIZATION_TIME_ZONE(timezone?.name)}
                  timezone={timezone?.id}
                  format={detailedDateTimeForPickers}
                  views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                  {...props}
                  onChange={(date) => {
                    props.onChange && props.onChange(date);
                    form.trigger('startMeasurementTimestamp');
                    form.trigger('endMeasurementTimestamp');
                  }}
                />
              )}
            </FormField>
            <FormField<TCalibrationParamsSchema> control={form.control} fieldName="startMeasurementValue">
              {(props) => (
                <Input
                  className={classes.input}
                  label={content.CALIBRATION_MODAL.METER_READING}
                  placeholder={content.CALIBRATION_MODAL.METER_READING}
                  required
                  testId={'startMeasurementValue'}
                  {...props}
                  onChange={(date) => {
                    props.onChange && props.onChange(date);
                    form.trigger('startMeasurementValue');
                    form.trigger('endMeasurementValue');
                  }}
                  externalEndAdornment={{ endAdornment }}
                />
              )}
            </FormField>
          </div>
        </div>
        <div className={classes.inputSection}>
          {content.CALIBRATION_MODAL.STOP_READING}
          <div className={classes.inputRow}>
            <FormField<TCalibrationParamsSchema> control={form.control} fieldName="endMeasurementTimestamp">
              {(props) => (
                <SingleDatePicker
                  required
                  className={classes.input}
                  variant="datetime"
                  label={content.CALIBRATION_MODAL.STOP_DATE_AND_TIME}
                  helperText={content.CALIBRATION_MODAL.ORGANIZATION_TIME_ZONE(timezone?.name)}
                  timezone={timezone?.id}
                  format={detailedDateTimeForPickers}
                  {...props}
                  onChange={(date) => {
                    props.onChange && props.onChange(date);
                    form.trigger('startMeasurementTimestamp');
                    form.trigger('endMeasurementTimestamp');
                  }}
                />
              )}
            </FormField>
            <FormField<TCalibrationParamsSchema> control={form.control} fieldName="endMeasurementValue">
              {(props) => (
                <Input
                  className={classes.input}
                  label={content.CALIBRATION_MODAL.METER_READING}
                  placeholder={content.CALIBRATION_MODAL.METER_READING}
                  required
                  testId={'endMeasurementValue'}
                  {...props}
                  onChange={(date) => {
                    props.onChange && props.onChange(date);
                    form.trigger('startMeasurementValue');
                    form.trigger('endMeasurementValue');
                  }}
                  externalEndAdornment={{ endAdornment }}
                />
              )}
            </FormField>
          </div>
        </div>
      </div>
      {timeWarning && (
        <div className={classes.warningRow}>
          <WarningRoundedIcon color="warning" className={classes.warningIcon} />
          <FormHelperText data-testid="time-warning">{content.CALIBRATION_MODAL.ERRORS.NOT_ENOUGH_TIME}</FormHelperText>
        </div>
      )}
    </Box>
  );
};
