import { TCalibrationParamsSchema, TSubtypesType } from '@marlin/asset/data-access/device';
import { UseFormReturn } from 'react-hook-form';

import { content } from '../../content';
import { ManualForm } from './manual-form.component';
import { MeterReadingsForm } from './meter-readings-form.component';

export type TTabKey = 'METER_READINGS' | 'MANUAL';

export interface ITab {
  key: TTabKey;
  label: string;
  component: (
    form: UseFormReturn<TCalibrationParamsSchema>,
    meterType: TSubtypesType,
    timezoneName: string
  ) => JSX.Element;
}

type TTabConfig = {
  [key in TTabKey]: ITab;
};

export const tabsConfig: TTabConfig = {
  METER_READINGS: {
    key: 'METER_READINGS',
    label: content.CALIBRATION_MODAL.READINGS_MULTIPLIER,
    component: (form: UseFormReturn<TCalibrationParamsSchema>, meterType: TSubtypesType, timezoneName: string) => (
      <MeterReadingsForm form={form} timezoneName={timezoneName} meterType={meterType} />
    ),
  },
  MANUAL: {
    key: 'MANUAL',
    label: content.CALIBRATION_MODAL.MANUAL_MULTIPLIER,
    component: (form: UseFormReturn<TCalibrationParamsSchema>, meterType: TSubtypesType) => (
      <ManualForm form={form} meterType={meterType} />
    ),
  },
};
