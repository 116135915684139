import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

const wholeFlexContainer = {
  width: '100%',
  height: '100%',
  display: 'flex',
};

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  sankeyWrapper: {
    ...wholeFlexContainer,

    'div.svg-container': wholeFlexContainer,

    'div.plot-container': wholeFlexContainer,

    '.sankey-node > .node-label': {
      textShadow: 'none!important',
      textAnchor: 'start',
      transform: 'translate(36px, 12px)',
    },
    '.sankey-node > .node-rect': {
      stroke: 'none !important',
    },
  },
  plot: {
    width: '100%',
    height: '100%',
  },
}));
