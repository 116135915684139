import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getHomeLocations } from '../infrastructure/home-locations';
import { THomeLocationsParamsSchema } from '../infrastructure/schema/home-locations.schema';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getHomeLocations;

export const useHomeLocations = (params: THomeLocationsParamsSchema) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.HOME_LOCATIONS(params),
    queryFn: () => getHomeLocations(params),
    placeholderData: (previousData, previousQuery) => previousData,
  });
};
