import { ILocation, useLocation, useUpdateLocation } from '@marlin/asset/data-access/location';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { routes } from '@marlin/shared/utils-routes';
import { useCallback, useMemo } from 'react';
import { useLocation as useRouterLocation, useNavigate } from 'react-router-dom';

import { content } from './content';

type TLocationState = {
  prevLocation: string;
};

export interface IEditLocation {
  isLoading: boolean;
  successfullyUpdatedLocation: boolean;
  loadingUpdatedLocation: boolean;
  nextRoute: string;
  location: ILocation;
  onSubmit: (data: ILocation) => void;
  goToNextRoute: () => void;
  isError: boolean;
}

export const useEditLocation = (): IEditLocation => {
  const { enqueueSnackbar } = useSnackbar();
  const onSuccess = () => {
    enqueueSnackbar(content.CONFIRMATION_MESSAGE_UPDATE_LOCATION_SUCCESS, {
      variant: 'success',
      preventDuplicate: true,
    });
  };
  const onError = () => {
    enqueueSnackbar(content.CONFIRMATION_MESSAGE_UPDATE_LOCATION_ERROR, { variant: 'error', preventDuplicate: true });
  };

  const navigate = useNavigate();
  const { pathname, state } = useRouterLocation();
  const locationState = state as TLocationState;
  const nextRoute = locationState?.prevLocation || routes.locations.list.url();
  const updateLocationMutation = useUpdateLocation({ onSuccess, onError });
  const locationId = pathname.substring(pathname.lastIndexOf('/') + 1);
  const locationQuery = useLocation({ locationId });

  const location = useMemo(() => locationQuery?.data as unknown as ILocation, [locationQuery?.data]); // TODO: fix types on location form
  const onSubmit = ({ parentLocation, ...data }: ILocation) => {
    return updateLocationMutation.mutateAsync({
      data: { ...data, parentLocationId: parentLocation?.id?.length ? parentLocation?.id : undefined },
      locationId,
    });
  };

  const goToNextRoute = useCallback(() => {
    navigate(nextRoute);
  }, [navigate, nextRoute]);

  const isLoading = useMemo(
    () => locationQuery.isLoading || locationQuery.isInitialLoading,
    [locationQuery.isInitialLoading, locationQuery.isLoading]
  );

  const isError = useMemo(() => locationQuery.isError, [locationQuery.isError]);

  const successfullyUpdatedLocation = useMemo(
    () => updateLocationMutation.isSuccess,
    [updateLocationMutation.isSuccess]
  );
  const loadingUpdatedLocation = useMemo(() => updateLocationMutation.isPending, [updateLocationMutation.isPending]);

  return {
    isLoading,
    successfullyUpdatedLocation,
    loadingUpdatedLocation,
    nextRoute,
    goToNextRoute,
    location,
    onSubmit,
    isError,
  };
};
