import { TWebhook, useIsPartition } from '@marlin/account-data-access-webhook';
import { CardHeader } from '@marlin/asset/shared/ui/hub-card';
import { MarlinTheme } from '@marlin/shared/theme';
import { ActionCustom, ActionDelete, ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import { Paper } from '@marlin/shared/ui-page';
import KeyIcon from '@mui/icons-material/Key';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Chip, Link, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { formatFrequency, formatWebhookType } from './formatting.utils';
import { Headers } from './headers/headers.component';
import { WebhookToggle } from './webhook-toggle.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  paper: {
    padding: theme.typography.pxToRem(0),
  },
  tile: {
    padding: theme.typography.pxToRem(16),
  },
  tags: {
    display: 'flex',
    marginTop: theme.typography.pxToRem(12),
    gap: theme.typography.pxToRem(8),
    flexWrap: 'wrap',
  },
  menu: {
    padding: 0,
  },
  webhookUrl: {
    wordBreak: 'break-word',
  },
}));

interface IWebhookTileProps {
  webhook: TWebhook;
  onEdit: (id: string) => void;
  onDelete: (webhook: TWebhook) => void;
}

export const WebhookTile = ({ webhook, onDelete, onEdit }: IWebhookTileProps) => {
  const { classes } = useStyles();
  const [keysVisible, setKeysVisible] = useState(false);

  const toggleKeys = useCallback(() => {
    setKeysVisible(!keysVisible);
  }, [setKeysVisible, keysVisible]);

  const isPartition = useIsPartition({ webhookDevices: webhook.devices });

  return (
    <Paper className={classes.paper} testId="list-tile-item">
      <div className={classes.tile}>
        <CardHeader variant="body2" asset={webhook}>
          <>
            <WebhookToggle webhookId={webhook.id} isEnabled={webhook.isEnabled} isSuspended={webhook.suspended} />
            <ContextMenu data-testid="webhook-card-menu" className={classes.menu}>
              <ActionEdit onClick={() => onEdit(webhook.id)} />
              <ActionCustom
                onClick={toggleKeys}
                optionText={keysVisible ? content.HIDE_KEYS : content.SHOW_KEYS}
                icon={<KeyIcon fontSize="small" />}
                dataTestId={keysVisible ? 'hide-keys' : 'show-keys'}
              />
              <ActionDelete onClick={() => onDelete(webhook)} />
            </ContextMenu>
          </>
        </CardHeader>
        <Typography variant="body2" data-testid="webhook-url" className={classes.webhookUrl}>
          {webhook.url}
        </Typography>
        <div className={classes.tags}>
          <Chip
            label={
              <Link href={`mailto:${webhook.managerEmail}`} data-testid="manager-email">
                {webhook.managerEmail}
              </Link>
            }
            icon={<NotificationsIcon color="action" />}
            size="small"
            data-testid="manager-email-chip"
          />
          <Chip label={formatFrequency(webhook.frequency)} size="small" data-testid="frequency-chip" />
          <Chip label={formatWebhookType(webhook.type, isPartition)} size="small" data-testid="type-chip" />
        </div>
      </div>
      {keysVisible && <Headers onHideClick={toggleKeys} headers={webhook.headers} />}
    </Paper>
  );
};
