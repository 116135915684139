import { MarlinTheme } from '@marlin/shared/theme';
import ElectricalServicesRoundedIcon from '@mui/icons-material/ElectricalServicesRounded';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';

interface IStyles {
  color?: string;
}

const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { color }) => ({
  cell: {
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    marginRight: theme.typography.pxToRem(6),
    color: color ? color : theme.palette.success.light,
  },
}));

export const Line = ({ color, disconnected }: { color?: string; disconnected?: boolean }) => {
  const { classes } = useStyles({ color });

  return (
    <div className={classes.cell}>
      <ElectricalServicesRoundedIcon className={classes.icon} />
      {disconnected ? content.DISCONNECTED : content.POWER_CELL_LINE}
    </div>
  );
};
