import { z } from 'zod';

export type TDeviceDrawerTabs = 'details' | 'alerts' | 'readings';
export type TEquipmentTabs = 'details' | 'alerts' | 'settings';

export interface IAlertsQueryParam {
  criticality?: 'LOW' | 'HIGH';
  alertDeviceId?: string;
  alertEquipmentId?: string;
  alertDeviceName?: string;
  alertEquipmentName?: string;
  category?: 'Health' | 'Performance';
  alertType?: 'Sensor' | 'Equipment' | 'Gateway';
}

export enum TAB_VALUES {
  LinkedItems = 'linked-items',
  Details = 'details',
  HierarchyView = 'hierarchy',
}

export enum EQUIPMENT_TAB_VALUES {
  DASHBOARD = 'dashboard',
  ALERTS = 'alerts',
  SETTINGS = 'settings',
  EVENT_LOG = 'event-log',
  HISTORICAL_CHART = 'historical-chart',
  EQUIPMENT_FLOW = 'flow',
  BOILERS_AND_PUMPS = 'boilers-and-pumps',
  BOILERS = 'boilers',
}

const EquipmentTabValuesSchema = z.nativeEnum(EQUIPMENT_TAB_VALUES);

export enum GATEWAY_TAB_VALUES {
  DASHBOARD = 'dashboard',
  SETTINGS = 'settings',
}

const GatewayTabValuesSchema = z.nativeEnum(GATEWAY_TAB_VALUES);

export type TTabValues = EQUIPMENT_TAB_VALUES | GATEWAY_TAB_VALUES;
export const TabValuesSchema = z.union([EquipmentTabValuesSchema, GatewayTabValuesSchema]);

export enum ONBOARDING_TYPE {
  CREATE_NEW_ORGANIZATION = 'create-new-organization',
}

export const isOnboardingType = (value: unknown): value is ONBOARDING_TYPE => {
  return Object.values(ONBOARDING_TYPE).includes(value as ONBOARDING_TYPE);
};

export interface ISensorHubParams {
  batteryLevelStatus?: 'LOW' | 'CRITICAL' | 'OK' | 'LINE' | 'NONE';
  lostCommunication?: boolean;
}
