import { useResetOccupancyQuery } from '@marlin/asset/data-access/gateway';
import { MarlinTheme } from '@marlin/shared/theme';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { Button, CircularProgress, alpha } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
import { z } from 'zod';

import { content } from '../../../content';

interface IFooter {
  handleCloseModal: () => void;
  params: {
    gatewayId: string;
    connectionId: string;
    addressId: string;
  };
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  cancelButton: {
    marginRight: theme.typography.pxToRem(16),
  },
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    zIndex: theme.indexPosition.foreground,
    top: 0,
    left: 0,
    right: '100%',
    bottom: '100%',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: alpha(theme.palette.text.disabled, 0.5),
  },
  loader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    width: theme.typography.pxToRem(60),
    height: theme.typography.pxToRem(60),
    position: 'absolute',
    top: '40%',
    left: `calc(50% - ${theme.typography.pxToRem(30)})`,
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
}));

const data = {
  datapoints: [
    {
      name: 'PartitionOccupancyTimeReset',
      // binary value for 1111 1111 1111 1111 when each 1 means reset door on number position eg. 0000 0000 0000 0010 means reset door on position 2
      value: '65535',
    },
    {
      name: 'OccupancyCountReset',
      // binary value for 1111 1111 1111 1111 when each 1 means reset door on number position eg. 0000 0000 0000 0010 means reset door on position 2
      value: '65535',
    },
  ],
};

export const Footer = ({ params, handleCloseModal }: IFooter) => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();

  const onError = useCallback(
    (error: unknown) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);

      if (error instanceof AxiosError && error.response?.data.errorCode === 40000) {
        enqueueSnackbar(content.ERROR_OFFLINE, { variant: 'error' });
      } else {
        enqueueSnackbar(content.ERROR, { variant: 'error' });
      }
      handleCloseModal();
    },
    [enqueueSnackbar, handleCloseModal]
  );

  const onSuccess = useCallback(() => {
    enqueueSnackbar(content.RESET_MESSAGE, { variant: 'success' });
    handleCloseModal();
  }, [enqueueSnackbar, handleCloseModal]);

  const resetOccupancyMutation = useResetOccupancyQuery({
    onError,
    onSuccess,
  });
  const handleReset = useCallback(async () => {
    resetOccupancyMutation.mutate({ params, data });
  }, [params, resetOccupancyMutation]);

  return (
    <div data-testid="reset-modal-footer">
      {resetOccupancyMutation.isPending && (
        <div className={classes.overlay}>
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        </div>
      )}
      <Button
        className={classes.cancelButton}
        variant="text"
        data-testid="reset-modal-footer-cancel"
        onClick={handleCloseModal}
      >
        {content.RESET_MODAL.CANCEL}
      </Button>
      <Button
        variant="contained"
        color="error"
        disabled={resetOccupancyMutation.isPending}
        data-testid="reset-modal-footer-confirm"
        onClick={handleReset}
      >
        {content.RESET_MODAL.SUBMIT}
      </Button>
    </div>
  );
};
