import { mapGatewayDataSource, mapGatewayDatapointsDataSource } from '@marlin/asset/shared/utils/details-mapper';
import { TDatapointWithMetadata } from '@marlin/shared/utils/datapoint-mappers';
import { TGatewayInfoUpdateResponsePayload } from '@marlin/shared/utils/zod';

import { content } from '../content';
import { useGatewayDatapointsContext } from '../context/gateway-datapoints.context';
import { useGatewayDetailsConfigContext } from '../context/gateway-details-config.context';

export type TRow = { label: string; value?: number | string; column?: number };

interface IDetailsData {
  datapoints: TDatapointWithMetadata[];
  gateway: TGatewayInfoUpdateResponsePayload;
}

export const useGatewayDetailsSectionData = ({ datapoints, gateway }: IDetailsData): TRow[] => {
  const {
    config: { details },
  } = useGatewayDetailsConfigContext();
  const { getDatapoint } = useGatewayDatapointsContext();

  if (!gateway) {
    return [];
  }

  const data = datapoints.length ? datapoints : gateway.lastReadingValues;

  return details.map((row) => {
    const emptyRow = {
      label: row.label,
      value: content.EMPTY_DATAPOINT_VALUE,
      column: row.column,
    };

    switch (row.valueSource) {
      case 'gateway':
        return mapGatewayDataSource(row, gateway) ?? emptyRow;
      case 'datapoints':
        return mapGatewayDatapointsDataSource(row, data, getDatapoint) ?? emptyRow;
      default:
        return emptyRow;
    }
  });
};
