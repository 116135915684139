import AdapterMoment from '@mui/lab/AdapterMoment';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import momentTimezone from 'moment-timezone';

import { content } from './content';
import { IDateStringRange, TDateString, TDateStringInternal } from './model';

export const dateAdapter = new AdapterMoment();

export const timeFormat = 'HH:mm';
export const dateFormat = 'ddd';
export const dateMonthFormat = 'MMM DD';
export const customFormat = 'MM/DD/YY HH:mm A z';

export const minutesToHours = (minutes: number) => minutes / 60;

export const createIsoStringDate = (date?: TDateString) => {
  if (!date) {
    return '';
  }

  return dateAdapter.date(date)?.toISOString();
};

export const formatDate = (
  date: TDateString,
  format?: string,
  timezone: string = momentTimezone.tz.guess()
): string => {
  if (!date) {
    return '';
  }

  return momentTimezone(date).tz(timezone).format(format);
};

export const formatTimestamp = (
  date?: number | string,
  format?: string,
  timezone: string = momentTimezone.tz.guess()
): string => {
  if (!date) {
    return '';
  }

  return momentTimezone(date).tz(timezone).format(format);
};

export const getMinutesFromNow = (date: TDateString) => {
  const now = dateAdapter.date();
  const lastReadingDate = dateAdapter.date(date);

  return now?.diff(lastReadingDate, 'minutes');
};

export const getTimeFromNow = (date: TDateString, unitOfTime: 'seconds' | 'hours' | 'days') => {
  const now = dateAdapter.date();
  const lastReadingDate = dateAdapter.date(date);

  return now?.diff(lastReadingDate, unitOfTime);
};

export const timestampToIsoString = (timestamp: number) => dateAdapter.date(timestamp)?.toISOString();
export const convertDateStringToTimestamp = (date: TDateString): number =>
  dateAdapter.date(date)?.toDate()?.getTime() ?? new Date().getTime();

export const checkValidDateStr = (str: string): str is TDateStringInternal => {
  if (str.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d+Z$/) !== null) {
    return true;
  }

  return str.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/) !== null;
};

export const checkIfTwoRangesOverlapped = (range1: IDateStringRange, range2: IDateStringRange) => {
  const from1 = convertDateStringToTimestamp(range1.from);
  const to1 = convertDateStringToTimestamp(range1.to);
  const from2 = convertDateStringToTimestamp(range2.from);
  const to2 = convertDateStringToTimestamp(range2.to);

  return from1 <= to2 && from2 <= to1;
};

export function createDateString(date?: Date | string): TDateString {
  if (isNil(date)) {
    return null;
  }

  if (isString(date)) {
    if (date.includes('Z')) {
      if (checkValidDateStr(date)) {
        return date;
      }
    } else {
      const timeZoneDate = `${date}Z`;
      if (checkValidDateStr(timeZoneDate)) {
        return timeZoneDate;
      }
    }

    // eslint-disable-next-line no-console
    console.error(`[DATE UTILS] Invalid date string: ${date}`);
    return null;
  }

  const dateString = dateAdapter.date(date)?.toISOString();
  if (dateString && checkValidDateStr(dateString)) {
    return dateString;
  }

  // eslint-disable-next-line no-console
  console.error(`[DATE UTILS] Invalid date string: ${date}`);
  return null;
}

export const isBefore = (date1: TDateString, date2: TDateString) =>
  dateAdapter.date(date1)?.isBefore(dateAdapter.date(date2));

export enum SECONDS_IN {
  YEARS = 63072000,
  YEAR = 31536000,
  MONTHS = 5184000,
  MONTH = 2592000,
  DAYS = 172800,
  DAY = 86400,
  HOURS = 7200,
  HOUR = 3600,
  MINUTES = 120,
  MINUTE = 60,
  SECOND = 1,
}

export const timeSince = (lastReadingTime: string) => {
  const currentDateInUtc = dateAdapter.date()?.valueOf() ?? 0;
  const lastReadingTimeDateInUtc = dateAdapter.date(lastReadingTime)?.valueOf() ?? 0;
  const differenceInSeconds = Math.floor((currentDateInUtc - lastReadingTimeDateInUtc) / 1000);

  return secondsToFormattedTime(differenceInSeconds);
};

export const secondsToFormattedTime = (time: number, useSeconds = false) => {
  if (time >= SECONDS_IN.YEAR) {
    return `${Math.floor(time / SECONDS_IN.YEAR)} ${
      time >= SECONDS_IN.YEAR && time < SECONDS_IN.YEARS ? content.YEAR : content.YEARS
    }`;
  }

  if (time >= SECONDS_IN.MONTH) {
    return `${Math.floor(time / SECONDS_IN.MONTH)} ${
      time >= SECONDS_IN.MONTH && time < SECONDS_IN.MONTHS ? content.MONTH : content.MONTHS
    }`;
  }

  if (time >= SECONDS_IN.DAY) {
    return `${Math.floor(time / SECONDS_IN.DAY)} ${
      time >= SECONDS_IN.DAY && time < SECONDS_IN.DAYS ? content.DAY : content.DAYS
    }`;
  }

  if (time >= SECONDS_IN.HOUR) {
    return `${Math.floor(time / SECONDS_IN.HOUR)} ${
      time >= SECONDS_IN.HOUR && time < SECONDS_IN.HOURS ? content.HOUR : content.HOURS
    }`;
  }

  if (useSeconds) {
    if (time >= SECONDS_IN.MINUTE) {
      return `${Math.floor(time / SECONDS_IN.MINUTE)} ${
        time >= SECONDS_IN.MINUTE && time < SECONDS_IN.MINUTES ? content.MINUTE : content.MINUTES
      }`;
    }
    return `${Math.floor(time / SECONDS_IN.SECOND)} ${time === SECONDS_IN.SECOND ? content.SECOND : content.SECONDS}`;
  }

  return `${Math.floor(time / SECONDS_IN.MINUTE)} ${
    time >= SECONDS_IN.MINUTE && time < SECONDS_IN.MINUTES ? content.MINUTE : content.MINUTES
  }`;
};

export const getUserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getUserTimezoneAbbreviation = () => momentTimezone.tz(momentTimezone.tz.guess(true)).format('z');

export const startOfDay = (date: TDateString): TDateString => {
  const dateObj = dateAdapter.date(date)?.utc().startOf('day');

  return createDateString(dateObj?.toDate());
};

export const dateFormatterValueCallback = (value: unknown) => {
  if (typeof value === 'string') {
    return dateAdapter.date(value)?.format('MM/DD/YY');
  }

  return undefined;
};
