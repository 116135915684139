import { MarlinTheme } from '@marlin/shared/theme';
import { getFormattedValue } from '@marlin/shared/utils-format-reading';
import { TValueSetting } from '@marlin/shared/utils/datapoint-mappers';
import ErrorIcon from '@mui/icons-material/Error';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { isValidStepValue, useValueSetting } from '../../../hooks/use-value-setting.hook';
import { TEditedSetting } from '../../../types';
import { EditHeader } from '../shared/edit-header.component';
import { ValueInput } from './value-input.component';
import { ValueSlider } from './value-slider.component';

type TValueSettingProps = {
  settingItem: TValueSetting;
  saveSetting: (name: string, value: string, prevValue: string) => Promise<void>;
  onCancelClick: () => void;
  setEditedSetting: (editedSetting: TEditedSetting | undefined) => void;
  setIsDirty: (isDirty: boolean) => void;
  editedSetting: TEditedSetting | undefined;
};

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  editContainer: {
    width: '100%',
    padding: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      padding: `${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(16)}`,
    },
  },
  editHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  inputsContainer: {
    marginTop: theme.typography.pxToRem(32),
    display: 'flex',
    gap: theme.typography.pxToRem(32),
  },
  actionButtonsContainer: {
    marginTop: theme.typography.pxToRem(40),
    display: 'flex',
    gap: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.typography.pxToRem(24),
    },
  },
  actionButton: {
    flex: 1,
  },
  thresholdError: {
    display: 'flex',
    alignItems: 'flex-start',
    color: theme.palette.error.main,
    gap: theme.typography.pxToRem(8),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(19.92),
    marginTop: theme.typography.pxToRem(16),
  },
  sliderContainer: {
    padding: `${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(16)}`,
  },
}));

export const ValueSetting = ({
  settingItem,
  saveSetting,
  onCancelClick,
  setEditedSetting,
  editedSetting,
  setIsDirty,
}: TValueSettingProps) => {
  const {
    label,
    value: currentValue,
    min = 0,
    max = 0,
    displayedValue,
    name,
    step = 1,
    maxThreshold,
    minThreshold,
    unitOfMeasure,
  } = settingItem;
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { value, onChange, onSliderChange, onSave, onBlur, onRemoveClick, onAddClick } = useValueSetting({
    min,
    max,
    currentValue,
    saveSetting,
    name,
    step,
    editedSetting,
    setEditedSetting,
    setIsDirty,
  });

  const { isMaxThresholdExceeded, isMinThresholdExceeded } = useMemo(
    () => ({
      isMaxThresholdExceeded: !!maxThreshold && parseFloat(value) > maxThreshold,
      isMinThresholdExceeded: !!minThreshold && parseFloat(value) < minThreshold,
    }),
    [maxThreshold, minThreshold, value]
  );
  const isValidStep = useMemo(() => isValidStepValue(parseFloat(value), min, max, step), [step, value, min, max]);

  return (
    <div className={classes.editContainer} data-testid={`${name}-editing-section`}>
      <div className={classes.editHeader}>
        <EditHeader title={label} displayedValue={displayedValue} />
      </div>
      {isMobile ? (
        <>
          <ValueInput
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            maxValue={max}
            minValue={min}
            onRemoveClick={() => onRemoveClick(step)}
            onAddClick={() => onAddClick(step)}
            unitOfMeasure={settingItem.unitOfMeasure}
          />
          <div className={classes.sliderContainer}>
            <ValueSlider
              value={parseFloat(value)}
              onChange={onSliderChange}
              max={max}
              min={min}
              step={step}
              unitOfMeasure={settingItem.unitOfMeasure}
              isThresholdExceeded={isMaxThresholdExceeded || isMinThresholdExceeded}
            />
          </div>
        </>
      ) : (
        <div className={classes.inputsContainer}>
          <ValueInput
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            maxValue={max}
            minValue={min}
            onRemoveClick={() => onRemoveClick(step)}
            onAddClick={() => onAddClick(step)}
            unitOfMeasure={settingItem.unitOfMeasure}
          />
          <ValueSlider
            value={parseFloat(value)}
            onChange={onSliderChange}
            min={min}
            max={max}
            step={step}
            unitOfMeasure={settingItem.unitOfMeasure}
            isThresholdExceeded={isMaxThresholdExceeded || isMinThresholdExceeded}
          />
        </div>
      )}
      {(isMaxThresholdExceeded || isMinThresholdExceeded) && (
        <div className={classes.thresholdError}>
          <ErrorIcon width={20} height={20} />
          <span>
            {content.THRESHOLD_ERROR(
              getFormattedValue(String(isMaxThresholdExceeded ? maxThreshold : minThreshold), unitOfMeasure),
              isMaxThresholdExceeded
            )}
          </span>
        </div>
      )}
      <div className={classes.actionButtonsContainer}>
        <Button
          className={classes.actionButton}
          onClick={onCancelClick}
          variant="outlined"
          data-testid="setting-item-cancel-button"
        >
          {content.CANCEL}
        </Button>
        <Button
          className={classes.actionButton}
          onClick={onSave}
          variant="contained"
          disabled={
            parseFloat(value) === parseFloat(currentValue) ||
            isMaxThresholdExceeded ||
            isMinThresholdExceeded ||
            !isValidStep
          }
          data-testid="setting-item-set-button"
        >
          {content.SET}
        </Button>
      </div>
    </div>
  );
};
