import { TExtractFnReturnType } from '@marlin/shared/utils/react-query';
import { useInfiniteQuery, useQuery } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { getFilteredEquipments } from '../infrastructure/equipment';
import { TEquipmentParamsSchema } from '../schema/equipment.request.schema';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getFilteredEquipments;

type TUseEquipmentsOptions = {
  params: TEquipmentParamsSchema;
  select?: (data: TExtractFnReturnType<TQueryFnType>) => TExtractFnReturnType<TQueryFnType>;
  enabled?: boolean;
};

export const useFilteredEquipmentsWithPaging = ({ params, select, enabled = true }: TUseEquipmentsOptions) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.EQUIPMENTS(params),
    queryFn: () => getFilteredEquipments(params),
    placeholderData: (previousData, previousQuery) => previousData,
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);
    },
    select,
    enabled,
  });
};

type TUseInfiniteEquipmentsProps = {
  params: Omit<TEquipmentParamsSchema, 'page'>;
  enabled?: boolean;
};

export const useFilteredEquipmentsWithLoadMore = ({ params, enabled = true }: TUseInfiniteEquipmentsProps) => {
  return useInfiniteQuery<TExtractFnReturnType<TQueryFnType>, Error>({
    queryKey: queryKey.EQUIPMENTS(params),
    queryFn: ({ pageParam }) => getFilteredEquipments({ ...params, page: (pageParam as number) || 1 }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.page * lastPage.pagination.pageSize < lastPage.pagination.totalItems
        ? lastPage.pagination.page + 1
        : undefined;
    },
    enabled,
  });
};
