import { TExtractFnReturnType, useInfiniteQuery, useQuery } from '@marlin/shared/utils/react-query';

import { getFilteredWebhooks } from '../infrastructure/get-filtered-webhooks';
import { TGetFilteredWebhooksParams } from '../schemas/get-filtered-webhooks.schema';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getFilteredWebhooks;

type TUseFilteredWebhooksWithPaging = {
  params: TGetFilteredWebhooksParams;
};

export const useFilteredWebhooksWithPaging = ({ params }: TUseFilteredWebhooksWithPaging) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>, Error>({
    queryKey: queryKey.FILTERED_WEBHOOKS(params),
    queryFn: () => getFilteredWebhooks(params),
    placeholderData: (previousData, previousQuery) => previousData,
  });
};

type TUseFilteredWebhooksWithLoadMoreProps = {
  params: Omit<TGetFilteredWebhooksParams, 'page'>;
  enabled: boolean;
};

export const useFilteredWebhooksWithLoadMore = ({ params, enabled }: TUseFilteredWebhooksWithLoadMoreProps) => {
  return useInfiniteQuery<TExtractFnReturnType<TQueryFnType>, Error>({
    queryKey: queryKey.FILTERED_WEBHOOKS(params),
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getFilteredWebhooks({ ...params, page: pageParam as number }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.page * lastPage.pagination.pageSize < lastPage.pagination.totalItems
        ? lastPage.pagination.page + 1
        : undefined;
    },
    enabled,
  });
};
