import { useAvailableBoilers } from '@marlin/asset/data-access/equipment';
import { IBoilersAndPumpsRow } from '@marlin/asset/shared/equipment-config';
import cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';

import { content } from '../../content';
import { useDatapointsContext } from '../../context/datapoints.context';
import { useEquipmentDetailsConfigContext } from '../../context/equipment-details-config-context';

const BoilerNumTemplate = '{X}';
const highlightStatuses = ['prepurge', 'ignition', 'firing', 'postpurge', 'on'];
export const useBoilersAndPumps = (equipmentId: string) => {
  const { getDatapoint } = useDatapointsContext();

  const {
    config: { boilersAndPumps },
  } = useEquipmentDetailsConfigContext();

  const rows = useMemo(() => boilersAndPumps?.rows, [boilersAndPumps]);

  const {
    data: availableBoilers,
    isLoading,
    refetch,
    isError,
  } = useAvailableBoilers({
    equipmentId,
    checkAvailableBoilers: true,
  });

  const boilersAndPumpsToDisplay = useMemo(() => {
    if (!rows) return [];

    const boilerDetails = rows.find((boilerOrPump) => boilerOrPump.type === 'boiler') || ({} as IBoilersAndPumpsRow);

    const getBoilerDetails = (boilerId: string) => {
      const boiler = cloneDeep(boilerDetails);
      const boilerKeys = Object.keys(boiler);

      boilerKeys.forEach((key) => {
        if (typeof boiler[key] === 'string') {
          boiler[key] = boiler[key].replace(BoilerNumTemplate, boilerId);
        }

        if (boiler[key]?.override?.datapointName) {
          boiler[key].override.datapointName = boiler[key].override.datapointName?.replace(BoilerNumTemplate, boilerId);
        }
      });

      return boiler;
    };

    const boilersToShow = (availableBoilers?.datapoints || [])
      .map((datapoint) => {
        const id = datapoint.name.replace(/\D/g, '');

        return getBoilerDetails(id);
      })
      .filter((boiler) => boiler.id);

    return rows.filter((boilerOrPump) => boilerOrPump.type !== 'boiler').concat(boilersToShow);
  }, [rows, availableBoilers?.datapoints]);

  const boilersAndPumpsList = useMemo(() => {
    if (!rows?.length || isError) return [];

    const getDatapointFromConfig = (datapointName: string | undefined) =>
      datapointName
        ? getDatapoint(datapointName)?.displayedValue ?? content.EMPTY_DATAPOINT_VALUE
        : content.EMPTY_DATAPOINT_VALUE;

    const getBoilerStatus = (
      boiler: IBoilersAndPumpsRow
    ): { value: string | undefined; controlType: IBoilersAndPumpsRow['boilerStatusControlType'] } | null => {
      if (!boiler.boilerFiringRateDatapoint || !boiler.boilerFiringTempDatapoint) return null;

      const enableStatus = getDatapoint(boiler.boilerEnabledStatus ?? '');
      const firingRate = getDatapoint(boiler.boilerFiringRateDatapoint);
      const firingTemp = getDatapoint(boiler.boilerFiringTempDatapoint);
      const hasValue = Number(firingRate?.value) || Number(firingTemp?.value);

      if (enableStatus) {
        return {
          value: enableStatus?.displayedValue ?? content.EMPTY_DATAPOINT_VALUE,
          controlType: highlightStatuses.includes(enableStatus.value.toLowerCase()) ? 'highlight' : undefined,
        };
      }

      const value = hasValue ? firingRate?.displayedValue || firingTemp?.displayedValue : content.EMPTY_DATAPOINT_VALUE;
      return {
        value,
        controlType: value === content.EMPTY_DATAPOINT_VALUE ? undefined : hasValue ? 'highlight' : 'error',
      };
    };

    const getPumpStatus = (
      pump: IBoilersAndPumpsRow
    ): {
      value: string | undefined;
      controlType: IBoilersAndPumpsRow['pumpStatusControlType'];
    } | null => {
      if (!pump.pumpStatus && !pump.valveStatus)
        return {
          value: content.EMPTY_DATAPOINT_VALUE,
          controlType: undefined,
        };

      const pumpStatus = getDatapoint(pump.pumpStatus ?? pump.valveStatus ?? '');
      return {
        value: pumpStatus?.displayedValue ?? content.EMPTY_DATAPOINT_VALUE,
        controlType: highlightStatuses.includes(pumpStatus?.value?.toLowerCase() ?? '') ? 'highlight' : undefined,
      };
    };

    return boilersAndPumpsToDisplay.map((boilerOrPump) => {
      const boilerStatus = getBoilerStatus(boilerOrPump);
      const pumpStatus = getPumpStatus(boilerOrPump);

      return {
        ...boilerOrPump,
        hours: getDatapointFromConfig(boilerOrPump.hoursDatapoint).replace(/\D/gi, '') || content.EMPTY_DATAPOINT_VALUE,
        cycles: getDatapointFromConfig(boilerOrPump.cyclesDatapoint),
        pumpStatus: pumpStatus?.value,
        boilerStatus: boilerStatus?.value ?? content.EMPTY_DATAPOINT_VALUE,
        boilerStatusControlType: boilerStatus?.controlType,
        pumpStatusControlType: pumpStatus?.controlType,
      };
    });
  }, [rows?.length, boilersAndPumpsToDisplay, getDatapoint, isError]);

  return {
    boilersAndPumps: boilersAndPumpsList,
    isLoading,
    refetch,
    isError,
  };
};
