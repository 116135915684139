import { queryKey } from '@marlin/asset/data-access/telemetry';
import { ChartChip } from '@marlin/asset/shared/ui/chart-chips';
import { useChartOptionsV2 } from '@marlin/asset/shared/ui/chart-options';
import { ChartTypeSwitcher } from '@marlin/asset/shared/ui/chart-type-switcher';
import { ExtendedDrawer } from '@marlin/shared/ui-modal';
import { Paper } from '@marlin/shared/ui-page';
import { CustomPeriodModalProvider, TChartType, useCustomPeriodModalContextStore } from '@marlin/shared/utils-chart';
import { queryClient } from '@marlin/shared/utils/react-query';
import { Button } from '@mui/material';

import { content } from '../../content';
import { useChartDisplayType } from '../../hooks/use-chart-display-type.hook';
import { useChartingColors } from '../../hooks/use-charting-colors.hook';
import { IRequestedTelemetry } from '../../types';
import { TotalValue } from '../total-volume/total-value.component';
import { ChartModalHeader } from './chart-modal-header.component';
import { useStyles } from './chart-modal.styles';
import { FullScreenChart } from './full-screen-chart.component';
import { useChartModalData } from './use-chart-modal-data.hook';
import { useChartRange } from './use-chart-range-reducer.hook';
import { useDataPoints } from './use-data-points.hook';

interface IChartModalProps {
  requestedTelemetry: IRequestedTelemetry[];
  closeChartModal: () => void;
  title: string;
  chartType: TChartType;
}

const chartId = 'full-screen-chart';

export const ChartModalInternal = ({ requestedTelemetry, title, chartType }: IChartModalProps) => {
  const { state, onRangeFilterChange, onAveragingFunctionFilterChange, onReset } = useChartRange();
  const { classes } = useStyles();
  const colors = useChartingColors();

  // TODO: Uncouple the store
  const [periodStore] = useCustomPeriodModalContextStore((store) => store);
  const { chartDisplayType, onChartDisplayTypeChange } = useChartDisplayType(chartType);
  const { activeRequestedTelemetry, onActiveDatapointsChange, clearAllActiveDatapoints, isDatapointActive } =
    useDataPoints(requestedTelemetry);

  const handleRefresh = () => {
    queryClient.invalidateQueries({ queryKey: queryKey.ANALYTICS_TELEMETRY() });
    onReset();
  };

  const { chartData, from, to, isFetching, isLoading, min, max, handleZoomChange, totalVolume } = useChartModalData({
    requestedTelemetry,
    activeRequestedTelemetry,
    from: periodStore.fromDate,
    to: periodStore.toDate,
    chartId,
    rangeFilter: state.rangeFilter,
    chartDisplayType,
    averagingFunctionFilter: state.averagingFunctionFilter,
    chartType,
  });

  const { layout, config, handleRelayout, handleHover, handleUnhover, tooltip, isEmpty, uoms } = useChartOptionsV2({
    max,
    min,
    chartData,
    rangeFilter: state.rangeFilter.range,
    from,
    to,
    handleZoomChange,
    isFullScreen: false,
    chartType,
    chartId,
    isZoomed: false,
    chartDisplayType,
  });

  return (
    <>
      <ChartModalHeader
        onRangeFilterChange={onRangeFilterChange}
        onAveragingFunctionFilterChange={onAveragingFunctionFilterChange}
        onReset={onReset}
        open={periodStore.open}
        range={state.rangeFilter.range}
        handleRefresh={handleRefresh}
        averagingFunctionFilter={state.averagingFunctionFilter}
        title={title}
        chartType={chartType}
      />
      <Paper className={classes.chart}>
        <div>
          {chartType === 'flow' && (
            <div data-testid="chart-type-switcher" className={classes.chartTypeSwitcher}>
              <ChartTypeSwitcher chartDisplayType={chartDisplayType} setChartDisplayType={onChartDisplayTypeChange} />
            </div>
          )}
          <div data-testid="chart-chips" className={classes.chips}>
            {requestedTelemetry.map(({ name, manufacturerId }, index) => {
              return (
                <ChartChip
                  key={manufacturerId}
                  label={name}
                  color={colors[index % colors.length]}
                  isActive={isDatapointActive(manufacturerId)}
                  toggleChip={() => onActiveDatapointsChange(manufacturerId)}
                  name={name}
                  dataTestId={`chart-chip-${manufacturerId}`}
                />
              );
            })}
            {!!requestedTelemetry.length && (
              <Button variant="text" onClick={clearAllActiveDatapoints} data-testid="clear-all-button">
                {content.CLEAR_ALL}
              </Button>
            )}
          </div>
          {totalVolume && (
            <div>
              <TotalValue value={totalVolume} chartType={chartDisplayType} />
            </div>
          )}
          <FullScreenChart
            chartData={chartData}
            from={from}
            to={to}
            isFetching={isFetching}
            isLoading={isLoading}
            handleHover={handleHover}
            layout={layout}
            config={config}
            handleRelayout={handleRelayout}
            isEmpty={isEmpty}
            tooltip={tooltip}
            handleUnhover={handleUnhover}
            uoms={uoms}
          />
        </div>
      </Paper>
    </>
  );
};

export const ChartModal = (props: IChartModalProps) => {
  const { requestedTelemetry, closeChartModal } = props;
  return (
    <ExtendedDrawer open={!!requestedTelemetry.length} onClose={closeChartModal}>
      {requestedTelemetry.length ? (
        <CustomPeriodModalProvider>
          <ChartModalInternal {...props} />
        </CustomPeriodModalProvider>
      ) : null}
    </ExtendedDrawer>
  );
};
