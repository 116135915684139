import { zodResolver } from '@hookform/resolvers/zod';
import { AUTOMATION_RULE_TYPE, DEVICE_TYPE, TCreateAutomation } from '@marlin/alert/data-access/automated-action';
import { Paper } from '@marlin/shared/ui-page';
import { withProviders } from '@marlin/shared/utils-component-provider';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { Buttons } from './buttons.component';
import { IAutomationCreateContent } from './content/content';
import { AutomationContentContext } from './context';
import { Action } from './form-groups/action.component';
import { AutomationName } from './form-groups/automation-name.component';
import { RepeatingAlertLimit } from './form-groups/repeating-alert-limit/repeating-alert-limit.component';
import { Trigger } from './form-groups/trigger/trigger.component';
import {
  AutomatedActionFormValidationSchema,
  AutomatedActionFormValidationSchemaDeviceErrorAndAlerts,
} from './schemas/automated-action-form.schema';
import { IAutomationDevice, ILocation, IRecipient } from './types';

interface IAutomatedActionFormProps extends Record<string, unknown> {
  onCancel: () => void;
  onSubmit: (data: TCreateAutomation) => void;
  onAddRecipientButtonClick: () => void;
  locations: ILocation[];
  recipients: IRecipient[];
  defaultValues: TCreateAutomation;
  isUpdateForm?: boolean;
  isInformationType?: boolean;
}

export function AutomatedActionForm({
  devices,
  locations,
  recipients,
  onCancel,
  onSubmit,
  onAddRecipientButtonClick,
  defaultValues,
  isUpdateForm = false,
  isInformationType = false,
}: IAutomatedActionFormProps) {
  const isDeviceErrorOrAlertsRule =
    defaultValues.ruleType === AUTOMATION_RULE_TYPE.DEVICE_ERROR ||
    defaultValues.ruleType === AUTOMATION_RULE_TYPE.EQUIPMENT_INFORMATION ||
    defaultValues.ruleType === AUTOMATION_RULE_TYPE.DEVICE_ALERT;

  const isEquipment = (defaultValues as unknown as IAutomationDevice)?.device?.type === DEVICE_TYPE.EQUIPMENT;

  const form = useForm<TCreateAutomation>({
    defaultValues,
    mode: 'onTouched',
    resolver: isDeviceErrorOrAlertsRule
      ? zodResolver(AutomatedActionFormValidationSchemaDeviceErrorAndAlerts)
      : zodResolver(AutomatedActionFormValidationSchema),
  });

  const handleSubmit = (formData: TCreateAutomation) => {
    const { conditions, ...rest } = formData;

    return onSubmit({
      ...rest,
      conditions: isDeviceErrorOrAlertsRule
        ? {
            thresholdMax: undefined,
            thresholdMin: undefined,
            deadbandMax: undefined,
            deadbandMin: undefined,
          }
        : {
            deadbandMin:
              conditions.thresholdMin?.enabled && conditions.deadbandMin?.enabled && conditions.deadbandMin.value
                ? conditions.deadbandMin
                : undefined,
            deadbandMax:
              conditions.thresholdMax?.enabled && conditions.deadbandMax?.enabled && conditions.deadbandMax.value
                ? conditions.deadbandMax
                : undefined,
            thresholdMin: conditions.thresholdMin?.enabled ? conditions.thresholdMin : undefined,
            thresholdMax: conditions.thresholdMax?.enabled ? conditions.thresholdMax : undefined,
          },
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper>
              <AutomationName ruleType={defaultValues.ruleType} isSystemRule={defaultValues.isSystemRule} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper>
              <Trigger
                locations={locations}
                disableDeviceSelection={isInformationType ? false : isUpdateForm}
                ruleType={defaultValues.ruleType}
                automationName={form.getValues('name')}
                isInformationType={isInformationType}
                isEquipment={isEquipment}
                uom={defaultValues.uoM ? (defaultValues.uoM as TUnitOfMeasure) : undefined}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <>
              <Paper>
                <Action
                  recipients={recipients}
                  onAddRecipientButtonClick={onAddRecipientButtonClick}
                  isInformationType={isInformationType}
                />
                {!defaultValues.isSystemRule && <RepeatingAlertLimit />}
              </Paper>
              <Buttons onCancel={onCancel} onSubmit={handleSubmit} />
            </>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

interface IAutomatedActionFormParams extends IAutomatedActionFormProps {
  content: IAutomationCreateContent;
}

export default withProviders<IAutomatedActionFormParams>(AutomatedActionForm)({
  content: AutomationContentContext,
});
