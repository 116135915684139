import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import isNaN from 'lodash/isNaN';
import React, { useCallback, useState } from 'react';

import { content } from '../content';
import { TEditedSetting } from '../types';
import { useSettingListItem } from './use-setting-list-item.hook';

interface IUseValueSetting {
  currentValue: string;
  min: number;
  max: number;
  saveSetting: (name: string, value: string, prevValue: string) => Promise<void>;
  name: string;
  step: number;
  setEditedSetting: (editedSetting: TEditedSetting | undefined) => void;
  setIsDirty: (isDirty: boolean) => void;
  editedSetting: TEditedSetting | undefined;
}

const scale = 1e10;

const findClosestValidValue = (value: number, min: number, max: number, step: number) => {
  if (value < min) return min;
  if (value > max) return max;

  const nearest = Math.round((value - min) / step) * step + min;

  return Math.min(max, Math.max(min, nearest));
};

export const isValidStepValue = (value: number, min: number, max: number, step: number) => {
  if (value < min || value > max) return false;

  const valueMinDiff = Math.round((value - min) * scale);
  const stepMod = Math.round(step * scale);

  return Math.round((valueMinDiff % stepMod) * scale) / scale === 0;
};

export const useValueSetting = ({
  min,
  max,
  currentValue,
  saveSetting,
  name,
  step,
  setEditedSetting,
  editedSetting,
  setIsDirty,
}: IUseValueSetting) => {
  const { enqueueSnackbar } = useSnackbar();
  const { onCancelClick } = useSettingListItem({ name, setEditedSetting, setIsDirty });
  const [value, setValue] = useState<string>(editedSetting?.updatedValue ?? currentValue);
  const minValue = min;
  const maxValue = max;

  const handleOnValueChange = useCallback(
    (updatedValue: string) => {
      const hasChanges = updatedValue !== currentValue;

      setIsDirty(hasChanges);
      setEditedSetting(hasChanges ? { updatedValue, currentValue } : undefined);
    },
    [currentValue, setEditedSetting, setIsDirty]
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = e.target.value;
      let match = inputValue.match(/^\d+$/);

      if (!Number.isInteger(step)) {
        inputValue = inputValue.replace(/[^\d.]/g, '');
        match = inputValue.match(/^(?:\d+(\.(\d))?|\d+\.$|\d+)?$/);
      }
      if (step === 0.5) {
        inputValue = inputValue.replace(/[^\d.]/g, '');
        match = inputValue.match(/^(?:\d+(\.(5|0))?|\d+\.$|\d+)?$/);
      }

      if (match) {
        setValue(inputValue);
        handleOnValueChange(inputValue);
      }
    },
    [handleOnValueChange, step]
  );

  const onSliderChange = useCallback(
    (_e: Event, value: number | number[]) => {
      if (typeof value === 'number') {
        if (!Number.isInteger(step)) {
          setValue(value.toFixed(1));
          handleOnValueChange(value.toFixed(1));
          return;
        }
        setValue(value.toString());
        handleOnValueChange(value.toString());
      }
    },
    [handleOnValueChange, step]
  );

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);

    if (newValue > maxValue) {
      setValue(maxValue.toString());
      handleOnValueChange(maxValue.toString());

      return;
    }

    if (newValue < minValue || isNaN(newValue)) {
      setValue(minValue.toString());
      handleOnValueChange(minValue.toString());

      return;
    }

    if (!isValidStepValue(newValue, minValue, maxValue, step)) {
      const newNearestValue = findClosestValidValue(parseFloat(value), minValue, maxValue, step);
      const updatedValue = !Number.isInteger(step) ? newNearestValue.toFixed(1) : newNearestValue.toString();
      setValue(updatedValue);
      handleOnValueChange(updatedValue);

      return;
    }

    if (!Number.isInteger(step)) {
      setValue(parseFloat(value).toFixed(1));
      handleOnValueChange(parseFloat(value).toFixed(1));

      return;
    }
  };

  const onSave = async () => {
    try {
      await saveSetting(name, String(value), currentValue);
      onCancelClick();
    } catch (error) {
      enqueueSnackbar(content.ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  const onRemoveClick = (step: number) => {
    setValue((prev) => {
      const decrementedValue = parseFloat(prev) - step;
      const updatedValue = !Number.isInteger(step) ? decrementedValue.toFixed(1) : decrementedValue.toString();

      handleOnValueChange(updatedValue);

      return updatedValue;
    });
  };

  const onAddClick = (step: number) => {
    setValue((prev) => {
      const incrementedValue = parseFloat(prev) + step;
      const updatedValue = !Number.isInteger(step) ? incrementedValue.toFixed(1) : incrementedValue.toString();
      handleOnValueChange(updatedValue);

      return updatedValue;
    });
  };

  return { value, onChange, onSliderChange, onSave, onBlur, onRemoveClick, onAddClick };
};
