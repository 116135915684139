import { TExtractFnReturnType, TQueryConfig, getHttpClient, useQuery } from '@marlin/shared/utils/react-query';

import { paths } from '../infrastructure/api';
import { TLocationDetails, locationDetailsSchema } from '../infrastructure/schema/location-details.schema';

export const getLocation = async ({ locationId }: { locationId: string }): Promise<TLocationDetails> => {
  const res = await getHttpClient().get<TLocationDetails>(paths.LOCATION, { params: { locationId } });

  return locationDetailsSchema.parse(res);
};

type TQueryFnType = typeof getLocation;

type TUseLocationOptions = { locationId?: string; config?: TQueryConfig<TQueryFnType> };

export const useLocation = ({ locationId, config }: TUseLocationOptions) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>, Error>({
    enabled: Boolean(locationId),
    queryKey: ['location', locationId],
    queryFn: () => getLocation({ locationId: locationId ?? '' }),
    ...config,
  });
};
