import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ReactNode } from 'react';

import { useStyles } from './toggle-buttons.styles';

export interface IToggleButtonsOption<T> {
  value: NonNullable<T>;
  label: string;
  icon?: ReactNode;
  disabled?: boolean;
}

interface IToggleButtonsProps<T> {
  options: IToggleButtonsOption<T>[];
  value: T;
  onChange: (event: React.MouseEvent<HTMLElement>, value: NonNullable<T>) => void;
}

export const ToggleButtons = <T,>({ value, options, onChange }: IToggleButtonsProps<T>) => {
  const { classes } = useStyles();

  return (
    <ToggleButtonGroup value={value} exclusive onChange={onChange} size={'small'} fullWidth>
      {options.map((option) => (
        <ToggleButton
          key={option.toString()}
          value={option.value}
          disabled={option.disabled}
          classes={{
            root: classes.root,
            selected: classes.selected,
          }}
          data-testid={`toggle-button-${option.value}`}
        >
          {option.icon && <span className={classes.icon}>{option.icon}</span>}
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
