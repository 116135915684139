import { CONVERSION_TYPE, convertRegion } from '@marlin/shared/utils/maps-api';
import { TOrgAddress } from '@marlin/shared/utils/zod';

type TAddressComponents = {
  [key in keyof TOrgAddress]: string[];
};

type TResolvedComponent = {
  key: string;
  value: string | null | undefined;
};

const addressComponentsMap: TAddressComponents = {
  address1: ['street_number', 'route'],
  address2: ['subpremise'],
  city: ['locality', 'sublocality'],
  state: ['administrative_area_level_1'],
  country: ['country'],
  postalCode: ['postal_code', 'postal_code_suffix'],
};

const resolveAddressComponent = (
  componentPaths: string[],
  addressComponents: google.maps.GeocoderAddressComponent[]
): TResolvedComponent[] => {
  return componentPaths.map((path) => {
    const component = addressComponents.find((addressComponent) => addressComponent.types.includes(path));
    if (
      path === 'administrative_area_level_1' &&
      component &&
      (component?.short_name === component?.long_name || component?.short_name === 'QC')
    ) {
      return {
        key: path,
        value: convertRegion(component.short_name, CONVERSION_TYPE.ABBREVIATED_TO_NAME) || component.long_name,
      };
    }
    if (path === 'country' && component && component?.long_name === 'USA') {
      return {
        key: path,
        value: 'United States',
      };
    }
    return { key: path, value: component?.long_name };
  });
};

const componentIsEmpty = (component: TResolvedComponent) => component.value === null || component.value === undefined;

export const mapAddressComponents = (googleAddressComponents: google.maps.GeocoderAddressComponent[]): TOrgAddress => {
  return Object.entries(addressComponentsMap).reduce((acc, [key, componentPaths]) => {
    const resolvedComponents = resolveAddressComponent(componentPaths, googleAddressComponents);
    if (resolvedComponents.every(componentIsEmpty)) {
      return acc;
    }
    return {
      ...acc,
      [key]: resolvedComponents
        .map((component) => component.value)
        .filter((component) => component !== null && component !== undefined)
        .join(key === 'postalCode' ? '-' : ' ')
        .trim(),
    };
  }, {} as TOrgAddress);
};

export const truncateString = (str: string, maxLength: number): string => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  }
  return str;
};
